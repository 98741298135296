import React, {useEffect} from 'react';
import classes from './Search.module.scss';
import {Mention, MentionsInput} from "react-mentions";
import {Button, Group, CloseButton} from "@mantine/core";
import {useQuery} from "@apollo/client";
import {GET_TAGS} from "@/graphql/queries/TagQueries";
import Loading from "@/components/Shared/LoadingOverlay";
import {useTranslation} from "react-i18next";


export default function Search(
  {
    startSearch,
    search,
    setSearch,
    setSelectedTagIds,
    selectedTagIds,
    label
  }) {
  const { t } = useTranslation(["translation"]);
  const tagsQuery = useQuery(GET_TAGS);

  useEffect(() => {
    if (selectedTagIds.length > 0) {
      const tagIdsFromSearch = search.match(/\((\d+)\)/g)?.map(match => match.slice(1, -1)) || [];
      if (selectedTagIds !== tagIdsFromSearch) {
        setSelectedTagIds(tagIdsFromSearch);
      }
    }
  }, [search])

  if (tagsQuery.loading) return <Loading />;

  const searchWithoutTags = search.replace(/#(\w+)?/g, '');
  const sortedTags = [...tagsQuery.data.tags]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((tag) => ({id: tag.id, display: tag.name}))

  function renderTagsSuggestion(
    suggestion,
    search,
    highlightedDisplay,
    index,
    focused
  ) {
    return (
      <span className={focused ? classes.tagDropdownItemFocused : classes.tagDropdownItem}>#{highlightedDisplay}</span>
    )
  }

  function handleOnAdd(id, display) {
    const tags = [...selectedTagIds, id]
    setSelectedTagIds(tags);
    startSearch({tagIds: tags, value: searchWithoutTags});
  }

  function handleOnChange(e) {
    setSearch(e.target.value);
  }

  return (
    <Group position="right">
      <MentionsInput
        value={search || ''}
        placeholder={label || t('search_by_name_or_tag_label')}
        onChange={handleOnChange}
        className={classes.searchInput}
        autoFocus={!!search}
        onFocus={(e) => {
          const val = e.target.value;
          e.target.value = '';
          e.target.value = val;
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            startSearch({tagIds: selectedTagIds, value: searchWithoutTags})
            e.preventDefault();
          }
        }}
      >
        <Mention
          displayTransform={(id, display) => `#${display}`}
          trigger="#"
          data={sortedTags.filter((tag) => !selectedTagIds.includes(tag.id))}
          renderSuggestion={renderTagsSuggestion}
          onAdd={handleOnAdd}
          appendSpaceOnAdd
        />
      </MentionsInput>
      <Group className={classes.searchButtonsContainer} spacing={5}>
        { search && <CloseButton
          aria-label="clear filters"
          onClick={() => {
            setSearch('');
            setSelectedTagIds([]);
            startSearch({tagIds: [], value: ""});
          }}
        /> }
        <Button
          className={classes.searchButton}
          variant="default"
          onClick={() => startSearch({tagIds: selectedTagIds, value: searchWithoutTags})}
        >
          {t('search_text')}
        </Button>
      </Group>
    </Group>
  );
}