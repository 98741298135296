import React, { useState } from 'react';
import {
  Avatar,
  Group,
  Text,
  ActionIcon,
  Menu,
  Badge,
  MultiSelect,
  rem
} from '@mantine/core';
import {
  IconPencil,
  IconTrash,
  IconDotsVertical,
} from '@tabler/icons-react';
import DeleteFundingModal from './DeleteFundingModal';
import EditFundingModal from './EditFundingModal';
import { useDisclosure } from "@mantine/hooks";
import {useTranslation} from "react-i18next";

export default function Funding({ funding, setFundings }) {
  const { t } = useTranslation(['translation']);
  const [isDeleteModalOpened, deleteModalHandlers] = useDisclosure(false);
  const [isEditModalOpened, editModalHandlers] = useDisclosure(false);

  return (
    <tr key={funding.id}>
      <td>{funding.name}</td>
      <td>{funding.date}</td>
      <td>{funding.numberOfInvestors}</td>
      <td>{funding.moneyRaised}</td>
      <td>{funding.leadInvestors}</td>
      <td>
        <Group spacing={0} position="right">
          <Menu withinPortal position="bottom-end" shadow="sm">
            <Menu.Target>
              <ActionIcon radius="xl">
                <IconDotsVertical size="1rem"/>
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                icon={<IconPencil size={rem(14)} />}
                onClick={editModalHandlers.open}
              >
                {t('edit_button')}
              </Menu.Item>
              <Menu.Item
                icon={<IconTrash size={rem(14)} />}
                onClick={deleteModalHandlers.open}
                color="red"
              >
                {t('delete_button')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <DeleteFundingModal 
            opened={isDeleteModalOpened}
            close={deleteModalHandlers.close}
            setFundings={setFundings}
            fundingId={funding.id}/>
          <EditFundingModal
            opened={isEditModalOpened}
            close={editModalHandlers.close}
            funding={funding}
            setFundings={setFundings}
          />
        </Group>
      </td>
    </tr>
  );
}
