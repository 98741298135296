import React, {useEffect} from "react";
import {Input, MultiSelect, Text} from "@mantine/core";
import {useLazyQuery} from '@apollo/client';
import {GET_JOURNALISTS} from "@/graphql/queries/JournalistQueries";
import classes from '../Form.module.scss';
import {useTranslation} from "react-i18next";

export default function Tags({isPublished, tagIds, pressReleaseQuery, setTyping, setTagIds, debouncedOnChange, availableTags}) {
  const { t } = useTranslation(['translation']);
  const [getJournalists, { loading, data, error }] = useLazyQuery(GET_JOURNALISTS, { fetchPolicy: 'no-cache'});

  useEffect(() => {
    const tagIds = pressReleaseQuery.data.pressRelease.tags.map((tag) => tag.id)
    setTagIds(tagIds);
  } , []);

  useEffect(() => {
    if (tagIds) {
      getJournalists({ variables: { selectedTagIds: tagIds} })
    }
  } , [tagIds]);

  function journalistsCount() {
    if (tagIds?.length === 0) {
      return 0
    }

    return loading ? "---" : data?.journalists?.totalCount
  }

  return(
    <Input.Wrapper label={t('press_releases.tags_label')} size="md">
      <MultiSelect
        className={classes.selectInput}
        size="md"
        variant={ "filled" }
        readOnly={isPublished}
        searchable={true}
        defaultValue={tagIds}
        value={tagIds}
        onChange={(value) => {
          setTyping(true);
          setTagIds(value)
          debouncedOnChange({ tagIds: value })
          // getJournalists({ variables: { selectedTagIds: value} })
        }}
        data={availableTags}
        placeholder={t('press_releases.tags_placeholder')}
      />
      {!isPublished && <Text
        size={"xs"}
        c="dimmed"
      >
        { journalistsCount() } {t('press_releases.notified_text')}
      </Text>}
    </Input.Wrapper>
  )
}