import React, {useEffect} from 'react';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import classes from './BodyEditor.module.scss';
import {Input, Text} from '@mantine/core';
import {useTranslation} from "react-i18next";

const initialPressReleaseMessage = `  
  <p>Hi <code>{{journalistName}}</code>,</p>
  <p></p><p>I hope you're doing well! <code>{{companyName}}</code> just released a new <a target="_blank" rel="noopener noreferrer nofollow" href="{{pressReleaseUrl}}">press release</a>. I've attached all the details to this email. If you want to dig deeper into the company, you can find additional information in the company press kit <a target="_blank" rel="noopener noreferrer nofollow" href="{{pressKitUrl}}">here</a>.</p><p></p><p>Let me know if you have any questions.</p>
  <p>Kind regards,</p>
  <code>{{senderName}}</code>
`;
  

export default function MessageEditor({onChange, message, isPublished, emailNotification}) {
  const { t } = useTranslation(['translation']);
  const content = message || initialPressReleaseMessage;
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content,
    onUpdate({ editor }) {
      onChange(editor.getHTML());
    },
  });

  // useEffect(() => {
  //   console.log(emailNotification)
  //   if (emailNotification) {
  //     window.scrollTo({ top: 1000, behavior: "smooth" });
  //   }
  // },[]);

  if(isPublished) {
    return (
      <div>
        <Input.Wrapper
          label={t('press_releases.content_label')}
          size="md"
        >
        <Text className={classes.disabledMessage}>
          <div className={classes.prBodyText} dangerouslySetInnerHTML={{ __html: message }} />
        </Text>
        </Input.Wrapper>
      </div>
    )
  }

  return (
    <Input.Wrapper
      label={t('press_releases.content_label')}
      size="md"
    >
      <RichTextEditor
        editor={editor}
        className={classes.editor}
      >
        <RichTextEditor.Toolbar sticky stickyOffset={60} className={classes.toolbar}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content className={classes.editorContent}/>
      </RichTextEditor>
    </Input.Wrapper>
  );
}