import React, {useState} from 'react';
import { Button, Card, Grid, Group, ScrollArea, Space, Text } from "@mantine/core";
import { getTextFromHTML } from "~/utils/getTextFromHTML";
import RichTextEditor from "./TextEditor";
import SubmitButtons from "~/components/Companies/AboutCompany/InputCards/shared/SubmitButtons";
import {useTranslation} from "react-i18next";
import useStyles from "~/components/Companies/AboutCompany/AboutCompany.styles";
import { GET_COMPANY } from "~/graphql/queries/CompanyQueries";
import { UPDATE_COMPANY } from "~/graphql/mutations/CompanyMutations";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

export default function Contacts() {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const { classes } = useStyles();
  const [contactsText, setContactsText] = useState( "Name: <br>Email: <br>Phone:");
  const [editMode, setEditMode] = useState(true);
  const label = t('company.contacts.label_contacts');
  const placeholder = t('company.description_placeholder');

  const [updateCompany] = useMutation(UPDATE_COMPANY);

  const { loading, error, data } = useQuery(GET_COMPANY, {
    variables: { id },
  });

  const contacts =  data?.company?.contactsText || contactsText;

  const saveCompanyChanges = () => {
    updateCompany({
      variables: {
        company: {
          id: id,
          contactsText: contacts || contactsText
        }
      },
    });
    setEditMode(false);
  };
  const cancelChanges = () => {
    setContactsText(contacts || "");
    setEditMode(false);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <Grid justify="space-between" align="center">
        <Grid.Col span="content"><h2>Contacts</h2></Grid.Col>
        <Grid.Col span="content"></Grid.Col>
      </Grid>

      <Card radius={8} my="md" className={classes.inputCard}>
        <Group position="apart" align="flex-start">
          <Text size={"sm"} weight={500}>
            {label}
          </Text>
          <Group>

            {!editMode &&
              <Button
                variant="subtle"
                radius="xl"
                size="xs"
                compact
                onClick={() => setEditMode(true)}
              >
                {t('edit_button')}
              </Button>}
          </Group>
        </Group>
        <Space h="md" />
        {!editMode && contacts ? (
          <ScrollArea>
            <div dangerouslySetInnerHTML={{ __html: contacts }} />
          </ScrollArea>
        ) : !editMode && !contacts ? (
          <Text className={classes.fakePlaceholder}>{placeholder}</Text>
        ) : (
          <div style={{ width: "95%" }}>
            <RichTextEditor
              content={contacts || contactsText}
              onChange={(content) => setContactsText(content)}
            />
          </div>
        )}
        { editMode && <SubmitButtons
          cancelChanges={cancelChanges}
          saveCompanyChanges={saveCompanyChanges}
        /> }
      </Card>

    </>
  );
}
