import React from 'react';
import {
  Table,
  ScrollArea,
  createStyles,
} from '@mantine/core';
import SortingTableHeader from './SortingTableHeader';
import PressRelease from './PressRelease';
import {useTranslation} from "react-i18next";

const useStyles = createStyles((theme) => ({
  tr: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    borderBottom: `5px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
    }`,
  },
  tagTd: {
    maxWidth: 300,
  },
  table: {
    "th": {
      borderBottom: "none !important",
    }
  }

}));

export default function PressReleaseList({ companyId, pressReleasesData, sort, startSorting, setPressReleases }) {
  const { t } = useTranslation(['translation']);
  const { classes, theme } = useStyles();

  const pressReleases = pressReleasesData.map((item) => (
    {
      id: item.id,
      subject: item.subject,
      status: item.status,
      releasedAt: new Date(item.releaseDate).toLocaleDateString(),
      companyId: item.company?.id,
      companyName: item.company?.name,
      tags: item.tags,
    }
  ));

  const rows = () => (
    pressReleases.map((item) => (
      <PressRelease
        key={item.id}
        pressRelease={item}
        companyId={companyId}
        setPressReleases={setPressReleases}
      />
    ))
  )

  return (
    <>
      <ScrollArea>
        <Table sx={{ minWidth: 800 }} verticalSpacing="md" className={classes.table}>
          <thead>
          <tr>
            <SortingTableHeader
              sort={sort}
              onSort={() => startSorting('subject')}
              setSortedData={setPressReleases}
            >
              {t('press_releases.subject_label')}
            </SortingTableHeader>
            { !companyId && <th>Company</th> }
            <SortingTableHeader
              sort={sort}
              onSort={() => startSorting('status')}
              setSortedData={setPressReleases}
            >
              {t('press_releases.status_label')}
            </SortingTableHeader>
            <SortingTableHeader
              sort={sort}
              onSort={() => startSorting('release_date')}
              setSortedData={setPressReleases}
            >
              {t('press_releases.release_date_label')}
            </SortingTableHeader>
            <th>{t('press_releases.tags_label')}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>{rows()}</tbody>
        </Table>
      </ScrollArea>
    </>
  );
}