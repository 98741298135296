import { gql } from "@apollo/client";

export const GET_COMPANY_PRESS_RELEASES = gql`
query PressReleases($companyId: ID!, $sortBy: String, $sort: String) {
  pressReleases(companyId: $companyId, sortBy: $sortBy, sort: $sort) {
    id
    subject
    companyId
    message
    status
    releaseDate
    company {
      id
      name
    }
    tags {
      id
      name
    }
  }
}
`;

export const GET_PRESS_RELEASE = gql`
query PressRelease($id: ID!) {
  pressRelease(id: $id) {
    id
    subject
    companyId
    message
    status
    releaseDate
    isValid
    isPublic
    emailNotification
    name
    bodyHtml
    company {
      id
      name
    }
    tags {
      id
      name
    }
    pressReleaseFiles {
      id
      url
      filename
    }
    picture {
      id
      url
      filename
    }
  }
}
`;

export const GET_PRESS_RELEASES_BY_COMPANY_UUID = gql`
query PressReleasesByCompanyUuid($companyUuid: String!) {
  pressReleasesByCompanyUuid(companyUuid: $companyUuid) {
    id
    subject
    companyId
    message
    status
    releaseDate
    isValid
    isPublic
    emailNotification
    name
    bodyHtml
    uuid
    company {
      id
      name
    }
    tags {
      id
      name
    }
    pressReleaseFiles {
      id
      url
      filename
    }
    picture {
      id
      url
      filename
    }
  }
}
`;

export const GET_PRESS_RELEASE_BY_UUID = gql`
query PressReleaseByUuid($uuid: String!) {
  pressReleaseByUuid(uuid: $uuid) {
    id
    subject
    companyId
    message
    status
    releaseDate
    isValid
    isPublic
    emailNotification
    name
    bodyHtml
    uuid
    company {
      id
      name
      externalUuid
    }
    tags {
      id
      name
    }
    pressReleaseFiles {
      id
      url
      filename
    }
    picture {
      id
      url
      filename
    }
  }
}
`;

export const GET_ALL_PRESS_RELEASES = gql`
query AllPressReleases($sortBy: String, $sort: String, $after: String, $count: Int, $search: String, $filter: String, $selectedTagIds: [ID!]) {
  allPressReleases(sortBy: $sortBy, sort: $sort, after: $after, count: $count, search: $search, filter: $filter, selectedTagIds: $selectedTagIds) {
    totalCount
    nodes {
      id
      subject
      companyId
      message
      status
      releaseDate
      isValid
      isPublic
      emailNotification
      name
      bodyHtml
      uuid
      company {
        id
        name
      }
      tags {
        id
        name
      }
      pressReleaseFiles {
        id
        url
        filename
      }
      picture {
        id
        url
        filename
      }
    }
    pageInfo {
      hasNextPage
      startCursor
      endCursor
      hasPreviousPage
    }
  }
}
`;

export const GET_ALL_PUBLIC_PRESS_RELEASES = gql`
query AllPublicPressReleases($sortBy: String, $sort: String, $after: String, $count: Int, $search: String, $filter: String, $selectedTagIds: [ID!]) {
  allPublicPressReleases(sortBy: $sortBy, sort: $sort, after: $after, count: $count, search: $search, filter: $filter, selectedTagIds: $selectedTagIds) {
    totalCount
    nodes {
      id
      subject
      companyId
      message
      status
      releaseDate
      isValid
      isPublic
      emailNotification
      name
      bodyHtml
      uuid
      company {
        id
        name
      }
      tags {
        id
        name
      }
      pressReleaseFiles {
        id
        url
        filename
      }
      picture {
        id
        url
        filename
      }
    }
    pageInfo {
      hasNextPage
      startCursor
      endCursor
      hasPreviousPage
    }
  }
}
`;