import React from 'react';
import TeamCard from "@/components/Companies/Team/TeamCard";
import {Grid, LoadingOverlay, SimpleGrid} from '@mantine/core';
import {useParams} from "react-router-dom";
import { useQuery } from '@apollo/client';
import { GET_TEAM_MEMBERS } from '~/graphql/queries/TeamMemberQueries';
import CreateTeamMember from './CreateTeamMember';
import {useTranslation} from "react-i18next";

export default function Team() {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_TEAM_MEMBERS, { variables: { companyId: id } });

  if (loading) return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (error) return <p>Error :(</p>;
  
  return (
    <div>
      <Grid justify="space-between" align="center">
        <Grid.Col span="content"><h2>{t('company.members.header')}</h2></Grid.Col>
        <Grid.Col span="content">
          <CreateTeamMember />
        </Grid.Col>
      </Grid>
      <Grid>
        {data.teamMembers.map((member) => (
          <Grid.Col key={member.id} xs={12} sm={6} md={6} lg={3}>
            <TeamCard member={member} />
          </Grid.Col>
        ))}
      </Grid>

    </div>
  );
}