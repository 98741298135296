import React, { useContext, useEffect, useState } from "react";
import { Text, Button, ActionIcon } from "@mantine/core";
import { IconBell, IconBellMinus } from "@tabler/icons-react";
import { CurrentUser } from "@/contexts/CurrentUserContext";
import { PRESSKIT_FOLLOW } from "~/graphql/queries/PresskitQueries";
import { FOLLOW_PRESSKIT, UNFOLLOW_PRESSKIT } from "~/graphql/mutations/PresskitMutations";
import { useQuery, useMutation } from "@apollo/client";
import classes from './Subscribe.module.scss';
import NotFoundTitle from "../../Shared/NotFoundTitle";

import { useTranslation } from "react-i18next";

export default function Subscribe({ companyId }) {
  const currentUser = useContext(CurrentUser);
  const { t } = useTranslation(["translation"]);

  const [follow, setFollow] = useState(null)

  const [_follow] = useMutation(FOLLOW_PRESSKIT);
  const [_unfollow] = useMutation(UNFOLLOW_PRESSKIT);

  const { loading, error, data } = useQuery(PRESSKIT_FOLLOW, {
    variables: { id: companyId, userId: currentUser.id },
  });

  const toggleFollow = () => {
    if (follow) {
      setFollow(false)
      _unfollow({ variables: { id: companyId, userId: currentUser.id }, refetchQueries: refetchQueries })
    } else {
      setFollow(true)
      _follow({ variables: { id: companyId, userId: currentUser.id }, refetchQueries: refetchQueries })
    }
  }

  const icon = () => follow ? <IconBellMinus p="0" size="1.2rem" color="white" /> : <IconBell size="1.2rem" color="white" />

  const refetchQueries = [
      {
        query: PRESSKIT_FOLLOW,
        variables: { id: companyId, userId: currentUser.id },
      },
    ]

  useEffect(() => {
    if(data?.presskitFollow) {
      setFollow(data?.presskitFollow?.follow)
    }
  }, [data])

  if (loading) return <p>{t("loading")}</p>;
  if (error) return <NotFoundTitle />;

  return (
    <Button pl="8px" color={follow ? 'gray.4' : 'primary'} radius="xl" onClick={toggleFollow}>
      <ActionIcon className={classes.bellIcon} component="span">
        {icon()}
      </ActionIcon>
      <Text>{follow ? 'Unsubscribe' : 'Subscribe'}</Text>
    </Button>
  );
}
