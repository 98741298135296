import React from "react";
import {useQuery} from "@apollo/client";
import {GET_SERVICES_BY_COMPANY_UUID} from "@/graphql/queries/ServiceQueries";
import {Grid, LoadingOverlay} from "@mantine/core";
import ServiceCard from "./ServiceCard"
import {useTranslation} from "react-i18next";

export default function Services({ company }) {
  const { t } = useTranslation(['translation']);
  const { loading, error, data } = useQuery(GET_SERVICES_BY_COMPANY_UUID, { variables: { companyUuid: company.externalUuid } });
  if (loading) return <LoadingOverlay visible={loading} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;

  const services = data.servicesByCompanyUuid

  return (
    <div>
      <Grid mt={12}>
        {services.map(service => (
          <Grid.Col key={service.id} span={4}>
            <ServiceCard service={service}/>
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
}