import React, {useEffect} from 'react';
import {
  Group,
  createStyles,
  Center,
  rem,
  UnstyledButton
} from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_PRESS_RELEASES } from '~/graphql/queries/PressReleaseQueries';
import Loading from "@/components/Shared/LoadingOverlay";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important"
  },
  control: {
    width: '100%',
    padding: rem(16),
    color: theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 700
  },
  icon: {
    width: rem(21),
    height: rem(21),
    borderRadius: rem(21)
  },
  group: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default function SortingTableHeader({ children, reversed, sorted, sortBy, onSort, setSortedData }) {
  const [getSortedData, { loading, data }] = useLazyQuery(GET_ALL_PRESS_RELEASES, { variables: {sortBy: sortBy, sort: reversed ? 'desc' : 'asc' } });
  const { classes, theme } = useStyles();
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;

  useEffect(() => {
    if (sorted) {
      getSortedData()
    }
  }, [sorted, reversed])

  useEffect(() => {
    if(data && data.allPressReleases) {
      setSortedData(data.allPressReleases)
    }
  }, [data])

  if (loading) return <Loading />;
  
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between" className={classes.group}>
          {children}
          <Center className={classes.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}
