import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
  Paper,
  Container,
  Anchor,
  Input,
  Textarea,
  Space,
  Text,
  Image,
  SimpleGrid,
  Grid, LoadingOverlay,
} from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { GET_SERVICE } from "@/graphql/queries/ServiceQueries";
import { UPDATE_SERVICE } from "@/graphql/mutations/ServiceMutations";
import { useQuery, useMutation } from '@apollo/client';
import { debounce } from "lodash";
import {useTranslation} from "react-i18next";

export default function ServicePage() {
  const { t } = useTranslation(['translation']);
  const { id, service_id } = useParams();
  const navigate = useNavigate();
  const serviceQuery = useQuery(GET_SERVICE, { variables: { id: service_id } });
  const [updateService, {loading}] = useMutation(UPDATE_SERVICE);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [typing, setTyping] = useState(false);

  const onChange = (name, description) => {
    updateServiceHandler(name, description);
  }
  const debouncedOnChange = debounce(onChange, 1000);

  if (serviceQuery.loading) return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (serviceQuery.error) return <p>Error :(</p>;

  function updateServiceHandler(name, description) {
    updateService({
      variables: {
        service: {
          id: service_id,
          name: name,
          description: description
        }
      }
    })
    setName(name);
    setDescription(description);
    setTyping(false);
  }

  function uploadImage(files) {
    updateService({
      variables: {
        service: {
          id: service_id,
          serviceImage: files[0]
        }
      }
    });
  }

  function savingState() {
    if (loading || typing) {
      return t('saving_text');
    } else {
      return t('saved_text');
    }
  }

  return (
    <>
      <Grid justify="space-between" align="center">
        <Grid.Col span="content">
          <Anchor
            underline={false}
            onClick={() => navigate(`/companies/${id}/services`) }
          >
            {`${t('company.services.back_to_all')}`}
          </Anchor>
        </Grid.Col>
        <Grid.Col span="content">
          <Text c="dimmed">{savingState()}</Text>
        </Grid.Col>
      </Grid>
      <Container my={30}>
        <Paper shadow="xs" p={30} radius="md" mt="xl">
          <Input
            variant="filled"
            placeholder={t('company.services.name_label')}
            size="xl"
            onKeyDown={() => setTyping(true)}
            defaultValue={name || serviceQuery.data.service.name}
            onChange={(e) => {
              debouncedOnChange(e.target.value, description || serviceQuery.data.service.description)
            }}
          />

          <Space h="xl" />
          <Textarea
            placeholder={t('company.services.description_label')}
            variant="filled"
            size="xl"
            withAsterisk
            autosize
            onKeyDown={() => setTyping(true)}
            minRows={4}
            defaultValue={description || serviceQuery.data.service.description}
            onChange={(e) => {
              debouncedOnChange(name || serviceQuery.data.service.name, e.target.value)
            }}
          />
          <Space h="xl" />
          <Dropzone
            accept={IMAGE_MIME_TYPE}
            onDrop={(files) => { uploadImage(files); }}
            multiple={false}
          >
            { !serviceQuery.data.service.serviceImageUrl && <Text align="center" >{t('company.services.drop_images_label')}</Text> }
            <SimpleGrid
              cols={4}
              breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
            >
              { serviceQuery.data.service.serviceImageUrl &&  <Image
                src={serviceQuery.data.service.serviceImageUrl}
              />}
            </SimpleGrid>
          </Dropzone>
        </Paper>
      </Container>
    </>
  );
}
