import React, {useEffect} from 'react';
import {
  Group,
  createStyles,
  Center,
  rem,
  UnstyledButton
} from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },
  control: {
    width: '100%',
    padding: rem(16),
    color: theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 700,
  },
  icon: {
    width: rem(21),
    height: rem(21),
    borderRadius: rem(21)
  },
  group: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default function SortingTableHeader({ children, sort, onSort }) {
  const { classes, theme } = useStyles();
  const Icon = !!sort ? (sort === "asc" ? IconChevronUp : IconChevronDown) : IconSelector;
  
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between" className={classes.group}>
          {children}
          <Center className={classes.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}
