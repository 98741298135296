import React, { useContext, useState, useEffect } from "react";
import classes from "./Notifications.module.scss";
import { CurrentUser } from "@/contexts/CurrentUserContext";
import {useLazyQuery} from "@apollo/client";
import {NOTIFICATIONS_QUERY} from "@/graphql/queries/NotificationQueries";
import InfiniteScroll from 'react-infinite-scroll-component';
import Notification from "./Notification";
import Loading from "@/components/Shared/LoadingOverlay";
import {useTranslation} from "react-i18next";

import { Group, Menu, Indicator, rem, ActionIcon, Loader, Anchor, Flex, Text } from '@mantine/core';
import { IconBell } from '@tabler/icons-react';

export default function Notifications() {
  const { t } = useTranslation(['translation']);
  const currentUser = useContext(CurrentUser);
  const [notifications, setNotifications] = useState([])
  const [getNotifications, { loading, data, error }] = useLazyQuery(NOTIFICATIONS_QUERY, { variables: { userId: currentUser.id }, fetchPolicy: 'no-cache'});

  useEffect(() => {
    loadMore()
  }, [])

  useEffect(() => {
    if (data?.notifications?.nodes) {
      setNotifications((current) => [...current, ...data.notifications.nodes])
    }
  }, [data])

  function loadMore() {
    getNotifications({ variables: {after: data?.notifications?.pageInfo?.endCursor, userId: currentUser.id} })
  }

  if (loading && !notifications.length) return <Loading />;
  if (error) return <p>Error :</p>;
  
  return((currentUser.user_type === "journalist") && <Menu
      transitionProps={{ transition: 'pop-top-right' }}
      position="bottom"
      width={420}
      withinPortal
    >
      <Menu.Target mr="md">
        <Indicator size="12" disabled={notifications.length === 0 || notifications.every((n) => n.readAt)}>
          <ActionIcon size="md">
            <IconBell
              style={{ width: rem(24), height: rem(24) }}
              stroke={1.5}
            />
          </ActionIcon>
        </Indicator>
      </Menu.Target>
      <Menu.Dropdown className={classes.dropdown}>
        <InfiniteScroll
          dataLength={notifications.length}
          next={loadMore}
          hasMore={ data?.notifications?.pageInfo?.hasNextPage }
          loader={ <Group justify="center" grow><Loader /></Group>}
        >
          {notifications.map((notification) => (
            <Notification key={notification.id} notification={notification} />
          ))}
        </InfiniteScroll>
        {data?.notifications?.pageInfo?.hasNextPage && <Flex my="md" justify="center">
          <Anchor onClick={() => loadMore()}>Load more</Anchor>
        </Flex>}
        {notifications.length === 0 && <Flex justify="center" py="md"><Text>No notifications.</Text></Flex>}
      </Menu.Dropdown>
    </Menu>
  )
}
