import React from "react";
import { Button, Tooltip } from '@mantine/core';
import {IconLockOpen, IconLock} from "@tabler/icons-react";
import { useMutation } from "@apollo/client";
import { PUBLISH_PRESSKIT } from "@/graphql/mutations/CompanyMutations";
import {useTranslation} from "react-i18next";

export default function PublishButton({ companyId, status }) {
  const { t } = useTranslation(['translation']);
  const [publishCompany, { loading }] = useMutation(PUBLISH_PRESSKIT);

  const handlePublish = () => {
    publishCompany({
      variables: {
        id: companyId,
        value: status !== "published",
      },
    });
  }

  return (
    <>
      <Tooltip
        label={t('company.publish_label')}
        position="left"
        withArrow
        transition="pop"
        openDelay={500}
      >
        <Button
          onClick={handlePublish}
          variant="light"
          size="sm"
          radius="xl"
          leftIcon={
            status === "published" ?
              <IconLockOpen /> :
              <IconLock />
          }
        >
          {status === "published" ? t('unpublish_button') : t('publish_button')}
        </Button>
      </Tooltip>
    </>
  );
}