import {gql} from "@apollo/client";

export const CREATE_MEDIA = gql`
mutation CreateMedia($media: MediaInput!) {
  createMedia(input: {media: $media}) {
    id
    name
    logoUrl
    description
  }
}
`;

export const UPDATE_MEDIA = gql`
mutation UpdateMedia($media: MediaInput!) {
  updateMedia(input: {media: $media}) {
    id
    name
    logoUrl
    description
  }
}
`;

export const DELETE_MEDIA = gql`
mutation DeleteMedia($id: ID!) {
  deleteMedia(input: {id: $id}) {
    success
  }
}
`;
