import React, {useEffect} from 'react';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import classes from './BodyEditor.module.scss';
import { Input } from '@mantine/core';
import {useTranslation} from "react-i18next";

export default function BodyEditor({onChange, content}) {
  const { t } = useTranslation(['translation']);
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content,
    onUpdate({ editor }) {
      onChange(editor.getHTML());
    },
  });

  return (
    <Input.Wrapper
      label={t('press_releases.content_label')}
      size="md"
    >
      <RichTextEditor
        editor={editor}
        className={classes.editor}
      >
        <RichTextEditor.Toolbar sticky stickyOffset={60} className={classes.toolbar}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>
        <RichTextEditor.Content className={classes.editorContent}/>
      </RichTextEditor>
    </Input.Wrapper>
  );
}