import React from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_JOURNALIST } from '~/graphql/mutations/JournalistMutations'
import { Button } from '@mantine/core'
import {useTranslation} from "react-i18next";

export default function CreateJournalist({ submit, name, email, description, tagIds, mediaId, loadDefault }) {
  const { t } = useTranslation(['translation']);
  const [_createJournalist] = useMutation(CREATE_JOURNALIST)

  function handleCreateJournalist() {
    if (name.length > 0 && email.length > 0 && (/^\S+@\S+$/.test(email))) {
      _createJournalist({
        variables: {
          journalist: {
            name: name,
            email: email,
            description: description,
            tagIds: tagIds,
            mediaId: mediaId,
          },
        },
        update(_observableQuery) {
          loadDefault()
        }
      })
      submit()
    } else {
      submit()
    }
  }

  return <Button onClick={handleCreateJournalist}>{t('ok_button')}</Button>
}
