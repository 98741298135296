import { gql } from "@apollo/client";

export const GET_FOLDERS = gql`
  query folders($companyId: ID!, $folderType: String!) {
    folders(companyId: $companyId, folderType: $folderType) {
      id
      name
    }
  }
`;

export const GET_FOLDER = gql`
  query folder($folderId: ID!) {
    folder(folderId: $folderId) {
      id
      name
    }
  }
`;
