import React from "react";
import AttachmentCard from "../Attachments/AttachmentCard";
import FolderCard from "../Attachments/FolderCard";
import {Grid, LoadingOverlay} from "@mantine/core";
import HeaderPage from "@/components/Companies/Attachments/HeaderPage";
import { useQuery } from "@apollo/client";
import { GET_DOCUMENTS } from "~/graphql/queries/AttachmentQueris";
import { useParams } from "react-router-dom";
import { GET_FOLDERS } from "@/graphql/queries/FolderQueries";
import { allowedDocumentFormats } from "@/utils/attachmentFormats";
import AttachmentsDropZone from "@/components/Companies/Attachments/AttachmentsDropZone";
import {useTranslation} from "react-i18next";

export default function Documents() {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const attachmentType = "documents";
  const documentsResults = useQuery(GET_DOCUMENTS, {
    variables: { companyId: id },
  });
  const foldersResults = useQuery(GET_FOLDERS, {
    variables: { companyId: id, folderType: attachmentType },
  });

  if (documentsResults.loading || foldersResults.loading)
    return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (documentsResults.error || foldersResults.error) return <p>Error :(</p>;

  return (
    <div>
      <AttachmentsDropZone
        fileAllowedFormats={allowedDocumentFormats}
        attachmentType={attachmentType}
        attachmentQuery={GET_DOCUMENTS}
      />
      <HeaderPage
        header={"Documents"}
        attachmentType={attachmentType}
        attachmentQuery={GET_DOCUMENTS}
        fileAllowedFormats={allowedDocumentFormats}
      />
      <h4>{t('company.folders_header')}</h4>
      <Grid gutter="lg">
        {foldersResults.data.folders.map((folder) => (
          <Grid.Col key={folder.id} xs={12} sm={4} md={3} lg={3} xl={3}>
            <FolderCard
              name={folder.name}
              folderId={folder.id}
              attachmentType={attachmentType}
            />
          </Grid.Col>
        ))}
      </Grid>
      <h4>{t('company.documents_header')}</h4>
      <Grid gutter="lg">
        {documentsResults.data.documents.map((document) => (
          <Grid.Col key={document.id} xs={12} sm={4} md={3} lg={3} xl={3}>
            <AttachmentCard
              attachmentType={attachmentType}
              attachmentQuery={GET_DOCUMENTS}
              attachment={document}
            />
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
}
