import React, {useState} from "react";
import {
  UnstyledButton,
  Checkbox,
  Text,
  SimpleGrid,
  createStyles,
  rem,
  Modal,
  Group,
  Alert,
  Avatar, Anchor, Space, Button, LoadingOverlay
} from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_TEAM_MEMBERS} from "@/graphql/queries/TeamMemberQueries";
import { IconAlertCircle } from '@tabler/icons-react';
import {useTranslation} from "react-i18next";

const useStyles = createStyles((theme, { checked }) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    transition: 'background-color 150ms ease, border-color 150ms ease',
    border: `${rem(1)} solid ${
      checked
        ? theme.fn.variant({ variant: 'outline', color: theme.primaryColor }).border
        : theme.colorScheme === 'dark'
          ? theme.colors.dark[8]
          : theme.colors.gray[3]
    }`,
    borderRadius: theme.radius.sm,
    padding: theme.spacing.sm,
    backgroundColor: checked
      ? theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background
      : theme.colorScheme === 'dark'
        ? theme.colors.dark[8]
        : theme.white,
  },

  body: {
    flex: 1,
    marginLeft: theme.spacing.md,
  },
}));

export function ImageCheckbox({
                                checked,
                                defaultChecked,
                                onChange,
                                title,
                                description,
                                className,
                                image,
                                id,
                                ...others
                              }) {
  const [value, handleChange] = useUncontrolled({
    value: checked,
    defaultValue: defaultChecked,
    finalValue: false,
    onChange(){ onChange({id: id, value: !value})}
  });

  const { classes, cx } = useStyles({ checked: value });

  return (
    <UnstyledButton
      {...others}
      onClick={() => handleChange(!value)}
      className={cx(classes.button, className)}
    >
      <Avatar src={image} alt={title} size="lg"/>

      <div className={classes.body}>
        <Text color="dimmed" size="xs" sx={{ lineHeight: 1 }} mb={5}>
          {description}
        </Text>
        <Text weight={500} size="sm" sx={{ lineHeight: 1 }}>
          {title}
        </Text>
      </div>

      <Checkbox
        checked={value}
        onChange={() => {}}
        tabIndex={-1}
        styles={{ input: { cursor: 'pointer' } }}
      />
    </UnstyledButton>
  );
}

export default function SelectKeyPeopleModal({ opened, close, keyPeople, updateCompany, setDefaultKeyPeople }) {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_TEAM_MEMBERS, { variables: { companyId: id } });
  const [keyPersonIds, setKeyPersonIds] = useState(keyPeople.map((member) => member.id));
  // const KeyPeopleIds = keyPeople.map((member) => member.id);

  if (loading) return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (error) return <p>Error :(</p>;

  const items = data.teamMembers.map((member) => <ImageCheckbox
    description={member.role}
    title={member.name}
    image={member.avatarUrl}
    key={member.id}
    id={member.id}
    defaultChecked={keyPersonIds.includes(member.id)}
    onChange={(value) => handleSelectKeyPeople(value)}
  />);

  function handleSelectKeyPeople({id, value}) {
    if (value) {
      setKeyPersonIds([...keyPersonIds, id]);
    } else {
      setKeyPersonIds(keyPersonIds.filter((keyPersonId) => keyPersonId !== id));
    }
  }
  function done() {
    updateCompany({ keyPersonIds: keyPersonIds });
    setDefaultKeyPeople(data.teamMembers.filter((member) => keyPersonIds.includes(member.id)));
    close();
  }

  return (
    <>
      <Modal opened={opened} onClose={close} centered size="80%" title={"Select key people in the company"}>
        <SimpleGrid
          cols={4}
          breakpoints={[
            { maxWidth: 'md', cols: 2 },
            { maxWidth: 'sm', cols: 1 },
          ]}
        >
          {items}
        </SimpleGrid>
        { items.length === 0 &&

          <Alert icon={<IconAlertCircle size="1rem" />} title={t('company.members_alert.no_people')} >
            {t('company.members_alert.please')}
            <Anchor href={`/companies/${id}/team`}>
              {t('company.members_alert.add')}
            </Anchor>
            {t('company.members_alert.when')}
          </Alert>
        }

        <Space h="xs" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button
            radius={"xl"}
            disabled={items.length === 0}
            onClick={done}
          >{t('done_button')}</Button>
        </Group>
      </Modal>
    </>
  );
}