import React from 'react';
import {
  Group,
  Text,
  ActionIcon,
  Menu,
  createStyles,
  Badge,
  Anchor,
  rem, MultiSelect
} from '@mantine/core';
import {
  IconPencil,
  IconTrash,
  IconDotsVertical,
} from '@tabler/icons-react';
import {useNavigate} from "react-router-dom";
import DeletePressReleaseModal from './DeletePressReleaseModal'
import { useDisclosure } from "@mantine/hooks";
import prClasses from "./PressRelease.module.scss";
import {useTranslation} from "react-i18next";

const useStyles = createStyles((theme) => ({
  tr: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    borderBottom: `5px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
    }`,
  }
}));

export default function PressRelease({ pressRelease, companyId, setPressReleases }) {
  const { t } = useTranslation(['translation']);
  const { classes, theme } = useStyles();
  const [isDeleteModalOpened, deleteModalHandlers] = useDisclosure(false);
  const navigate = useNavigate();
  const tags = pressRelease.tags || [];

  function statusColor(status) {
    switch (status) {
      case "published":
        return "green";
      case "draft":
        return "gray";
      case "archived":
        return "primary";
      default:
        return "gray";
    }
  }

  return (
    <tr className={classes.tr}>
      <td>
        <Group spacing="sm">
          <div>
            <Anchor
              underline={false}
              onClick={() => navigate( companyId ? `/companies/${pressRelease.companyId}/release/${pressRelease.id}/press_releases` : `/press_releases/${pressRelease.id}`) }
            >
              <Text fz="sm" fw={500} style={{ maxWidth: "250px" }} lineClamp={3}>
                {pressRelease.subject}
              </Text>
            </Anchor>
          </div>
        </Group>
      </td>
      { !companyId && <td>
        <Anchor
          underline={false}
          onClick={() => navigate(`/companies/${pressRelease.companyId}/press_releases`) }
        >
          {pressRelease.companyName}
        </Anchor>
      </td>}
      <td>
        <Badge color={ statusColor(pressRelease.status) }>{pressRelease.status}</Badge>
      </td>
      <td>
        {pressRelease.status === "published" && pressRelease.releasedAt}
      </td>
      <td className={prClasses.tagsCell}>
        <MultiSelect
          data={tags.map((tag) => ({ value: `${pressRelease.id}-${tag.id}`, label: tag.name }))}
          placeholder=""
          defaultValue={tags.map((tag) => `${pressRelease.id}-${tag.id}`)}
          variant="unstyled"
          readOnly={true}
          rightSection={null}
        />
      </td>
      <td>
        {
          pressRelease.status !== "Unsubscribed" && pressRelease.status !== "Subscribed" &&
          <Group spacing={0} position="right">
            <Menu withinPortal position="bottom-end" shadow="sm">
              <Menu.Target>
                <ActionIcon radius="xl">
                  <IconDotsVertical size="1rem"/>
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  onClick={() => navigate(`/press_releases/${pressRelease.id}`) }
                  icon={<IconPencil size={rem(14)} />}
                >
                  { pressRelease.status === "published" ? t('press_releases.preview_button') : t('edit_button')}
                </Menu.Item>
                <Menu.Item
                  icon={<IconTrash size={rem(14)} />}
                  color="red"
                  onClick={deleteModalHandlers.open}
                >
                  {t('delete_button')}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        }
        <DeletePressReleaseModal
          opened={isDeleteModalOpened}
          close={deleteModalHandlers.close}
          setPressReleases={setPressReleases}
          pressReleaseId={pressRelease.id}/>
      </td>
    </tr>
  );
}
