import React, { useState } from 'react';
import {Modal, Button, Input, Space, Group, Anchor} from '@mantine/core';
import {IconPlus} from "@tabler/icons-react";
import {useTranslation} from "react-i18next";
import { useDisclosure } from '@mantine/hooks';
import { SEND_ACCOUNT_INVITATION } from '@/graphql/mutations/AccountMutations';
import { GET_CURRENT_ACCOUNT } from '@/graphql/queries/AccountQueries';
import { useMutation } from '@apollo/client';

export default function NewTeammateButton({getCurrentAccount}) {
  const { t } = useTranslation(['translation']);
  const [opened, { open, close }] = useDisclosure(false);
  const [sendAccountInvitation] = useMutation(SEND_ACCOUNT_INVITATION);
  const [email, setEmail] = useState('');

  const addTeammate = (email) => {
    sendAccountInvitation({
      variables: { email: email },
      refetchQueries: [
        { query: GET_CURRENT_ACCOUNT },
      ],
    });
    close();
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        centered
        title={t('accounts.teammates.new_teammate_button')}
      >
        <Input.Wrapper
          label="Tammate email address">
          <Input
            required
            placeholder="teammate@prbox.com"
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
        </Input.Wrapper>

        <Space h="xl" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button
            radius="xl"
            compact="true"
            onClick={() => addTeammate(email)}
          >
            {t('add_button')}
          </Button>
        </Group>
      </Modal>

      <Button
        variant="light"
        size="sm"
        radius="xl"
        leftIcon={<IconPlus />}
        onClick={open}
      >
        {t('accounts.teammates.new_teammate_button')}
      </Button>
    </>
  );
}