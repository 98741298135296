import React from "react";
import PageContainer from "./PageContainer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import JournalistsSignIn from "~/components/Authentication/JournalistsSignIn"
import Login from "~/components/Authentication/Login";
import Register from "~/components/Authentication/Register";
import ResetPassword from "~/components/Authentication/ResetPassword";
import ChangePassword from "~/components/Authentication/ChangePassword";
import ExternalPageContainer from "@/components/routing/ExternalPageContainer";

export default function PageRouting() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={ <PageContainer view="Dashboard" /> } />
        <Route path="/personal" exact element={ <PageContainer view="Personal" sidebar="accountView" /> } />
        <Route path="/account" exact element={ <PageContainer view="Account" sidebar="accountView" /> } />
        <Route path="/press_releases" exact element={ <PageContainer view="PressReleases"/> } />
        <Route path="/press_releases/:press_release_id" exact element={ <PageContainer view="PressReleaseForm"/> } />
        <Route path="/press-release/:uuid" exact element={ <ExternalPageContainer view="PressReleasePage"/> } />
        <Route path="/presskits/:presskit_uuid" exact element={ <ExternalPageContainer view="PressKit"/> } />
        <Route path="/presskits" exact element={ <PageContainer view="PressKits"/> } />
        <Route path="/presskits/:presskit_uuid/:tab" exact element={ <ExternalPageContainer view="PressKit"/> } />
        <Route path="/companies" exact element={ <PageContainer view="Companies"/> } />
        <Route path="/companies/:id/about" exact element={ <PageContainer view="Company" sidebar="companyView" /> } />
        <Route path="/companies/:id/team" exact element={ <PageContainer view="CompanyTeam" sidebar="companyView" /> } />
        <Route path="/companies/:id/member/:member_id/team" exact element={ <PageContainer view="TeamMember" sidebar="companyView" /> } />
        <Route path="/companies/:id/press_releases" exact element={ <PageContainer view="CompanyPressReleases" sidebar="companyView" /> } />
        <Route path="/companies/:id/release/:press_release_id/press_releases" exact element={ <PageContainer view="CompanyPressReleasesForm" sidebar="companyView" /> } />
        <Route path="/companies/:id/images" exact element={ <PageContainer view="CompanyImages" sidebar="companyView" /> } />
        <Route path="/companies/:id/videos" exact element={ <PageContainer view="CompanyVideos" sidebar="companyView" /> } />
        <Route path="/companies/:id/documents" exact element={ <PageContainer view="CompanyDocuments" sidebar="companyView" /> } />
        <Route path="/companies/:id/services" exact element={ <PageContainer view="CompanyServices" sidebar="companyView" /> } />
        <Route path="/companies/:id/service/:service_id/services" exact element={ <PageContainer view="CompanyServicesPage" sidebar="companyView" /> } />
        <Route path="/companies/:id/products" exact element={ <PageContainer view="CompanyProducts" sidebar="companyView" /> } />
        <Route path="/companies/:id/product/:product_id/products" exact element={ <PageContainer view="CompanyProductPage" sidebar="companyView" /> } />
        <Route path="/companies/:id/folders/:folder_id/:attachment_type" exact element={ <PageContainer view="Folder" sidebar="companyView" /> } />
        <Route path="/companies/:id/fundings" exact element={ <PageContainer view="CompanyFundings" sidebar="companyView" /> } />
        <Route path="/companies/:id/contacts" exact element={ <PageContainer view="CompanyContacts" sidebar="companyView" /> } />
        <Route path="/tags" exact element={ <PageContainer view="Tags"/> } />
        <Route path="/journalists" exact element={ <PageContainer view="Journalists"/> } />
        <Route path="/journalists/sign_in" exact element={ <JournalistsSignIn /> } />
        <Route path="/journalists_show/:id" exact element={ <PageContainer view="Journalist"/> } />
        <Route path="/journalists/:action" exact element={ <PageContainer view="Journalists"/> } />
        <Route path="/media_list" exact element={ <PageContainer view="Medias"/> } />
        <Route path="/media_list/:action" exact element={ <PageContainer view="Medias"/> } />
        <Route path="/sign_in" exact element={ <Login /> } />
        <Route path="/sign_up" exact element={ <Register /> } />
        <Route path="/reset_password" exact element={ <ResetPassword /> } />
        <Route path="/change_password" exact element={ <ChangePassword /> } />
      </Routes>
    </BrowserRouter>
  );
}
