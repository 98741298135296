import React from "react";
import { Card, Text, Group, Badge, Anchor, Image } from '@mantine/core';
import classes from './PressReleaseCard.module.scss';
import { pressReleaseDate } from "@/utils/PressReleaseMixins";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export default function PressReleaseCard({ pressRelease, backUrl }) {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const tags = pressRelease.tags.map((tag, index) => (
    <Badge variant="light" key={index} className={classes.topicBadge}>
      {tag.name}
    </Badge>
  ));

  return (
    <Card shadow="xs" radius="md" p="xl" className={classes.card}>
      <Card.Section className={classes.section} mt="md">
        <Group justify="center" className={classes.title}>
          <Anchor
            // href={`/press-release/${pressRelease.uuid}`} target="_blank" rel="noopener noreferrer"
            onClick={() => navigate(`/press-release/${pressRelease.uuid}`, { state: { backUrl: backUrl }  })}
            className={classes.link}
          >
            <Image
              height={60}
              src={pressRelease.picture?.url || "https://placehold.co/200x60?text=No Image"}
            />
            <Text fz="lg" fw={500}>
              {pressRelease.name}
            </Text>
          </Anchor>
        </Group>
        <Text fz="sm" mt="xs" c="dimmed">
          {t('press_kits.published_at')} {pressReleaseDate(pressRelease.releaseDate)}
        </Text>
        <Text fz="sm" mt="xs" lineClamp={9} >
          <div className={classes.prBodyText} dangerouslySetInnerHTML={{ __html: pressRelease.bodyHtml }} />
        </Text>
      </Card.Section>

      <Card.Section className={classes.section}>
        <Text mt="md" className={classes.label} c="dimmed">
          {t('press_kits.topics')}
        </Text>
        {tags}
      </Card.Section>
    </Card>
  );
}