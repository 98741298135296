import {gql} from "@apollo/client";

export const CREATE_PRODUCT = gql`
mutation CreateProduct($product: ProductInput!) {
  createProduct(input: {product: $product}) {
    id
    name
    description
  }
}
`;

export const UPDATE_PRODUCT = gql`
mutation UpdateProduct($product: ProductInput!) {
  updateProduct(input: {product: $product}) {
    __typename
    id
    name
    description
    productImageUrl
  }
}
`;

export const DELETE_PRODUCT = gql`
mutation DeleteProduct($id: ID!) {
  deleteProduct(input: {id: $id}) {
    success
  }
}
`;