import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import {
  TextInput,
  Text,
  Paper,
  Group,
  Button,
  Space,
  Stack,
  Container,
  Alert,
  PasswordInput,
  Checkbox,
} from '@mantine/core';
import { verifyJournalistEmail, submitLogin, registerAccount } from "@/utils/login";
import { IconAlertCircle } from '@tabler/icons-react';
import {useTranslation} from "react-i18next";

export default function JournalistsSignIn(props) {
  const { t } = useTranslation(['translation']);
  const form = useForm({
    initialValues: { email: '', name: '', password: '', terms: false },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : t("auth.email_error")),
      password: (val) => (status === 'journalist' && val.length <= 6 ? t("auth.password_min_error") : null),
      terms: (val) => (status ==="journalist" && !val ? t("auth.terms_error") : null)
    },
  });
  const [status, setStatus] = useState(null)
  const [journalistId, setJournalistId] = useState(null)
  const [error, setError] = useState("");

  const handleSubmit = (values) => {
    setError("")
    switch(status) {
      case "user":
        return submitLogin({ email: values.email, password: values.password }, setError)
      case "journalist":
        return registerAccount({
          email: values.email,
          password: values.password,
          name: values.name,
          user_type: "journalist",
          journalist_id: journalistId
        }, setError)
      default:
        return verifyJournalistEmail({ email: values.email }, setError, setStatus, setJournalistId);
    }
  }

  return (
    <Container size="30rem" px={0} mt={"100px"}>
      { error &&
        <Alert icon={<IconAlertCircle size="1rem" />} color="red">
          {t("auth.record_not_found_error")}
        </Alert>
      }
      <Space h="md" />
      <Paper radius="md" p="xl" withBorder  {...props}>
        <Text size="lg" weight={500}>
          {t("auth.journalist_sign_in_header")}
        </Text>

        <Space h="md" />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Stack>
            <TextInput
              required
              label={t("auth.email_label")}
              placeholder="hello@prbox.com"
              disabled={status}
              value={form.values.email}
              onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
              error={form.errors.email && 'Invalid email'}
              radius="md"
            />
            {status === "user" &&
              <PasswordInput
                required
                label={t("auth.password_label")}
                placeholder={t("auth.password_placeholder")}
                mt="md"
                size="sm"
                radius="md"
                onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
              />}
            {status === "journalist" &&
              <>
                <TextInput
                  required
                  label={t("auth.name_label")}
                  placeholder={t("auth.name_placeholder")}
                  value={form.values.name}
                  onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
                  radius="md"
                />
                <PasswordInput
                  required
                  label={t("auth.password_label")}
                  placeholder={t("auth.create_password_placeholder")}
                  mt="md"
                  size="sm"
                  radius="md"
                  value={form.values.password}
                  onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                  error={form.errors.password && t("auth.password_min_error")}
                />
                <Checkbox
                  required
                  label={t("auth.terms_label")}
                  checked={form.values.terms}
                  onChange={(event) => form.setFieldValue('terms', event.currentTarget.checked)}
                  error={form.errors.terms && t("auth.terms_error")}
                />
              </>}

          </Stack>

          <Group position="apart" mt="xl">
            <div></div>
            <Button type="submit" radius="xl">
              {status === "user" ? t("auth.login_button") : t("auth.continue_button")}
            </Button>
          </Group>
        </form>
      </Paper>
    </Container>
  );
}
