import React from 'react';
import {
  Avatar,
  Group,
  Text,
  ActionIcon,
  Menu,
  rem,
  Image
} from '@mantine/core';
import {
  IconPencil,
  IconTrash,
  IconDotsVertical,
} from '@tabler/icons-react';
import DeleteMediaModal from './DeleteMediaModal';
import { useDisclosure } from "@mantine/hooks";
import EditMediaModal from './EditMediaModal';
import {useMutation} from "@apollo/client";
import {UPDATE_MEDIA} from "@/graphql/mutations/MediasMutations";
import noImage from "@/components/mocks/no-image.png";
import {useTranslation} from "react-i18next";

export default function Media({ media, classes, setMedias }) {
  const { t } = useTranslation(['translation']);
  const [isDeleteModalOpened, deleteModalHandlers] = useDisclosure(false);
  const [isEditModalOpened, editModalHandlers] = useDisclosure(false);
  const [_updateMedia] = useMutation(UPDATE_MEDIA)

  return (
   <tr className={classes.tr}>
      <td>
        <Group spacing="sm" style={{ flexWrap: "nowrap" }}>
          { media?.logoUrl ?
            <Avatar size={40} src={media?.logoUrl} radius={40} />
            : <Image src={ noImage} height={35} width={40} fit="contain" />
          }
          <div>
            <Text fz="sm" fw={600} style={{ whiteSpace: "nowrap" }}>
              {media.name}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        <Text fz="xs" lineClamp={3}>
          {media.description}
        </Text>
      </td>
      <td>
        <Group spacing={0} position="right">
          <Menu withinPortal position="bottom-end" shadow="sm">
            <Menu.Target>
              <ActionIcon radius="xl">
                <IconDotsVertical size="1rem"/>
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                icon={<IconPencil size={rem(14)} />}
                onClick={editModalHandlers.open}
              >
                {t('edit_button')}
              </Menu.Item>
              <Menu.Item
                icon={<IconTrash size={rem(14)} />}
                onClick={deleteModalHandlers.open}
                color="red"
              >
                {t('delete_button')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <DeleteMediaModal 
            opened={isDeleteModalOpened}
            close={deleteModalHandlers.close}
            setMedias={setMedias}
            mediaId={media.id}/>
          <EditMediaModal
            setMedias={setMedias}
            opened={isEditModalOpened}
            close={editModalHandlers.close}
            media={media}/>
        </Group>
      </td>
    </tr>
  );
}
