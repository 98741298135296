import React from "react";
import { Modal, Space, Group, Button, Anchor, Text } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { DELETE_FUNDING } from "~/graphql/mutations/FundingMutations";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function DeleteFundingModal({ opened, close, fundingId, setFundings }) {
  const { t } = useTranslation(['translation']);
  const [_deleteFunding] = useMutation(DELETE_FUNDING);
  const { id } = useParams();
  const deleteFunding = () => {
    _deleteFunding({
      variables: {
        id: fundingId,
      },
      update(_cache) {
        setFundings((fundings) => {
          const filtered = fundings.filter((funding) => funding.id !== fundingId);
          return [...filtered];
        })
      }
    });
    close();
  };
  return (
    <>
       <Modal opened={opened} onClose={close} centered>
        <Text align="center">
          {t('company.fundings.delete_confirm')}
        </Text>
        <Space h="xl" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button onClick={deleteFunding} color="red">
            {t('ok_button')}
          </Button>
        </Group>
      </Modal>
    </>
  );
}
