import React from "react";
import {
  Card,
  Group,
  Text,
  Menu,
  ActionIcon,
  Image,
  rem,
  Space,
  Tooltip,
} from "@mantine/core";
import { IconDots, IconEye, IconTrash } from "@tabler/icons-react";
import noImage from "../../mocks/no-image.png";
import { useParams } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import DeleteProductModal from "./DeleteProductModal";
import {useTranslation} from "react-i18next";

export default function ProductCard({ product }) {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const [isDeleteModalOpened, deleteModalHandlers] = useDisclosure(false);

  function slicedName() {
    if (product.name.length > 14) {
      return product.name.slice(0, 14) + "...";
    } else {
      return product.name;
    }
  }

  const handlePreview = () => {
    window.location.assign(`/companies/${id}/product/${product.id}/products`);
  };
  return (
    <>
      <Tooltip label={product.name} position="top" openDelay={500}>
        <Card shadow="sm" radius="md">
          <Card.Section inheritPadding py="xs">
            <Group position="apart">
              <Text weight={500}>{slicedName()}</Text>
              <Menu withinPortal position="bottom-end" shadow="sm">
                <Menu.Target>
                  <ActionIcon>
                    <IconDots size="1rem" />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    icon={<IconEye size={rem(14)} />}
                    onClick={handlePreview}
                  >
                    {t('company.product.preview_button')}
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconTrash size={rem(14)} />}
                    color="red"
                    onClick={deleteModalHandlers.open}
                  >
                    {t('company.product.delete_button')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Card.Section>

          <div style={{ cursor: "pointer" }} onClick={handlePreview}>
            <Card.Section>
              <Image
                src={product.productImageUrl || noImage}
                height={100}
                fit={product.productImageUrl ? "cover" : "contain"}
              />
            </Card.Section>
            <Space h="xs" />
            <div style={{ minHeight: 100 }}>
              <Text mt="sm" color="dimmed" size="sm">
                {product.description ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: product.description.slice(0, 140),
                    }}
                  />
                ) : (
                  t('company.product.no_description_text')
                )}
              </Text>
            </div>
          </div>
        </Card>
      </Tooltip>
      <DeleteProductModal
        opened={isDeleteModalOpened}
        close={deleteModalHandlers.close}
        productId={product.id}
      />
    </>
  );
}
