import React, {useContext} from "react";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {
  GET_PRESS_RELEASE_BY_UUID,
} from "@/graphql/queries/PressReleaseQueries";
import {useQuery} from "@apollo/client";
import NotFoundTitle from "../../../Shared/NotFoundTitle";
import {Badge, Text, Divider, List, Anchor, Space, LoadingOverlay} from '@mantine/core';
import { pressReleaseDate } from "@/utils/PressReleaseMixins";
import classes from "@/components/PressKits/PressReleases/PressReleaseCard.module.scss";
import { CurrentUser } from "~/contexts/CurrentUserContext";
import {useTranslation} from "react-i18next";


export default function PressReleasePage({ }) {
  const { t } = useTranslation(['translation']);
  const currentUser = useContext(CurrentUser);
  const {state} = useLocation();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { loading, error, data } = useQuery(GET_PRESS_RELEASE_BY_UUID, { variables: { uuid: uuid } });

  if (loading) return <LoadingOverlay visible={loading} overlayBlur={2} />;
  if (error) return <NotFoundTitle />;

const files = data.pressReleaseByUuid.pressReleaseFiles;
  const pressRelease = data.pressReleaseByUuid
  const tags = pressRelease.tags.map((tag, index) => (
    <Badge variant="light" key={index} className={classes.topicBadge}>
      {tag.name}
    </Badge>
  ));

  return (
    <div>
      { currentUser.id &&
        <Anchor
          underline={false}
          onClick={() => navigate(state?.backUrl)}
        >
          {`${t('press_kits.back_to_press_releases')}`}
        </Anchor>
      }
      <Text fw={700} mt={20}>
        {pressRelease.company.name}
      </Text>
      <h1>{pressRelease.subject}</h1>
      <Text fz="sm" mt="sm" c="dimmed">
        {t('press_kits.published_on')} {pressReleaseDate(pressRelease.releaseDate)} | {t('press_kits.topics')}: {tags}
      </Text>
      <Divider my="sm" />
      <Text size="lg">
        <div className={classes.prBodyText} dangerouslySetInnerHTML={{ __html: pressRelease.bodyHtml }} />
      </Text>
      <Divider my="sm" />
      <Text fw={700}>
        {t('press_kits.attachments')}
      </Text>
      <Space h="sm" />
      <List
        spacing="md"
        size="sm"
        center
      >
        {(files).map((file, index) => (
          <Anchor href={file.url} target="_blank" rel="noopener noreferrer" key={index}>
            <Text fw={700}>{file.filename || file.name }</Text>
          </Anchor>
        ))}
      </List>
    </div>
  );
}