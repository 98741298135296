import React, { useState, useEffect } from 'react';
import PressReleaseList from "@/components/PressReleases/PressReleaseList";
import {
  Divider,
  Grid,
  Group,
  Loader,
} from '@mantine/core';
import NewPressReleaseButton from "@/components/PressReleases/NewPressReleaseButton";
import {GET_ALL_PRESS_RELEASES} from "@/graphql/queries/PressReleaseQueries";
import {useLazyQuery, useQuery} from "@apollo/client";
import InfiniteScroll from 'react-infinite-scroll-component';
import {GET_TAGS} from "@/graphql/queries/TagQueries";
import Loading from "@/components/Shared/LoadingOverlay";
import {useTranslation} from "react-i18next";
import {searchWithoutTags} from "@/utils/search";
import Search from "@/components/Companies/Search/Search";
import classes from "@/components/Companies/Companies.module.scss";

export default function PressReleases() {
  const { t } = useTranslation(['translation']);
  const [search, setSearch] = useState("")
  const [sort, setSort] = useState('desc')
  const [sortBy, setSortBy] = useState('id')
  const [selectedTagIds, setSelectedTagIds] = useState([])

  const tagsQuery = useQuery(GET_TAGS);
  const [pressReleases, setPressReleases] = useState([])
  const [getPressReleases, { loading, data, error }] = useLazyQuery(GET_ALL_PRESS_RELEASES, { fetchPolicy: 'no-cache'});

  useEffect(() => {
    loadMore()
  }, [])

  useEffect(() => {
    if (data?.allPressReleases?.nodes) {
      setPressReleases((current) => [...current, ...data.allPressReleases.nodes])
    }
  }, [data])

  function loadMore() {
    getPressReleases({ variables: {sortBy: sortBy, sort: sort, after: data?.allPressReleases?.pageInfo?.endCursor, search: searchWithoutTags(search), selectedTagIds: selectedTagIds} })
  }

  function startSearch({tagIds, value}) {
    setPressReleases([])
    getPressReleases({ variables: {sortBy: sortBy, sort: sort, after: "", search: value, selectedTagIds: tagIds }})
  }

  function startSorting(field) {
    setPressReleases([]);
    const sortDirection = sort === 'asc' ? 'desc' : 'asc';
    setSort(sortDirection);
    setSortBy(field);
    getPressReleases({ variables: {sortBy: field, sort: sortDirection, after: "", search: searchWithoutTags(search), selectedTagIds: selectedTagIds} })
  };

  // function startFiltering(field) {
  //   setPressReleases([]);
  //   setFilter(field)
  //   getPressReleases({ variables: {sortBy: sortBy, sort: sort, after: "", search: searchWithoutTags(search), selectedTagIds: selectedTagIds} })
  // }

  if ((loading && !pressReleases.length) || tagsQuery.loading) return <Loading />;
  if (error || tagsQuery.error) return <p>{t('error')}</p>;

  return (
    <>
      <Grid align="center">
        <Grid.Col span={7}><h2>{t('press_releases.header')}</h2></Grid.Col>
        <Grid.Col span={5}>
          <Search
            startSearch={startSearch}
            search={search}
            setSearch={setSearch}
            setSelectedTagIds={setSelectedTagIds}
            selectedTagIds={selectedTagIds}
            label={t("search_by_pr_name_or_tag")}
          />
        </Grid.Col>
      </Grid>
      <Divider my="sm" className={classes.divider}/>
      <Group position="right">
        <NewPressReleaseButton />
      </Group>

      <InfiniteScroll
        dataLength={pressReleases.length}
        next={loadMore}
        hasMore={ data?.allPressReleases?.pageInfo?.hasNextPage }
        loader={ <Group justify="center" grow><Loader /></Group>}
        endMessage={<p>{t('no_more_data_text')}</p> }
      >
        <PressReleaseList
          sort={sort}
          startSorting={startSorting}
          pressReleasesData={pressReleases}
          setPressReleases={setPressReleases}
        />
      </InfiniteScroll>
    </>
  );
}