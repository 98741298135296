import React from "react";
import { Modal, Space, Group, Button, Anchor, Text } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { DELETE_PRODUCT } from "~/graphql/mutations/ProductMutations";
import { GET_PRODUCTS } from "@/graphql/queries/ProductQueries";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function DeleteProductModal({ opened, close, productId }) {
  const { t } = useTranslation(['translation']);
  const [_deleteProduct] = useMutation(DELETE_PRODUCT);
  const { id } = useParams();
  const deleteProduct = () => {
    _deleteProduct({
      variables: {
        id: productId,
      },
      refetchQueries: [
        {
          query: GET_PRODUCTS,
          variables: { companyId: id },
        },
      ],
    });
    close();
  };
  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        <Text align="center">
          {t('company.product.delete_confirm')}
        </Text>
        <Space h="xl" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button onClick={deleteProduct} color="red">
            {t('ok_button')}
          </Button>
        </Group>
      </Modal>
    </>
  );
}
