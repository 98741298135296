import React from "react";
import { Card, Group, Image, Text, Modal, Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import ImageUploader from "../../ImageUploader/ImageUploader";
import useStyles from "../../AboutCompany.styles";
import {useTranslation} from "react-i18next";

export default function CompanyLogo({ logoUrl, id }) {
  const { t } = useTranslation(['translation']);
  const { classes } = useStyles();
  const [modalOpened, { open: modalOpen, close: modalClose }] =
    useDisclosure(false);
  return (
    <>
      <Card radius={8} my="md">
        <Group position="apart" align="flex-start">
          <Text>{t('company.logo_text')}</Text>
          <Button
            variant="subtle"
            radius="xl"
            size="xs"
            compact
            onClick={modalOpen}
          >
            {t('edit_button')}
          </Button>
        </Group>
        <Image src={logoUrl} className={classes.logo} />
      </Card>
      <Modal
        opened={modalOpened}
        onClose={modalClose}
        title="Image uploader"
        centered
      >
        <ImageUploader id={id} close={modalClose} />
      </Modal>
    </>
  );
}
