import { gql } from "@apollo/client";

export const PRESSKIT_FOLLOW = gql`
  query PresskitFollow($id: ID!, $userId: ID!){
    presskitFollow(id: $id, userId: $userId) {
      id
      follow
    }
  }
`;
