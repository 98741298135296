import React from "react";
import {Button, Input} from "@mantine/core";
import classes from "../inputs.module.scss";
import {useTranslation} from "react-i18next";
export default function Preview({ label, value, setEditMode, editButton }) {
  const { t } = useTranslation(['translation']);

  return (
    <>
      <Input.Wrapper
        label={label}
      >
        <p className={classes.fieldValue}>{value}</p>
      </Input.Wrapper>
      { editButton && <Button
        variant="light"
        radius="xl"
        size="xs"
        compact
        onClick={() => setEditMode(true)}
      >
        {t('accounts.edit_button')}
      </Button> }
    </>
  );
}