import React from "react";
import { Button, FileButton, Grid, LoadingOverlay } from "@mantine/core";
import AttachmentCard from "./AttachmentCard";
import { Anchor } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ATTACHMENTS } from "@/graphql/queries/AttachmentQueris";
import { GET_FOLDER } from "@/graphql/queries/FolderQueries";
import { BULK_CREATE_ATTACHMENTS } from "@/graphql/mutations/AttachmentMutations";
import { allowedFormats } from "@/utils/attachmentFormats";
import { allowedDocumentFormats } from "@/utils/attachmentFormats";
import AttachmentsDropZone from "@/components/Companies/Attachments/AttachmentsDropZone";
import {useTranslation} from "react-i18next";
import {IconPlus} from "@tabler/icons-react";

export default function Folder() {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const { id, attachment_type, folder_id } = useParams();
  const folder = useQuery(GET_FOLDER, { variables: {folderId: folder_id}})
  const attachments = useQuery(GET_ATTACHMENTS, {
    variables: { companyId: id, folderId: folder_id },
  });
  const [_bulkCreateAttachments, { loading }] = useMutation(
    BULK_CREATE_ATTACHMENTS
  );

  if (attachments.loading || folder.loading) return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (attachments.error) return <p>Error :(</p>;

  function handleFileChange(files) {
    _bulkCreateAttachments({
      variables: {
        files: files,
        folderId: folder_id,
        companyId: id,
        attachmentType: attachment_type,
      },
      refetchQueries: [
        {
          query: GET_ATTACHMENTS,
          variables: { companyId: id, folderId: folder_id },
        },
      ],
    });
  }

  return (
    <div>
      <AttachmentsDropZone
        fileAllowedFormats={allowedDocumentFormats}
        attachmentType={attachment_type}
        attachmentQuery={GET_ATTACHMENTS}
        folderId={folder_id}
      />
      <LoadingOverlay visible={loading} overlayBlur={2} />

      <Grid justify="space-between" align="center">
        <Grid.Col span={12}>
          <Anchor
            underline={false}
            onClick={() => navigate(`/companies/${id}/${attachment_type}`)}
          >
            {`${t('company.attachments.back_to')} ${attachment_type}`}
          </Anchor>
        </Grid.Col>
        <Grid.Col span="content">
          <h2>{folder.data.folder.name}</h2>
        </Grid.Col>
        <Grid.Col span="content">
          <FileButton
            onChange={handleFileChange}
            accept={allowedFormats(attachment_type).join(",")}
            multiple
          >
            {(props) => (
              <Button
                variant="light"
                size="sm"
                radius="xl"
                leftIcon={<IconPlus />}

                {...props}>
                {t('upload_button')}
              </Button>
            )}
          </FileButton>
        </Grid.Col>
      </Grid>

      <Grid gutter="lg">
        {attachments.data.getAttachments.map((attachment) => (
          <Grid.Col key={attachment.id} xs={12} sm={4} md={3} lg={3} xl={3}>
            <AttachmentCard
              attachment={attachment}
              attachmentQuery={GET_ATTACHMENTS}
              attachmentType={attachment_type}
            />
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
}
