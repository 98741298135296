import React from "react";
import { Modal, Space, Group, Button, Anchor, Text } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { DELETE_TAG } from "~/graphql/mutations/TagMutations";
import { GET_TAGS } from "@/graphql/queries/TagQueries";
import {useTranslation} from "react-i18next";

export default function DeleteTagModal({ opened, close, tagId }) {
  const { t } = useTranslation(['translation']);
  const [_deleteTag] = useMutation(DELETE_TAG);
  const deleteTag = () => {
    _deleteTag({
      variables: { id: tagId },
      refetchQueries: [
        { query: GET_TAGS },
      ],
    });
    close();
  };
  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        <Text align="center">
          {t('tags.delete_confirm')}
        </Text>
        <Space h="xl" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button onClick={deleteTag} color="red">
            {t('ok_button')}
          </Button>
        </Group>
      </Modal>
    </>
  );
}
