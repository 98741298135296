import { gql } from "@apollo/client";

export const SEND_ACCOUNT_INVITATION = gql`
mutation SendAccountInvitation($email: String!) {
  sendAccountInvitation(input: {email: $email}) {
    id
    email
    status
  }
}
`;

export const DELETE_ACCOUNT_INVITATION = gql`
  mutation DeleteInvitation($email: String!) {
  deleteInvitation(input: {email: $email}) {
    success
  }
}
`;