import React from 'react';
import { Title, Text, Button, Container, Group } from '@mantine/core';
import classes from './NotFoundTitle.module.css';
import {useTranslation} from "react-i18next";

export default function NotFoundTitle() {
  const { t } = useTranslation(['translation']);

  return (
    <Container className={classes.root}>
      <div className={classes.label}>404</div>
      <Title className={classes.title}>{t("404.title")}</Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        {t("404.message")}
      </Text>
    </Container>
  );
}