import React from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_FUNDING } from '~/graphql/mutations/FundingMutations'
import { Button } from '@mantine/core'
import {useTranslation} from "react-i18next";

export default function UpdateFunding({ submit, id, name, date, numberOfInvestors, moneyRaised, leadInvestors, setFundings }) {
  const { t } = useTranslation(['translation']);
  const [_updateFunding] = useMutation(UPDATE_FUNDING)

  function handleUpdateFunding() {
    _updateFunding({
      variables: {
        funding: {
          id: id,
          name: name,
          date: date,
          numberOfInvestors: numberOfInvestors,
          moneyRaised: moneyRaised,
          leadInvestors: leadInvestors,
        },
      },
      update(cache, { data: { updateFunding } }) {
        setFundings((fundings) => {
          return fundings.map((funding) => {
            if (funding.id === updateFunding.id) { return updateFunding }

            return funding
          });
        });
      }
    })
    submit()
  }

  return <Button onClick={handleUpdateFunding}>{t('ok_button')}</Button>
}
