import {gql} from "@apollo/client";

export const CREATE_JOURNALIST = gql`
mutation CreateJournalist($journalist: JournalistInput!) {
  createJournalist(input: {journalist: $journalist}) {
    __typename
    id
    name
    email
    status
    description
    media {
      id
      name
    }
    tags {
      id
      name
    }
  }
}
`;

export const UPDATE_JOURNALIST = gql`
mutation UpdateJournalist($journalist: JournalistInput!) {
  updateJournalist(input: {journalist: $journalist}) {
    __typename
    id
    name
    email
    status
    description
    media {
      id
      name
    }
    tags {
      id
      name
    }
  }
}
`;

export const DELETE_JOURNALIST = gql`
mutation DeleteJournalist($id: ID!) {
  deleteJournalist(input: {id: $id}) {
    success
  }
}
`;

export const BULK_CREATE_JOURNALISTS = gql`
mutation BulkCreateJournalists($file: Upload!) {
  bulkCreateJournalists(input: {file: $file}) {
    success
    error
  }
}
`;
