import {gql} from "@apollo/client";

export const CREATE_TAG = gql`
mutation CreateTag($tag: TagInput!) {
  createTag(input: {tag: $tag}) {
    id
    name
    description
  }
}
`;

export const UPDATE_TAG = gql`
mutation UpdateTag($tag: TagInput!) {
  updateTag(input: {tag: $tag}) {
    __typename
    id
    name
    description
  }
}
`;

export const DELETE_TAG = gql`
mutation DeleteTag($id: ID!) {
  deleteTag(input: {id: $id}) {
    success
  }
}
`;
