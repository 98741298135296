import React from "react";
import { Modal, Space, Group, Button, Anchor, Text } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { DELETE_JOURNALIST } from "~/graphql/mutations/JournalistMutations";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function DeleteJournalistModal({ opened, close, journalistId, setJournalists }) {
  const { t } = useTranslation(['translation']);
  const [_deleteJournalist] = useMutation(DELETE_JOURNALIST);
  const { id } = useParams();
  const deleteJournalist = () => {
    _deleteJournalist({
      variables: {
        id: journalistId,
      },
      update(_cache) {
        setJournalists((journalists) => {
          const index = journalists.findIndex((journalist) => journalist.id === journalistId);
          journalists.splice(index, 1);
          return [...journalists];
        })
      }
    });
    close();
  };
  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        <Text align="center">
          {t('journalists.delete_confirm')}
        </Text>
        <Space h="xl" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button onClick={deleteJournalist} color="red">
            {t('ok_button')}
          </Button>
        </Group>
      </Modal>
    </>
  );
}
