import React from "react";
import { Modal, Space, Group, Button, Anchor, Text } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { DELETE_MEDIA } from "~/graphql/mutations/MediasMutations";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function DeleteMediaModal({ opened, close, mediaId, setMedias }) {
  const { t } = useTranslation(['translation']);
  const [_deleteMedia] = useMutation(DELETE_MEDIA);
  const { id } = useParams();
  const deleteMedia = () => {
    _deleteMedia({
      variables: {
        id: mediaId,
      },
      update(_cache) {
        setMedias((medias) => {
          const index = medias.findIndex((media) => media.id === mediaId);
          medias.splice(index, 1);
          return [...medias];
        })
      }
    });
    close();
  };
  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        <Text align="center">
          {t('medias.delete_confirm')}
        </Text>
        <Space h="xl" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button onClick={deleteMedia} color="red">
            {t('ok_button')}
          </Button>
        </Group>
      </Modal>
    </>
  );
}
