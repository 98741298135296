import React from 'react';
import {Grid, ActionIcon, Menu, rem} from '@mantine/core';
import {IconDotsVertical, IconPencil, IconTrash} from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import DeleteTagModal from "./DeleteTagModal";
import EditTagModal from "./EditTagModal";
import {useTranslation} from "react-i18next";

export default function TagRow({ id, name, description }) {
  const { t } = useTranslation(['translation']);
  const [isDeleteModalOpened, deleteModalHandlers] = useDisclosure(false);
  const [isEditModalOpened, editModalHandlers] = useDisclosure(false);

  return (
    <tr key={name}>
      <td>{name}</td>
      <td>{description}</td>
      <td>

        <Grid justify={"end"} align={"center"}>
          <Menu withinPortal position="bottom-end" shadow="sm">
            <Menu.Target>
              <ActionIcon radius="xl">
                <IconDotsVertical size="1rem"/>
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                icon={<IconPencil size={rem(14)} />}
                onClick={editModalHandlers.open}
              >
                {t('edit_button')}
              </Menu.Item>
              <Menu.Item
                icon={<IconTrash size={rem(14)} />}
                color="red"
                onClick={deleteModalHandlers.open}
              >
                {t('delete_button')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Grid>
        <DeleteTagModal
            opened={isDeleteModalOpened}
            close={deleteModalHandlers.close}
            tagId={id}
        />
        <EditTagModal
            opened={isEditModalOpened}
            close={editModalHandlers.close}
            tagId={id}
            tagName={name}
            tagDescription={description}
        />
      </td>
    </tr>
  );
}
