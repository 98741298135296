import React from 'react';

export default function Funding({ funding }) {
  return (
    <tr key={funding.id}>
      <td>{funding.name}</td>
      <td>{funding.date}</td>
      <td>{funding.numberOfInvestors}</td>
      <td>{funding.moneyRaised}</td>
      <td>{funding.leadInvestors}</td>
    </tr>
  );
}
