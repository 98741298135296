import { gql } from "@apollo/client";

export const UPDATE_COMPANY = gql`
mutation UpdateCompany($company: CompanyInput!) {
  updateCompany(input: {company: $company}) {
    id
    name
    officeAddress
    description
    formationDate
    businessType
    turnover
    funding
    employeesCount
    webUrl
    facebookUrl
    linkedinUrl
    twitterUrl
    instagramUrl
    tagline
    descriptionMore
    logoUrl
    tags {
      id
      name
    }
  }
}
`;

export const DELETE_COMPANY = gql`
mutation DeleteCompany($id: ID!) {
  deleteCompany(input: {id: $id}) {
    success
  }
}
`;

export const PUBLISH_PRESSKIT = gql`
mutation PublishPresskit($id: ID!, $value: Boolean!) {
  publishPresskit(input: {id: $id, value: $value}) {
    id
    name
    officeAddress
    description
    formationDate
    businessType
    turnover
    funding
    employeesCount
    webUrl
    facebookUrl
    linkedinUrl
    twitterUrl
    instagramUrl
    tagline
    descriptionMore
    logoUrl
    status
    tags {
      id
      name
    }
  }
}
`;