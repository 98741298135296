import React, { useState } from "react";
import {
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBrandInstagram,
} from "@tabler/icons-react";
import {
  ActionIcon,
  Card,
  Group,
  TextInput,
  Stack,
  Text,
  Tooltip,
  Button,
} from "@mantine/core";
import useStyles from "../../AboutCompany.styles";
import { useForm } from "@mantine/form";
import SubmitButtons from "@/components/Companies/AboutCompany/InputCards/shared/SubmitButtons";
import {useTranslation} from "react-i18next";

export default function CompanySocials({
  facebookUrl,
  linkedinUrl,
  twitterUrl,
  instagramUrl,
  updateCompany,
}) {
  const { t } = useTranslation(['translation']);
  const { classes } = useStyles();
  const [editMode, setEditMode] = useState(false);
  const label = t('company.socials_label');

  const urlRegex = new RegExp(
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
  );

  const form = useForm({
    initialValues: {
      facebook: facebookUrl || "",
      linkedin: linkedinUrl || "",
      twitter: twitterUrl || "",
      instagram: instagramUrl || "",
    },

    validate: {
      facebook: (value) => (urlRegex.test(value) ? null : t('company.socials_invalid')),
      linkedin: (value) => (urlRegex.test(value) ? null : t('company.socials_invalid')),
      twitter: (value) => (urlRegex.test(value) ? null : t('company.socials_invalid')),
      instagram: (value) => (urlRegex.test(value) ? null : t('company.socials_invalid')),
    },
  });
  const saveCompanyChanges = () => {
    updateCompany({
      facebookUrl: form.values.facebook,
      linkedinUrl: form.values.linkedin,
      twitterUrl: form.values.twitter,
      instagramUrl: form.values.instagram,
    });
    setEditMode(false);
  };
  const cancelChanges = () => {
    setEditMode(false);
    form.reset();
  };

  return (
    <>
      <Card radius={8} my="md" className={classes.inputCard}>
        <Group position="apart" align="flex-start" spacing={0}>
          {editMode ? (
            <form
            // onSubmit={form.onSubmit((values) => console.log(values))}
              style={{ width: "95%" }}
            >
              <TextInput
                label={label}
                icon={<IconBrandFacebook strokeWidth={1.5} />}
                value={form.values.facebook}
                placeholder={t("company.social_facebook")}
                {...form.getInputProps("facebook")}
              />
              <TextInput
                py={"xs"}
                icon={<IconBrandLinkedin strokeWidth={1.5} />}
                value={form.values.linkedin}
                placeholder={t("company.social_linkedin")}
                {...form.getInputProps("linkedin")}
              />
              <TextInput
                py={"xs"}
                icon={<IconBrandTwitter strokeWidth={1.5} />}
                value={form.values.twitter}
                placeholder={t("company.social_twitter")}
                {...form.getInputProps("twitter")}
              />
              <TextInput
                py={"xs"}
                icon={<IconBrandInstagram strokeWidth={1.5} />}
                value={form.values.instagram}
                placeholder={t("company.social_instagram")}
                {...form.getInputProps("instagram")}
              />
            </form>
          ) : (
            <Stack>
              <Text size={"sm"} weight={500}>
                {label}
              </Text>
              <Group>
                {form.isValid('facebook') && <ActionIcon
                  component="a"
                  href={form.values.facebook}
                  target="_blank"
                >
                  <IconBrandFacebook />
                </ActionIcon>}
                {form.isValid('linkedin') && <ActionIcon
                  component="a"
                  href={form.values.linkedin}
                  target="_blank"
                >
                  <IconBrandLinkedin />
                </ActionIcon>}
                {form.isValid('twitter') && <ActionIcon
                  component="a"
                  href={form.values.twitter}
                  target="_blank"
                >
                  <IconBrandTwitter />
                </ActionIcon>}
                {form.isValid('instagram') && <ActionIcon
                  component="a"
                  href={form.values.instagram}
                  target="_blank"
                >
                  <IconBrandInstagram />
                </ActionIcon>}

                <Text className={classes.onlyChildText}>
                  {t('company.socials_placeholder')}
                </Text>
              </Group>
            </Stack>
          )}
          {!editMode &&
            <Button
              variant="subtle"
              radius="xl"
              size="xs"
              compact
              onClick={() => setEditMode(true)}
            >
              {t('edit_button')}
            </Button>}
        </Group>
        { editMode && <SubmitButtons
          cancelChanges={cancelChanges}
          saveCompanyChanges={saveCompanyChanges}
        /> }
      </Card>
    </>
  );
}
