import React from "react";
import { Modal, Space, Group, Button, Anchor, Text } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { DELETE_SERVICE } from "~/graphql/mutations/ServiceMutations";
import { GET_SERVICES } from "@/graphql/queries/ServiceQueries";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function DeleteServiceModal({ opened, close, serviceId }) {
  const { t } = useTranslation(['translation']);
  const [_deleteService] = useMutation(DELETE_SERVICE);
  const { id } = useParams();
  const deleteService = () => {
    _deleteService({
      variables: {
        id: serviceId,
      },
      refetchQueries: [
        {
          query: GET_SERVICES,
          variables: { companyId: id },
        },
      ],
    });
    close();
  };
  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        <Text align="center">
          {t('company.services.delete_confirm')}
        </Text>
        <Space h="xl" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button onClick={deleteService} color="red">
            {t('ok_button')}
          </Button>
        </Group>
      </Modal>
    </>
  );
}
