import React from "react";
import Preview from "../Preview/Preview";
import {Card, Group} from "@mantine/core";
import {useTranslation} from "react-i18next";
export default function Email({ email }) {
  const { t } = useTranslation(['translation']);

  return (
    <Card radius={8} my="md">
      <Group position="apart" align="flex-start" spacing={0}>
        <Preview
          label={t("accounts.email_label")}
          value={email}
          editButton={false}
        />
      </Group>
    </Card>
  );
}