import React from "react";
import { Navbar, UnstyledButton } from "@mantine/core";
import {
  IconUsers,
  IconUserCircle,
} from "@tabler/icons-react";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "./NavbarCompanyView.module.scss";
import {useTranslation} from "react-i18next";

export default function NavbarAccountView() {
  // const { classes, cx } = useStyles();
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const location = useLocation();

  const links = [
    { link: "/personal", label: t('accounts.sidebar.account'), icon: IconUserCircle },
    { link: "/account", label: t('accounts.account_header'), icon: IconUsers },
  ];

  const mainLinks = links.map((link) => (
    <UnstyledButton
      key={link.label}
      className={classes.mainLink + " " + (location.pathname.endsWith(link.link) ? classes.linkActive : "")}
      onClick={() => navigate(link.link)}
    >
      <div
        className={classes.mainLinkInner}
      >
        <link.icon
          size={20}
          className={classes.mainLinkIcon}
          stroke={1.5} />
        <span>{link.label}</span>
      </div>
    </UnstyledButton>
  ));

  return (
    <div
        className={classes.navbar}
    >
      <Navbar.Section
        // className={classes.section}
      >
        <div
          className={classes.mainLinks}
        >{mainLinks}</div>
      </Navbar.Section>
    </div>
  );
}
