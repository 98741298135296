import React from 'react';
import {Modal, Button, Group, Text, Space, Anchor} from '@mantine/core';
import {useTranslation} from "react-i18next";

export default function PublishConfirmationModal({close, opened, disabled, pressRelease, publishPressReleaseHandler}) {
  const { t } = useTranslation(['translation']);
  return (
    <>
      {console.log()}
      <Modal opened={opened} onClose={close} centered>
        <Text>
          {t('press_releases.publish_confirm')}
        </Text>
        <Space h="xl" />
        {pressRelease?.subject && <>
          <Text fs="italic" bg="gray.1" p="sm">
            {pressRelease?.subject}
          </Text>
          <Space h="xl" />
        </>}
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>

          <Button
            disabled={disabled}
            size="sm"
            onClick={publishPressReleaseHandler}
          >
            {t('press_releases.yes_button')}
          </Button>
        </Group>
      </Modal>
    </>
  );
}