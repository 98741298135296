import React from "react";
import { Modal, Space, Group, Button, Anchor, Text } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { DELETE_TEAM_MEMBER } from "~/graphql/mutations/TeamMemberMutations";
import { GET_TEAM_MEMBERS } from "@/graphql/queries/TeamMemberQueries";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function DeleteMemberModal({ opened, close, memberId }) {
  const { t } = useTranslation(['translation']);
  const [_deleteTeamMember] = useMutation(DELETE_TEAM_MEMBER);
  const { id } = useParams();
  const deleteTeamMember= () => {
    _deleteTeamMember({
      variables: {
        id: memberId,
      },
      refetchQueries: [
        {
          query: GET_TEAM_MEMBERS,
          variables: { companyId: id },
        },
      ],
    });
    close();
  };
  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        <Text align="center">
          {t('company.members.delete_confirm')}
        </Text>
        <Space h="xl" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button onClick={deleteTeamMember} color="red" radius={"xl"}>
            {t('ok_button')}
          </Button>
        </Group>
      </Modal>
    </>
  );
}
