import React from "react";
import {Button, Group, Tooltip, Space} from "@mantine/core";
import {useTranslation} from "react-i18next";


export default function SubmitButtons({cancelChanges, saveChanges}) {
  const { t } = useTranslation(['translation']);

  return (
    <Group position="right" mt={20}>
        <Tooltip
          label={t('accounts.cancel_changes_button')}
          withArrow
          position="right"
          openDelay={300}
        >
          <Button
            variant="subtle"
            radius="xl"
            size="xs"
            compact
            onClick={() => cancelChanges()}
          >
            {t('cancel_button')}
          </Button>
        </Tooltip>

        <Button
          radius="xl"
          size="xs"
          compact
          onClick={() => saveChanges()}
        >
          {t('save_button')}
        </Button>
    </Group>
  );
}