import React from 'react';
import {Button, Grid, Menu, rem, FileButton, Text, LoadingOverlay} from "@mantine/core";
import {IconFolderFilled, IconPlus, IconUpload} from "@tabler/icons-react";
import {useDisclosure} from "@mantine/hooks";
import NewFolderModal from "@/components/Companies/Attachments/NewFolderModal";
import {useParams} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {BULK_CREATE_ATTACHMENTS} from "@/graphql/mutations/AttachmentMutations";
import {useTranslation} from "react-i18next";

export default function HeaderPage({ header, attachmentType, attachmentQuery, fileAllowedFormats }) {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const [opened, {open, close}] = useDisclosure(false);
  const [_bulkCreateAttachments, {loading}] = useMutation(BULK_CREATE_ATTACHMENTS);

  function handleFileChange(files) {
    _bulkCreateAttachments({
      variables: {
        files: files,
        folderId: null,
        companyId: id,
        attachmentType: attachmentType
      },
      refetchQueries: [
        {
          query: attachmentQuery,
          variables: { companyId: id },
        },
      ],
    })
  }

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Grid justify="space-between" align="center">
        <Grid.Col span="content"><h2>{header}</h2></Grid.Col>
        <Grid.Col span="content">
          <Menu withinPortal shadow="sm">
            <Menu.Target>
              <Button
                variant="light"
                size="sm"
                radius="xl"
                leftIcon={<IconPlus />}
              >
                {t('company.attachments.new_button')}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Button.Group orientation="vertical">
                <Button leftIcon={<IconFolderFilled size={rem(14)} />} variant="subtle" onClick={open}>
                  {t('company.attachments.new_folder_button')}
                </Button>
                <FileButton onChange={handleFileChange} accept={fileAllowedFormats.join(',')} multiple>
                  {(props) => <Button
                    leftIcon={<IconUpload size={rem(14)} />}
                    variant="subtle"
                    {...props}
                  >
                    {t('company.attachments.upload_files_button')}
                  </Button>}
                </FileButton>
              </Button.Group>
            </Menu.Dropdown>
          </Menu>
        </Grid.Col>
      </Grid>
      <NewFolderModal opened={opened} close={close} attachmentType={attachmentType}/>
    </>
  );
}