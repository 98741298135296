import React, {useState} from 'react';
import {Modal, Space, Group, Button, Anchor, TextInput} from '@mantine/core';
import { CREATE_FOLDER } from '~/graphql/mutations/FolderMutations';
import { useMutation } from "@apollo/client";
import {useParams} from "react-router-dom";
import {GET_FOLDERS} from "@/graphql/queries/FolderQueries";
import {useTranslation} from "react-i18next";

export default function NewFolderModal({ opened, close, attachmentType }) {
  const { t } = useTranslation(['translation']);
  const [folderName, setFolderName] = useState("");
  const [error, setError] = useState(null);
  const [createFolder] = useMutation(CREATE_FOLDER);
  const { id } = useParams();

  const createNewFolder = () => {
    if (folderName === "") {
      setError(t('company.attachments.folder_name_error'));
      return;
    }

    if (folderName.length > 50) {
      setError(t('company.attachments.folder_length_error'));
      return;
    }

    createFolder({
      variables: {
        folder: {
          name: folderName,
          folderType: attachmentType,
          companyId: id
        }
      },
      refetchQueries: [
        {
          query: GET_FOLDERS,
          variables: { companyId: id, folderType: attachmentType },
        },
      ],
    });
    close();
    setFolderName("");
  }

  function onChange(event) {
    setFolderName(event.currentTarget.value);
    setError(null);
  }

  return (
    <>
      <Modal opened={opened} onClose={close} title={t('company.attachments.new_folder_header_text')} centered>
        <TextInput
          placeholder={t('company.attachments.folder_name_placeholder')}
          value={folderName}
          onChange={onChange}
          error={error}
        />
        <Space h="xs" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>{t('cancel_button')}</Anchor>
          <Button onClick={createNewFolder}>{t('ok_button')}</Button>
        </Group>
      </Modal>
    </>
  );
}