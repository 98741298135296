import React, {useState, useEffect, useRef} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
  Paper,
  Container,
  Anchor,
  Input,
  Textarea,
  Space,
  Text,
  Image,
  SimpleGrid, Grid, LoadingOverlay
} from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { GET_TEAM_MEMBER } from "@/graphql/queries/TeamMemberQueries";
import { UPDATE_TEAM_MEMBER } from "@/graphql/mutations/TeamMemberMutations";
import { useQuery, useMutation } from '@apollo/client';
import { debounce } from "lodash";
import {useTranslation} from "react-i18next";

export default function Member() {
  const { t } = useTranslation(['translation']);
  const { id, member_id } = useParams();
  const navigate = useNavigate();
  const teamMemberQuery = useQuery(GET_TEAM_MEMBER, { variables: { id: member_id } });
  const [updateTeamMember, { loading }] = useMutation(UPDATE_TEAM_MEMBER);

  const [teamMember, setTeamMember] = useState({
    name: '',
    role: '',
    description: '',
    linkedin: '',
    x: '',
    website: ''
  });

  useEffect(() => {
    if (teamMemberQuery.data) {
      setTeamMember({
        name: teamMemberQuery.data.teamMember.name || '',
        role: teamMemberQuery.data.teamMember.role || '',
        description: teamMemberQuery.data.teamMember.description || '',
        linkedin: teamMemberQuery.data.teamMember.linkedin || '',
        x: teamMemberQuery.data.teamMember.x || '',
        website: teamMemberQuery.data.teamMember.website || ''
      });
    }
  }, [teamMemberQuery.data]);

  const teamMemberRef = useRef(teamMember);
  useEffect(() => {
    teamMemberRef.current = teamMember;
  }, [teamMember]);

  const debouncedUpdate = useRef(
    debounce((newData) => {
      updateTeamMember({
        variables: { teamMember: { id: member_id, ...newData } }
      });
    }, 500)
  ).current;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTeamMember(prev => {
      const updated = { ...prev, [name]: value };
      teamMemberRef.current = updated;
      debouncedUpdate(updated);
      return updated;
    });
  };


  function uploadImage(files) {
    updateTeamMember({
        variables: {
            teamMember: {
                id: member_id,
                avatar: files[0]
            }
        }
    });
  }


  function savingState() {
    return loading ? t('saving_text') : t('saved_text');
  }

  if (teamMemberQuery.loading) return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (teamMemberQuery.error) return <p>Error :(</p>;

    return (
        <>

            <Grid justify="space-between" align="center">
                <Grid.Col span="content">
                    <Anchor
                        underline={false}
                        onClick={() => navigate(`/companies/${id}/team`) }
                    >
                        {`${t('company.members.back_to_all')}`}
                    </Anchor>
                </Grid.Col>
                <Grid.Col span="content">
                    <Text c="dimmed">{savingState()}</Text>
                </Grid.Col>
            </Grid>
            <Container my={30}>
                <Paper shadow="xs" p={30} radius="md" mt="xl">
                    <Input
                        name="name"
                        variant="filled"
                        placeholder={t('company.members.name_placeholder')}
                        size="xl"
                        value={teamMember.name}
                        onChange={handleInputChange}
                    />
                    <Space h="xl" />
                    <Input
                        name="role"
                        variant="filled"
                        placeholder={t('company.members.position_placeholder')}
                        size="xl"
                        value={teamMember.role}
                        onChange={handleInputChange}
                    />
                    <Space h="xl" />
                    <Input
                        name="linkedin"
                        variant="filled"
                        placeholder={t('company.members.linkedin_placeholder')}
                        size="xl"
                        value={teamMember.linkedin}
                        onChange={handleInputChange}
                    />
                    <Space h="xl" />
                    <Input
                        name="x"
                        variant="filled"
                        placeholder={t('company.members.twitter_placeholder')}
                        size="xl"
                        value={teamMember.x}
                        onChange={handleInputChange}
                    />
                    <Space h="xl" />
                    <Input
                        name="website"
                        variant="filled"
                        placeholder={t('company.members.website_placeholder')}
                        size="xl"
                        value={teamMember.website}
                        onChange={handleInputChange}
                    />

                    <Space h="xl" />
                    <Textarea
                        name="description"
                        placeholder={t('company.members.description_placeholder')}
                        variant="filled"
                        size="xl"
                        withAsterisk
                        autosize
                        minRows={4}
                        value={teamMember.description}
                        onChange={handleInputChange}
                    />
                    <Space h="xl" />
                    <Dropzone
                        accept={IMAGE_MIME_TYPE}
                        onDrop={(files) => { uploadImage(files); }}
                        multiple={false}
                    >
                        { !teamMemberQuery.data?.teamMember.avatarUrl && <Text align="center" >{t('company.members.drop_images_label')}</Text> }
                        <SimpleGrid
                        cols={4}
                        breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
                        >
                        { teamMemberQuery.data?.teamMember.avatarUrl &&  <Image
                            src={teamMemberQuery.data?.teamMember.avatarUrl}
                        />}
                        </SimpleGrid>
                    </Dropzone>
                </Paper>
            </Container>
        </>
    );
}