import React from "react";
import {
  Card,
  Group,
  Text,
  Menu,
  ActionIcon,
  rem,
  Tooltip,
} from "@mantine/core";
import {
  IconDotsVertical,
  IconEye,
  IconDownload,
  IconTrash,
  IconFolderSearch,
  IconPencil,
} from "@tabler/icons-react";
import MoveAttachmentModal from "./MoveAttachmentModal";
import RenameAttachmentModal from "./RenameAttachmentModal";
import DeleteAttachmentModal from "./DeleteAttachmentModal";
import AttachmentPreview from "@/components/Companies/Attachments/AttachmentPreview";
import { useDisclosure } from "@mantine/hooks";
import {useTranslation} from "react-i18next";

export default function AttachmentCard({
  attachmentQuery,
  attachment,
  attachmentType,
}) {
  const { t } = useTranslation(['translation']);
  const [isMoveModalOpened, moveModalHandlers] = useDisclosure(false);
  const [isRenameModalOpened, renameModalHandlers] = useDisclosure(false);
  const [isDeleteModalOpened, deleteModalHandlers] = useDisclosure(false);

  const downloadUrl = `/download/attachments/${attachment.id}`;

  function slicedName() {
    if (attachment.name.length > 11) {
      return attachment.name.slice(0, 11) + "…";
    } else {
      return attachment.name;
    }
  }
  const isImage = attachment.type === "IMAGE";

  return (
    <>
      <Tooltip label={attachment.name} openDelay={500}>
        <Card shadow="sm" radius="md" padding={10}>
          <Card.Section inheritPadding py="xs">
            <Group position="apart" noWrap={true}>
              <Text weight={500} size={14}>
                {slicedName()}
              </Text>
              <Menu withinPortal position="bottom-end" shadow="sm">
                <Menu.Target>
                  <ActionIcon size={22}>
                    <IconDotsVertical size="1rem" />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  {isImage && (
                    <Menu.Item
                      icon={<IconEye size={rem(14)} />}
                      component="a"
                      href={attachment.url}
                      target="_blank"
                    >
                      {t('company.attachments.preview_button')}
                    </Menu.Item>
                  )}
                  <Menu.Item
                    component="a"
                    href={downloadUrl}
                    download={attachment.name}
                    icon={<IconDownload size={rem(14)} />}
                  >
                    {t('company.attachments.download_button')}
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconPencil size={rem(14)} />}
                    onClick={renameModalHandlers.open}
                  >
                    {t('company.attachments.rename_button')}
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconFolderSearch size={rem(14)} />}
                    onClick={moveModalHandlers.open}
                  >
                    {t('company.attachments.move_button')}
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconTrash size={rem(14)} />}
                    color="red"
                    onClick={deleteModalHandlers.open}
                  >
                    {t('company.attachments.delete_button')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Card.Section>

          <Card.Section
            component={isImage ? "a" : "div"}
            href={attachment.url}
            target="_blank"
          >
            <AttachmentPreview attachment={attachment} />
          </Card.Section>
        </Card>
      </Tooltip>
      <MoveAttachmentModal
        opened={isMoveModalOpened}
        close={moveModalHandlers.close}
        attachment={attachment}
        attachmentType={attachmentType}
        attachmentQuery={attachmentQuery}
      />
      <RenameAttachmentModal
        opened={isRenameModalOpened}
        close={renameModalHandlers.close}
        attachmentId={attachment.id}
        attachmentQuery={attachmentQuery}
        attachmentName={attachment.name}
      />
      <DeleteAttachmentModal
        opened={isDeleteModalOpened}
        close={deleteModalHandlers.close}
        attachmentId={attachment.id}
        attachmentQuery={attachmentQuery}
      />
    </>
  );
}
