import React from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_MEDIA } from '~/graphql/mutations/MediasMutations'
import { Button } from '@mantine/core'
import {useTranslation} from "react-i18next";

export default function CreteMedia({ submit, name, logo, description, loadDefault }) {
  const [_createMedia] = useMutation(CREATE_MEDIA)
  const { t } = useTranslation(['translation']);

  function handleCreateMedia() {
    _createMedia({
      variables: {
        media: {
          name: name,
          logo: logo,
          description: description,
        },
      },
      update(_observableQuery) {
        loadDefault()
      },
    })
    submit()
  }

  return <Button onClick={handleCreateMedia}>{t('ok_button')}</Button>
}
