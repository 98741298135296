import {gql} from "@apollo/client";

export const CREATE_TEAM_MEMBER = gql`
mutation CreateTeamMember($teamMember: TeamMemberInput!) {
  createTeamMember(input: {teamMember: $teamMember}) {
    id
    name
    role
    description
    avatarUrl
    linkedin
    x
    website
  }
}
`;

export const UPDATE_TEAM_MEMBER = gql`
mutation UpdateTeamMember($teamMember: TeamMemberInput!) {
  updateTeamMember(input: {teamMember: $teamMember}) {
    __typename
    id
    name
    role
    description
    avatarUrl
    linkedin
    x
    website
  }
}
`;

export const DELETE_TEAM_MEMBER = gql`
mutation DeleteTeamMember($id: ID!) {
  deleteTeamMember(input: {id: $id}) {
    success
  }
}
`;
