import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import {
  TextInput,
  Text,
  Paper,
  Group,
  Button,
  Space,
  Anchor,
  Stack,
  Container,
  Alert,
} from '@mantine/core';
import { sendResetPassword } from "@/utils/login";
import { IconAlertCircle } from '@tabler/icons-react';
import {useTranslation} from "react-i18next";

export default function ResetPassword(props) {
  const { t } = useTranslation(['translation']);
  const form = useForm({
    initialValues: { email: '' },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : t('auth.email_error'))
    },
  });
  const [error, setError] = useState("");

  const handleSubmit = (values) => {
    sendResetPassword({ email: values.email }, setError);
  }

  return (
    <Container size="30rem" px={0} mt={"100px"}>
      <Paper radius="md" p="xl" withBorder  {...props}>
        <Text size="lg" weight={500}>
          {t('auth.reset_password')}
        </Text>

        { error &&
          <Alert icon={<IconAlertCircle size="1rem" />} color="red">
            {error}
          </Alert>
        }

        <Space h="md" />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Stack>

            <TextInput
              required
              label={t('auth.email_label')}
              placeholder={t('auth.email_address_placeholder')}
              value={form.values.email}
              onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
              error={form.errors.email && t('auth.email_error')}
              radius="md"
            />

          </Stack>

          <Group position="apart" mt="xl">
            <Group>
              <Anchor
                component="button"
                type="button"
                color="dimmed"
                onClick={() => window.location.assign("/sign_in")}
                size="xs"
              >
                {t('auth.login_button')}
              </Anchor>
              <Anchor
                component="button"
                type="button"
                color="dimmed"
                onClick={() => window.location.assign("/sign_up")}
                size="xs"
              >
                {t('auth.register_button')}
              </Anchor>
            </Group>
            <Button type="submit" radius="xl">
              {t('auth.reset_password_button')}
            </Button>
          </Group>
        </form>
      </Paper>
    </Container>
  );
}
