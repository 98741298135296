import React, {useState, useEffect} from 'react';
import { SimpleGrid, Space, Grid, Group, Loader } from '@mantine/core';
import CompanyCard from './CompanyCard';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_COMPANIES } from '~/graphql/queries/CompanyQueries';
import InfiniteScroll from 'react-infinite-scroll-component';
import {GET_TAGS} from "@/graphql/queries/TagQueries";
import Loading from "@/components/Shared/LoadingOverlay";
import Search from "@/components/Companies/Search/Search";
import {useTranslation} from "react-i18next";

export default function Companies() {
  const [search, setSearch] = useState("")
  const [selectedTagIds, setSelectedTagIds] = useState([])
  const { t } = useTranslation(['translation']);

  const tagsQuery = useQuery(GET_TAGS);
  const [companies, setCompanies] = useState([])
  const [getCompanies, { loading, data, error }] = useLazyQuery(GET_COMPANIES, { fetchPolicy: 'no-cache'});

  useEffect(() => {
    loadMore()
  }, [])

  useEffect(() => {
    if (data?.companies?.nodes) {
      setCompanies((current) => [...current, ...data.companies.nodes])
    }
  }, [data])

  const searchWithoutTags = search.replace(/#(\w+)?/g, '');

  function loadMore() {
    getCompanies({ variables: {after: data?.companies?.pageInfo?.endCursor, search: searchWithoutTags, filter: 'published', selectedTagIds: selectedTagIds} })
  }

  function startSearch({tagIds, value}) {
    setCompanies([])
    getCompanies({ variables: {after: "", search: value, filter: 'published', selectedTagIds: tagIds} })
  }

  if ((loading && !companies.length) || tagsQuery.loading) return <Loading />;
  if (error || tagsQuery.error) return <p>Error :</p>;

  return (
    <>
      <Grid justify="space-between" align="center">
        <Grid.Col span="content"><h2>Press kits</h2></Grid.Col>
        <Grid.Col span="content">
        </Grid.Col>
      </Grid>

      <Search
        startSearch={startSearch}
        search={search}
        setSearch={setSearch}
        setSelectedTagIds={setSelectedTagIds}
        selectedTagIds={selectedTagIds}
        label={t("search_pk_by_name_desc_tag")}
      />

      <Space h="md" />
      <InfiniteScroll
        dataLength={companies.length}
        next={loadMore}
        hasMore={ data?.companies?.pageInfo?.hasNextPage }
        loader={ <Group justify="center" grow><Loader /></Group>}
        endMessage={<p>No more data to load.</p> }
      >
        <Grid align="center">
          {companies.map((company) => (
            <Grid.Col lg={3} md={4} sm={6} xs={12} key={company.id}>
              <CompanyCard key={company.id} company={company} setCompanies={setCompanies} />
            </Grid.Col>
          ))}
        </Grid>
      </InfiniteScroll>
    </>
  );
}