import React from "react";
import {
  Card,
  Group,
  Text,
  Image,
  Space,
  Tooltip,
} from "@mantine/core";
import noImage from "../../mocks/no-image.png";
import {useTranslation} from "react-i18next";

export default function ProductCard({ product }) {
  const { t } = useTranslation(['translation']);
  function slicedName() {
    if (product.name.length > 14) {
      return product.name.slice(0, 14) + "...";
    } else {
      return product.name;
    }
  }

  return (
    <>
      <Tooltip label={product.name} position="top" openDelay={500}>
        <Card shadow="xs" radius="md">
          <Card.Section inheritPadding py="xs">
            <Group position="apart">
              <Text weight={500}>{slicedName()}</Text>
            </Group>
          </Card.Section>

          <div>
            <Card.Section mt="sm">
              <Image
                src={product.productImageUrl || noImage}
                height={120}
              />
            </Card.Section>
            <Space h="xs" />
            <div style={{ minHeight: 100 }}>
              <Text mt="sm" color="dimmed" size="sm">
                {product.description ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: product.description.slice(0, 140),
                    }}
                  />
                ) : (
                  t('press_kits.no_description')
                )}
              </Text>
            </div>
          </div>
        </Card>
      </Tooltip>
    </>
  );
}
