import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  Button,
  Divider,
  Checkbox,
  Anchor,
  Stack,
  Container,
  Alert,
} from '@mantine/core';
import { GoogleOmniauthForm } from './SocialButtons/SocialButtons';
import { registerAccount } from "@/utils/login";
import { IconAlertCircle } from '@tabler/icons-react';
import {useTranslation} from "react-i18next";

export default function Registration(props) {
  const { t } = useTranslation(['translation']);
  const form = useForm({
    initialValues: {
      email: '',
      name: '',
      password: '',
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : t("auth.email_error")),
      password: (val) => (val.length <= 6 ? t("auth.password_min_error") : null),
    },
  });
  const [error, setError] = useState("");

  const handleSubmit = (values) => {
    registerAccount({ email: values.email, password: values.password, name: values.name }, setError);
  }

  return (
    <Container size="30rem" px={0} mt={"100px"}>
      <Paper radius="md" p="xl" withBorder  {...props}>
        <Text size="lg" weight={500}>
          {t("auth.welcome")}
        </Text>

        <Group grow mb="md" mt="md">
          <GoogleOmniauthForm text={t("auth.social_buttons.sign_up_google")} />
        </Group>

        <Divider label="Or continue with email" labelPosition="center" my="lg" />

        { error &&
          <Alert icon={<IconAlertCircle size="1rem" />} color="red">
            {error}
          </Alert>
        }
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Stack>
            <TextInput
              label={t("auth.name_label")}
              placeholder={t("auth.name_placeholder")}
              value={form.values.name}
              onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
              radius="md"
            />

            <TextInput
              required
              label={t("auth.email_label")}
              placeholder={t("auth.email_address_placeholder")}
              value={form.values.email}
              onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
              error={form.errors.email && t("auth.email_error")}
              radius="md"
            />

            <PasswordInput
              required
              label={t("auth.password_label")}
              placeholder={t("auth.password_placeholder")}
              value={form.values.password}
              onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
              error={form.errors.password && t("auth.password_min_error")}
              radius="md"
            />

            <Checkbox
              label={t("auth.terms_label")}
              checked={form.values.terms}
              onChange={(event) => form.setFieldValue('terms', event.currentTarget.checked)}
            />
          </Stack>

          <Group position="apart" mt="xl">
            <Anchor
              component="button"
              type="button"
              color="dimmed"
              onClick={() => window.location.assign("/sign_in")}
              size="xs"
            >
              {t("auth.already_have_account")}
            </Anchor>
            <Button type="submit" radius="xl">
              {t("auth.register_button")}
            </Button>
          </Group>
        </form>
      </Paper>
    </Container>
  );
}