import React from 'react';
import {Button} from '@mantine/core';
import {IconPlus} from "@tabler/icons-react";
import {useDisclosure} from "@mantine/hooks";
import NewTagFormModal from "@/components/Tags/NewTagFormModal";
import {useTranslation} from "react-i18next";

export default function NewTagButton() {
  const { t } = useTranslation(['translation']);  
  const [opened, {open, close}] = useDisclosure(false);

  return (
    <>
      <Button
        variant="light"
        size="sm"
        radius="xl"
        leftIcon={<IconPlus />}
        onClick={open}
      >
        {t('tags.new_button')}
      </Button>
      <NewTagFormModal opened={opened} close={close}/>
    </>
  );
}