import React, {useState, useEffect} from 'react';
import {Grid, Table} from '@mantine/core';
import {useParams} from "react-router-dom";
import { useQuery } from '@apollo/client';
import { GET_FUNDINGS_BY_COMPANY_ID } from '~/graphql/queries/FundingQueries';
import {useTranslation} from "react-i18next";
import NewFundingButton from './NewFundingButton';
import Funding from './Funding';

export default function Fundings() {
  const { t } = useTranslation(['translation']);
  const [fundings, setFundings] = useState([])
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_FUNDINGS_BY_COMPANY_ID, { variables: { companyId: id } });

  useEffect(() => {
    if (data?.fundings) {
      setFundings(data.fundings)
    }
  }, [data])

  if (loading) return <p>{t('loading')}</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <Grid justify="space-between" align="center">
        <Grid.Col span="content"><h2>{t('company.fundings.header')}</h2></Grid.Col>
        <Grid.Col span="content">
          <NewFundingButton companyId={id} setFundings={setFundings} />
        </Grid.Col>
      </Grid>
      <Table>
        <thead>
          <tr>
            <th>{t('company.fundings.name_placeholder')}</th>
            <th>{t('company.fundings.date_placeholder')}</th>
            <th>{t('company.fundings.number_of_investors_label')}</th>
            <th>{t('company.fundings.money_raised_label')}</th>
            <th>{t('company.fundings.lead_investors_label')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{fundings.map((funding) => (
          <Funding
            key={funding.id}
            funding={funding}
            setFundings={setFundings}
          />
        ))}</tbody>
      </Table>

    </div>
  );
}