import { gql } from "@apollo/client";

export const GET_CURRENT_ACCOUNT = gql`
query GetCurrentAccount {
  getCurrentAccount {
    id
    name
    licenseType
    accountInvitations {
      id
      email
      status
    }
  }
}
`;

