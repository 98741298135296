import React, { useState } from "react";
import {Button, Card, Group, TextInput, Tooltip} from "@mantine/core";
import useStyles from "../../AboutCompany.styles";
import SubmitButtons from "@/components/Companies/AboutCompany/InputCards/shared/SubmitButtons";
import {useTranslation} from "react-i18next";

export default function CompanyTurnover({ turnover, updateCompany }) {
  const { t } = useTranslation(['translation']);
  const { classes } = useStyles();
  const [companyTurnover, setCompanyTurnover] = useState(turnover || "");
  const [editMode, setEditMode] = useState(false);
  const saveCompanyChanges = () => {
    updateCompany({ turnover: companyTurnover });
    setEditMode(false);
  };
  const cancelChanges = () => {
    setCompanyTurnover(turnover || "");
    setEditMode(false);
  };
  return (
    <>
      <Card radius={8} my="md" className={classes.inputCard}>
        <Group position="apart" align="flex-start" spacing={0}>
          <TextInput
            label={t('company.turnover_label')}
            placeholder={t('company.turnover_placeholder')}
            value={companyTurnover}
            onChange={(event) => setCompanyTurnover(event.currentTarget.value)}
            disabled={!editMode}
            w={"90%"}
            classNames={{
              label: classes.label,
              input: classes.input,
            }}
          />
          {!editMode &&
            <Button
              variant="subtle"
              radius="xl"
              size="xs"
              compact
              onClick={() => setEditMode(true)}
            >
              {t('edit_button')}
            </Button>}
        </Group>

        { editMode && <SubmitButtons
          cancelChanges={cancelChanges}
          saveCompanyChanges={saveCompanyChanges}
        /> }
      </Card>
    </>
  );
}
