import React from 'react';
import {Button} from '@mantine/core';
import {IconPlus} from "@tabler/icons-react";
import {useDisclosure} from "@mantine/hooks";
import NewJournalistModal from "@/components/Journalists/NewJournalistModal";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function NewMediaButton({ loadDefault }) {
  const { t } = useTranslation(['translation']);
  const { action } = useParams();
  const [opened, {open, close}] = useDisclosure(action === "new");

  return (
    <>
      <Button
        variant="light"
        size="sm"
        radius="xl"
        leftIcon={<IconPlus />}
        onClick={open}
      >
        {t('journalists.new_button')}
      </Button>
      <NewJournalistModal
        opened={opened}
        close={close}
        loadDefault={loadDefault}
      />
    </>
  );
}