import {gql} from "@apollo/client";

export const CREATE_PRESS_RELEASE = gql`
mutation CreatePressRelease($pressRelease: PressReleaseInput!) {
  createPressRelease(input: {pressRelease: $pressRelease}) {
    id
    subject
    companyId
    message
    tags {
      id
      name
    }
  }
}
`;

export const UPDATE_PRESS_RELEASE = gql`
mutation UpdatePressRelease($pressRelease: PressReleaseInput!) {
  updatePressRelease(input: {pressRelease: $pressRelease}) {
    id
    subject
    companyId
    message
    tags {
      id
      name
    }
  }
}
`;

export const PUBLISH_PRESS_RELEASE = gql`
mutation PublishPressRelease($id: ID!) {
  publishPressRelease(input: {id: $id}) {
    success
  }
}
`;

export const DELETE_PRESS_RELEASE = gql`
mutation DeletePressRelease($id: ID!) {
  deletePressRelease(input: {id: $id}) {
    success
  }
}
`;
