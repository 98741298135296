import { gql } from "@apollo/client";

export const CREATE_FUNDING = gql`
mutation CreateFunding($funding: FundingInput!) {
  createFunding(input: {funding: $funding}) {
    id
    companyId
    name
    numberOfInvestors
    moneyRaised
    leadInvestors
    date
  }
}
`;

export const DELETE_FUNDING = gql`
mutation DeleteFunding($id: ID!) {
  deleteFunding(input: {id: $id}) {
    success
  }
}
`;

export const UPDATE_FUNDING = gql`
mutation UpdateFunding($funding: FundingInput!) {
  updateFunding(input: {funding: $funding}) {
    id
    companyId
    name
    numberOfInvestors
    moneyRaised
    leadInvestors
    date
  }
}
`;
