import React from "react";
import {Alert, Grid, LoadingOverlay} from "@mantine/core";
import {useQuery} from "@apollo/client";
import {GET_PRESS_RELEASES_BY_COMPANY_UUID} from "@/graphql/queries/PressReleaseQueries";
import PressReleaseCard from "~/components/PressKits/PressReleases/PressReleaseCard";
import {useTranslation} from "react-i18next";
import {IconAlertCircle} from "@tabler/icons-react";

export default function PressReleases({ company }) {
  const { t } = useTranslation(['translation']);
  const { loading, error, data } = useQuery(GET_PRESS_RELEASES_BY_COMPANY_UUID, { variables: { companyUuid: company.externalUuid } });
  
  if (loading) return <LoadingOverlay visible={loading} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;
  const pressReleases = data.pressReleasesByCompanyUuid

  if (pressReleases.length === 0) {
    return (
      <Alert icon={<IconAlertCircle size="1rem" />} title={t('press_kits.nothing_added_yet')} color="indigo">
      </Alert>
    );
  }

  return (
    <Grid mt={12} >
      {pressReleases.map(pressRelease => (
        <Grid.Col key={pressRelease.id} span={4}>
          <PressReleaseCard
            pressRelease={pressRelease}
            backUrl={`/presskits/${company.externalUuid}/press-releases`}
          />
        </Grid.Col>
      ))}
    </Grid>
  );
}