import React, { useState } from "react";
import { Avatar,Card, Group, Button, SimpleGrid, Text, Space} from "@mantine/core";
import SubmitButtons from "../SubmitButtons/SubmitButtons";
import classes from "../inputs.module.scss";
import {useTranslation} from "react-i18next";
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
export default function Picture({ picture, updateUser }) {
  const { t } = useTranslation(['translation']);
  const [editMode, setEditMode] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const saveChanges = () => {
    updateUser({ avatar: avatar });
    setEditMode(false);
  };
  const cancelChanges = () => {
    setAvatar(picture || null);
    setEditMode(false);
  };
  function uploadImage(files) {
    setAvatar(files[0])
  }

  const preview = () => {
    const imageUrl = avatar ? URL.createObjectURL(avatar) : picture
    return (
      <Avatar src={imageUrl} size={80} radius={80} />
    );
  }

  return (
    <>
      <Card radius={8} my="md">
        <Group position="apart" align="flex-start" spacing={0}>

          { !editMode &&
            <>
              <Avatar src={picture} size={80} radius={80} />
              <Button
                variant="light"
                radius="xl"
                size="xs"
                compact
                onClick={() => setEditMode(true)}
              >
                {t('accounts.edit_button')}
              </Button>
            </>
          }
        </Group>

        { editMode &&
          <>
            {(picture || avatar) && <Group position="center">{preview()}</Group>}
            <Space h="md" />
            <Dropzone
              accept={IMAGE_MIME_TYPE}
              onDrop={(files) => { uploadImage(files); }}
              multiple={false}
            >
              <Text align="center">{t('accounts.avatar_upload')}</Text>
              <SimpleGrid
                cols={4}
                breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
              >
              </SimpleGrid>
            </Dropzone>
          </>
        }

        { editMode && <SubmitButtons
          cancelChanges={cancelChanges}
          saveChanges={saveChanges}
        /> }
      </Card>
    </>
  );
}
