import { gql } from "@apollo/client";

export const GET_PRODUCTS = gql`
query products($companyId: ID!) {
  products(companyId: $companyId) {
    __typename
    id
    companyId
    name
    description
    productImageUrl
  }
}
`;

export const GET_PRODUCTS_BY_COMPANY_UUID = gql`
query productsByCompanyUuid($companyUuid: String!) {
  productsByCompanyUuid(companyUuid: $companyUuid) {
    __typename
    id
    companyId
    name
    description
    productImageUrl
  }
}
`;

export const GET_PRODUCT = gql`
query product($id: ID!) {
  product(id: $id) {
    __typename
    id
    companyId
    name
    description
    productImageUrl
  }
}
`;
