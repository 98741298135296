import { gql } from "@apollo/client";

export const GET_FUNDINGS_BY_COMPANY_ID = gql`
query fundings($companyId: ID!) {
  fundings(companyId: $companyId) {
    id
    name
    companyId
    numberOfInvestors
    moneyRaised
    leadInvestors
    date
  }
}
`;

export const GET_FUNDINGS_BY_COMPANY_UUID = gql`
query fundingsByCompanyUuid($companyUuid: String!) {
  fundingsByCompanyUuid(companyUuid: $companyUuid) {
    id
    name
    companyId
    numberOfInvestors
    moneyRaised
    leadInvestors
    date
  }
}
`;
