import React, { useState } from "react";
import { IconEdit, IconCheck, IconCopy, IconX } from "@tabler/icons-react";
import {
  ActionIcon,
  Card,
  Group,
  Text,
  CopyButton,
  Tooltip,
  MultiSelect,
  Button, LoadingOverlay
} from "@mantine/core";
import useStyles from "../../AboutCompany.styles";
import { getTextFromHTML } from "../../../../../utils/getTextFromHTML";
import {useQuery} from "@apollo/client";
import {GET_TAGS} from "@/graphql/queries/TagQueries";
import SubmitButtons from "@/components/Companies/AboutCompany/InputCards/shared/SubmitButtons";
import {useTranslation} from "react-i18next";

export default function CompanyTopics({ tags, updateCompany }) {
  const { t } = useTranslation(['translation']);
  const { classes } = useStyles();
  const tagIds = tags?.map((tag) => tag.id);
  const [companyTopics, setCompanyTopics] = useState(tagIds || []);
  const [editMode, setEditMode] = useState(false);
  const label = t('company.topics_label');
  const placeholder = t('company.topics_placeholder');
  const { loading, error, data } = useQuery(GET_TAGS);

  if (loading) return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;

  const availableTags = data.tags.map((tag) => {
    return { label: tag.name, value: tag.id };
  });

  const saveCompanyChanges = () => {
    updateCompany({ tagIds: companyTopics });
    setEditMode(false);
  };
  const cancelChanges = () => {
    setCompanyTopics(tagIds || []);
    setEditMode(false);
  };

  return (
    <>
      <Card radius={8} my="md" className={classes.inputCard}>
        <Group position="apart" align="flex-start" spacing={0}>
            <Text size={"sm"} weight={500}>
              {label}
            </Text>

              {!editMode &&
                <Button
                  radius="xl"
                  size="xs"
                  compact
                  variant="subtle"
                  onClick={() => setEditMode(true)}
                >
                  {t('edit_button')}
                </Button>}

        </Group>
        {!editMode && companyTopics ? (
          <MultiSelect
            data={availableTags}
            defaultValue={companyTopics}
            placeholder={placeholder}
            variant="unstyled"
            readOnly
          />
        ) : (
          <div style={{ width: "95%" }}>
            <MultiSelect
              onChange={(value) => setCompanyTopics(value)}
              defaultValue={companyTopics}
              data={availableTags}
              placeholder={placeholder}
              searchable
            />
          </div>
        )}
        { editMode && <SubmitButtons
          cancelChanges={cancelChanges}
          saveCompanyChanges={saveCompanyChanges}
        /> }
      </Card>
    </>
  );
}
