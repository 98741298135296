import React, { createContext } from "react";

const CurrentUser = createContext({
  id: null,
  name: null,
  email: null,
  language: null,
  setCurrentUser: () => {},
});

export { CurrentUser };