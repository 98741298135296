import React, { useState } from "react";
import {Button, Card, Group, NumberInput, Tooltip} from "@mantine/core";
import useStyles from "../../AboutCompany.styles";
import SubmitButtons from "@/components/Companies/AboutCompany/InputCards/shared/SubmitButtons";
import {useTranslation} from "react-i18next";
export default function CompanyEmployees({ employeesCount, updateCompany }) {
  const { t } = useTranslation(['translation']);
  const { classes } = useStyles();
  const [companyEmployees, setCompanyEmployees] = useState(employeesCount || 0);
  const [editMode, setEditMode] = useState(false);
  const saveCompanyChanges = () => {
    updateCompany({ employeesCount: companyEmployees });
    setEditMode(false);
  };
  const cancelChanges = () => {
    setCompanyEmployees(employeesCount || 0);
    setEditMode(false);
  };
  return (
    <>
      <Card radius={8} my="md" className={classes.inputCard}>
        <Group position="apart" align="flex-start" spacing={0}>
          <NumberInput
            label={t('company.employees_number_label')}
            placeholder={t('company.employees_number_placeholder')}
            value={companyEmployees}
            onChange={(number) => setCompanyEmployees(number)}
            disabled={!editMode}
            w={"90%"}
            classNames={{
              label: classes.label,
              input: classes.input,
            }}
          />

          {!editMode &&
            <Button
              variant="subtle"
              radius="xl"
              size="xs"
              compact
              onClick={() => setEditMode(true)}
            >
              {t('edit_button')}
            </Button>}
        </Group>
        { editMode && <SubmitButtons
          cancelChanges={cancelChanges}
          saveCompanyChanges={saveCompanyChanges}
        /> }
      </Card>
    </>
  );
}
