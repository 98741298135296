import React, { useState } from 'react';
import {
    Paper,
    createStyles,
    TextInput,
    PasswordInput,
    Checkbox,
    Button,
    Divider,
    Group,
    Title,
    Text,
    Anchor,
    rem,
    Alert,
} from '@mantine/core';
import { GoogleOmniauthForm } from './SocialButtons/SocialButtons';
import { submitLogin } from "@/utils/login";
import {IconAlertCircle} from "@tabler/icons-react";
import {useTranslation} from "react-i18next";

const useStyles = createStyles((theme) => ({
    wrapper: {
        minHeight: rem(900),
        backgroundSize: 'cover',
        backgroundImage:
            'url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)',
    },

    form: {
        borderRight: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
        }`,
        minHeight: rem(900),
        maxWidth: rem(450),
        paddingTop: rem(80),

        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
        },
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
}));

export default function AuthenticationPage() {
    const { t } = useTranslation(['translation']);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { classes } = useStyles();
    const handleSubmit = (event) => {
        event.preventDefault();
        submitLogin({ email: email, password: password }, setError);
    };

    const registrationForm = (event) => {
        event.preventDefault();
        window.location.assign("/sign_up");
    };
    const resetPasswordForm = (event) => {
        event.preventDefault();
        window.location.assign("/reset_password");
    };
    const renderError = () => {
        if (error !== "") {
            return <div className="notice">{error}</div>;
        }
    };
    return (
        <div className={classes.wrapper}>
            <Paper className={classes.form} radius={0} p={30}>
                <Title order={2} className={classes.title} ta="center" mt="md" mb={50}>
                    {t("auth.welcome")}
                </Title>

                <TextInput
                    label={t("auth.email_address_label")}
                    placeholder={t("auth.email_address_placeholder")}
                    size="md"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <PasswordInput
                    label={t("auth.password_label")}
                    placeholder={t("auth.password_placeholder")}
                    mt="md"
                    size="md"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Checkbox label={t("auth.keep_me_in_label")} mt="xl" size="md" />
                <Button fullWidth mt="xl" size="md" onClick={handleSubmit}>
                    {t("auth.login_button")}
                </Button>

                <Divider label="Or" labelPosition="center" my="lg" />

                <Group grow mb="md" mt="md">
                    <GoogleOmniauthForm text={t("auth.social_buttons.sign_in_google")}/>
                </Group>

                <Text ta="center" mt="md">
                    {t("auth.no_account_text")}

                    <Anchor href="#" weight={700} onClick={registrationForm}>
                        &nbsp;{t("auth.register_button")}
                    </Anchor>
                </Text>
                <Text ta="center" mt="md">
                    <Anchor href="#" weight={700} onClick={resetPasswordForm}>
                        {t("auth.forgot_password")}
                    </Anchor>
                </Text>
                <br/>
                { error &&
                  <Alert icon={<IconAlertCircle size="1rem" />} color="red">
                      {error}
                  </Alert>
                }
            </Paper>
        </div>
    );
}