import { gql } from "@apollo/client";

export const FOLLOW_PRESSKIT = gql`
  mutation FollowPresskit($id: ID!, $userId: ID!) {
    followPresskit(input: {id: $id, userId: $userId}) {
      success
    }
  }
`;

export const UNFOLLOW_PRESSKIT = gql`
  mutation UnfollowPresskit($id: ID!, $userId: ID!) {
    unfollowPresskit(input: {id: $id, userId: $userId}) {
      success
    }
  }
`;
