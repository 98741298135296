import React, {useEffect} from 'react';
import { Switch, Tooltip, Container } from '@mantine/core';
import {useTranslation} from "react-i18next";
import { useDisclosure } from '@mantine/hooks';

export default function SendNotificationSwitch({onChange, value, readOnly, setEmailNotification}) {
  const { t } = useTranslation(['translation']);
  const [opened, { close, open }] = useDisclosure(false);
  useEffect(() => {
    setEmailNotification(value);
  },[])

  function handleChange(e) {
    setEmailNotification(e.target.checked);
    onChange(e.target.checked);
  }

  return (
    <Tooltip
      label="Vyberte tuto možnost, pokud chcete tiskovou zprávu odeslat e-mailem
      všem zvoleným novinářům. Pokud ji chcete pouze
      zveřejnit v PR BOXu, ponechte ji vypnutou."
      width={300}
      color="gray"
      position="top-start"
      multiline
      withArrow
      transitionProps={{ duration: 200 }}
    >
      <Container>
        <Switch
          disabled={readOnly}
          label={t('email_notification_label')}
          size="md"
          defaultChecked={value}
          onChange={handleChange}
        />
      </Container>
    </Tooltip>
  );
}