import React from 'react';
import {Button, LoadingOverlay} from '@mantine/core';
import { useMutation } from '@apollo/client';
import { CREATE_TEAM_MEMBER } from '~/graphql/mutations/TeamMemberMutations';
import {useNavigate, useParams} from "react-router-dom";
import { GET_TEAM_MEMBERS } from '~/graphql/queries/TeamMemberQueries';
import {IconPlus} from "@tabler/icons-react";
import {useTranslation} from "react-i18next";

export default function CreateTeamMember(companyId) {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const [createTeamMember, { loading, data }] = useMutation(CREATE_TEAM_MEMBER);
  const newTeamMemberParams = { teamMember: { name: "Untitled team member", companyId: id }}
  const navigate = useNavigate();

  const handleCreateTeamMember = () => {
    createTeamMember(
      {
        variables: newTeamMemberParams,
        refetchQueries: [
          {
            query: GET_TEAM_MEMBERS,
            variables: { companyId: id },
          },
        ],
      })
  }

  if (data?.createTeamMember?.id) navigate(`/companies/${id}/member/${data.createTeamMember.id}/team`)

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Button
        variant="light"
        size="sm"
        radius="xl"
        leftIcon={<IconPlus />}
        onClick={handleCreateTeamMember}
      >
        {t('company.members.add_button')}
      </Button>
    </>
  );
}
