const allowedDocumentFormats = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/rtf",
  "application/zip",
  "application/x-rar-compressed",
  "application/x-7z-compressed",
  "application/x-tar",
  "application/gzip",
  "application/x-bzip2",
  "application/x-msdownload",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.presentation",
  "application/epub+zip",
];

const allowedImageFormats = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/tiff",
  "image/webp",
  "image/svg+xml",
  "image/x-icon",
  "image/vnd.microsoft.icon",
  "image/jp2",
  "image/jpx",
  "image/jpm",
  "image/jxr",
  "image/ico",
  "image/icns",
  "image/ief",
  "image/pjpeg",
  "image/tiff-fx",
];

const allowedVideoFormats = [
  "video/mp4",
  "video/avi",
  "video/mkv",
  "video/mov",
  "video/wmv",
  "video/flv",
  "video/webm",
  "video/m4v",
  "video/3gpp",
  "video/mpeg",
  "video/mpg",
  "video/x-m4v",
  "video/mp2t",
  "video/mts",
  "video/vob",
  "video/ogg",
  "video/rm",
  "video/rmvb",
  "video/divx",
  "video/x-ms-asf",
  "video/mp2t",
  "video/x-f4v",
  "video/dv",
  "video/mpeg",
  "video/ogm",
  "video/quicktime",
  "video/x-msvideo",
  "video/flash",
];

const allowedFormats = (attachmentType) => {
  switch(attachmentType) {
    case 'images':
      return allowedImageFormats;
    case 'documents':
      return allowedDocumentFormats;
    case 'videos':
      return allowedVideoFormats;
    default:
      return [];
  }
}


export { allowedDocumentFormats, allowedImageFormats, allowedVideoFormats, allowedFormats };