import React, { useContext } from "react";
import Dashboard from "~/components/Dashboard/Dashboard";
import Companies from "~/components/Companies/Companies";
import Company from "~/components/Companies/Company";
import Team from "~/components/Companies/Team/Team";
import Member from "~/components/Companies/Team/Member";
import CompanyPressReleasesForm from "@/components/Companies/PressReleases/CompanyPressReleasesForm";
import CompanyPressReleases from "@/components/Companies/PressReleases/PressReleases";
import Images from "~/components/Companies/Images/Images";
import Videos from "~/components/Companies/Videos/Videos";
import Documents from "~/components/Companies/Documents/Documents";
import Folder from "@/components/Companies/Attachments/Folder";
import Services from "~/components/Companies/Services/Services";
import Products from "~/components/Companies/Products/Products";
import ServicePage from "~/components/Companies/Services/ServicePage";
import ProductPage from "~/components/Companies/Products/ProductPage";
import PressReleases from "~/components/PressReleases/PressReleases";
import PressReleaseForm from "~/components/PressReleases/PressReleaseForm";
import Account from "~/components/Account/Account";
import Tags from "~/components/Tags/Tags";
import Journalists from "~/components/Journalists/Journalists";
import JournalistShow from "~/components/Journalists/JournalistShow";
import Medias from "~/components/Medias/Medias";
import {AppShell, useMantineTheme, Container} from "@mantine/core";
import NavbarCompanyVew from "~/components/Layout/NavbarCompanyVew";
import NavbarAccountView from "~/components/Layout/NavbarAccountView";
import HeaderAction from "~/components/Layout/HeaderAction";
import Fundings from "../Companies/Fundings/Fundings";
import classes from "./AgencyView.module.scss";
import Personal from "@/components/Account/Personal";
import Contacts from "~/components/Companies/Contacts/Contacts";

export default function AgencyView({ view, sidebar }) {
  const theme = useMantineTheme();

  const renderPage = () => {
    switch(view) {
      case "Dashboard":
        return <Companies />;
      case "Personal":
        return <Personal />;
      case "Account":
        return <Account />;
      case "Companies":
        return <Companies />;
      case "Company":
        return <Company />;
      case "CompanyTeam":
        return <Team />;
      case "TeamMember":
        return <Member />;
      case "CompanyPressReleasesForm":
        return <CompanyPressReleasesForm />;
      case "CompanyPressReleases":
        return <CompanyPressReleases />;
      case "CompanyImages":
        return <Images />;
      case "CompanyVideos":
        return <Videos />;
      case "CompanyDocuments":
        return <Documents />;
      case "CompanyServicesPage":
        return <ServicePage />;
      case "CompanyServices":
        return <Services />;
      case "CompanyProducts":
        return <Products />;
      case "CompanyProductPage":
        return <ProductPage />;
      case "CompanyFundings":
        return <Fundings />
      case "CompanyContacts":
        return <Contacts />;
      case "PressReleases":
        return <PressReleases />;
      case "PressReleaseForm":
        return <PressReleaseForm />;
      case "Tags":
        return <Tags />;
      case "Journalists":
        return <Journalists />;
      case "Journalist":
        return <JournalistShow />;
      case "Medias":
        return <Medias />;
      case "Folder":
        return <Folder />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          // position: "absolute",
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      header={<HeaderAction />}
    >
      <Container size="xl" mt={20}>
        {(sidebar === "companyView" && <NavbarCompanyVew />) || (sidebar === "accountView" && <NavbarAccountView />)}
        <div
          className={sidebar ? classes.withSidebar : ""}
        >
          {renderPage()}
        </div>

      </Container>
    </AppShell>
  );
}