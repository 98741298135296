import React, {useState} from 'react';
import {Modal, Radio, Space, Group, Button, Anchor, LoadingOverlay} from '@mantine/core';
import {useMutation, useQuery} from "@apollo/client";
import {GET_FOLDERS} from "@/graphql/queries/FolderQueries";
import {useParams} from "react-router-dom";
import {UPDATE_ATTACHMENT} from "@/graphql/mutations/AttachmentMutations";
import {GET_ATTACHMENTS, GET_IMAGES, GET_VIDEOS, GET_DOCUMENTS} from "@/graphql/queries/AttachmentQueris"
import {useTranslation} from "react-i18next";

export default function MoveAttachmentModal({ opened, close, attachment, attachmentType, attachmentQuery }) {
  const { t } = useTranslation(['translation']);
  const { id, folder_id } = useParams();
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [updateAttachment] = useMutation(UPDATE_ATTACHMENT);
  const foldersResults = useQuery(GET_FOLDERS, {
    variables: { companyId: id, folderType: attachmentType },
  });

  if (foldersResults.loading) return <LoadingOverlay visible={true} overlayBlur={2} />;

  const getQueryToRefetch = () => {
    switch (attachmentType) {
      case 'images':
        return GET_IMAGES;
      case 'videos':
        return GET_VIDEOS;
      case 'documents':
        return GET_DOCUMENTS;
    }
  }
  const moveAttachment = () => {
    updateAttachment({
      variables: {
        attachment: {
          id: attachment.id,
          folderId: selectedFolderId,
        },
      },
      refetchQueries: [
        {
          query: attachmentQuery,
          variables: { companyId: id, folderId: folder_id }
        },
        {
          query: GET_ATTACHMENTS,
          variables: { companyId: id, folderId: selectedFolderId }
        },
        {
          query: getQueryToRefetch(),
          variables: { companyId: id }
        },
      ],
    });
    close();
  };

  return (
    <>
      <Modal opened={opened} onClose={close} title="Move attachment" centered>
        <Radio.Group
          name="folder"
          label={t('company.attachments.new_folder_location_label')}
          description={`${t('company.attachments.current_folder')} ${attachment.folder?.name || 'root'}`}
        >
          <Space h="xs" />
          {folder_id &&
            <>
              <Radio value={''} label="Root directory" onClick={() => setSelectedFolderId(folder_id)} /><Space h="xs" />
            </>
          }
          { foldersResults.data.folders.map((folder) => (
            <div key={folder.id}>
              <Radio key={folder.id} value={folder.id} label={folder.name} onClick={() => setSelectedFolderId(folder.id)} /><Space h="xs" />
            </div>
          ))}
        </Radio.Group>
        <br/>
        <Group position="right">
          <Anchor onClick={close} underline={false}>{t('cancel_button')}</Anchor>
          <Button onClick={moveAttachment}>{t('ok_button')}</Button>
        </Group>
      </Modal>
    </>
  );
}