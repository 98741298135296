import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import {
    Menu,
    Center,
    Header,
    Container,
    Group,
    Burger,
    UnstyledButton,
    Avatar,
    rem,
    Text,
    Space,
    Autocomplete,
    Divider,
    ScrollArea,
    Drawer,
  Anchor,
  Image
} from '@mantine/core';
// import { IconSearch } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import {
    IconChevronDown,
    IconSettings,
    IconLogout,
} from '@tabler/icons-react';
import { submitLogout } from "~/utils/login";
import { CurrentUser } from "~/contexts/CurrentUserContext";
import {useLocation, useNavigate} from "react-router-dom";
// import CreateButton from "@/components/Layout/CreateButton";
import {useQuery, useLazyQuery} from '@apollo/client';
import { GET_ALL_COMPANIES } from '~/graphql/queries/CompanyQueries';
import { GET_ALL_PRESS_RELEASES } from '~/graphql/queries/PressReleaseQueries';
import {GET_USER} from "@/graphql/queries/UserQueries";
import classes from './HeaderAction.module.scss';
import {useTranslation} from "react-i18next";
import logo from '~/images/prbox-logo.svg';
import Notifications from '../Notifications/Notifications';

const HEADER_HEIGHT = rem(60);

export default function HeaderAction() {
    const { t } = useTranslation(['translation']);
    const { id } = useParams()
    const navigate = useNavigate();
    const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
    const [opened, { toggle }] = useDisclosure(false);
    const [userMenuOpened, setUserMenuOpened] = useState(false);
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const [searchTerm, setSearchTerm] = useState('')
    const [searchData, setSearchData] = useState([])
    const [searchCompanies, companies] = useLazyQuery(GET_ALL_COMPANIES, { fetchPolicy: 'no-cache'});
    const [searchPressReleases, pressReleases] = useLazyQuery(GET_ALL_PRESS_RELEASES, { fetchPolicy: 'no-cache'});
    const location = useLocation();
    const currentUser = useContext(CurrentUser);
    const userData = useQuery(GET_USER, { variables: { id: currentUser.id } });
    const [userImage, setUserImage] = useState(null)

    useEffect(() => {
        if(companies.data && companies.data.allCompanies) {
            let results = companies.data.allCompanies.map((c) => ({ value: c.name, label: c.name, id: c.id, group: 'Press kit' }))
            let currentPressReleases = searchData.filter((item) => item.group === "Press Releases")
            setSearchData([...currentPressReleases, ...results])
        }
    }, [companies.data])

    useEffect(() => {
        if(pressReleases.data && pressReleases.data.allPressReleases) {
            let results = pressReleases.data.allPressReleases.nodes.map((c) => ({ value: c.subject, label: c.subject, id: c.id, group: 'Press Releases' }))
            let currentCompanies = searchData.filter((item) => item.group === "Press kit")
            setSearchData([...currentCompanies, ...results])
        }
    }, [pressReleases.data])

    useEffect(() => {
        searchCompanies({ variables: {search: searchTerm, count: 3 } })
        searchPressReleases({ variables: {search: searchTerm, count: 3 } })
    }, [searchTerm])

    useEffect(() => {
        if(userData?.data?.user) {
            setUserImage(userData.data.user.avatarUrl)
        }
    }, [userData])

    const submit = (item) => {
        if(item.group === "Press Releases") {
            window.location.assign(`/press_releases/${item.id}`)
        } else {
            window.location.assign(`/companies/${item.id}/about`)
        }
    }

    const getLinks = () => {
        if (currentUser.user_type === 'standard') {
            return [
                { label: t('layout.companies_label'), link: '/companies' },
                { label: t('layout.press_releases_label'), link: '/press_releases' },
                { label: t('layout.journalists_label'), link: '/journalists' },
                { label: t('layout.media_label'), link: "/media_list" },
                { label: t('layout.tags_label'), link: '/tags' },
            ];
        } else {
            return [
                { label: t('layout.press_kits_label'), link: '/presskits' },
                { label: t('layout.press_releases_label'), link: '/press_releases' }
            ];
        }
    }
    const user = { name: currentUser.name };
    const getCurrentPath = () => {
        if (location.pathname === '/') {
            if (currentUser.user_type === 'standard') {
                return '/companies'
            }
            return '/presskits'
        }
        return location.pathname
    }
    const companyItems = () => {
        if (!location.pathname.startsWith('/companies/')) return
        const links = [
            { link: "/about", label: t('layout.navbar.about') },
            { link: "/team", label: t('layout.navbar.team') },
            { link: "/press_releases", label: t('layout.navbar.press_releases') },
            { link: "/images", label: t('layout.navbar.images') },
            { link: "/videos", label: t('layout.navbar.videos') },
            { link: "/documents", label: t('layout.navbar.documents') },
            { link: "/services", label: t('layout.navbar.services') },
            { link: "/products", label: t('layout.navbar.products') },
            { link: "/fundings", label: t('layout.navbar.fundings') },
        ]

        return (
            <>
                <Divider my="sm" />
                <Text p="sm" weight="700">This page</Text>
                {links.map(link => {
                    const companyLink = `/companies/${id}${link.link}`
                    return (
                        <span key={link.link}>
                            <a
                                key={companyLink}
                                href={companyLink}
                                className={getCurrentPath().startsWith(companyLink) ? classes.activelink : classes.link}
                                onClick={(event) => {
                                    event.preventDefault();
                                    window.location.assign(companyLink);
                                }}
                            >
                                {link.label}
                            </a>

                            <Divider
                                my="xl"
                                size={7}
                                className={getCurrentPath().startsWith(companyLink) ? classes.linkLine : classes.linkLineHidden}
                            />
                        </span>
                    )
                })}
            </>
        )
    }
    const accountItems = () => {
        if (location.pathname.startsWith('/account') || location.pathname.startsWith('/teammates')) {
            const links = [
                { link: "/account", label: t('accounts.sidebar.about') },
                { link: "/teammates", label: t('accounts.sidebar.teammates') },
            ];
            return (
                <>
                    <Divider my="sm" />
                    <Text p="sm" weight="700">This page</Text>
                    {links.map(link => {
                        return (
                            <span key={link.link}>
                                <a
                                    key={link.link}
                                    href={link.link}
                                    className={getCurrentPath().startsWith(link.link) ? classes.activelink : classes.link}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        window.location.assign(link.link);
                                    }}
                                >
                                    {link.label}
                                </a>

                                <Divider
                                    my="xl"
                                    size={7}
                                    className={getCurrentPath().startsWith(link.link) ? classes.linkLine : classes.linkLineHidden}
                                />
                            </span>
                        )
                    })}
                </>
            )
        }

    }
    const items = getLinks().map((link, index) => {
        const menuItems = link.links?.map((item, i) => (
          <Menu.Item key={item.link + `_${index}_${i}`}>{item.label}</Menu.Item>
        ));

        if (menuItems) {
            return (
              <Menu key={link.label} trigger="hover" transitionProps={{ exitDuration: 0 }} withinPortal>
                  <Menu.Target>
                      <a
                        href={link.link}
                        className={classes.link}
                        onClick={(event) => event.preventDefault()}
                      >
                          <Center>
                              <span
                                className={classes.linkLabel}
                              >{link.label}</span>
                              <IconChevronDown size={rem(12)} stroke={1.5} />
                          </Center>
                      </a>
                  </Menu.Target>
                  <Menu.Dropdown>{menuItems}</Menu.Dropdown>
              </Menu>
            );
        }

        return (
          <span key={link.link}>
              <a
                key={link.label}
                href={link.link}
                className={getCurrentPath().startsWith(link.link) ? classes.activelink : classes.link}
                // className={cx(classes.link, { [classes.linkActive]: getCurrentPath().startsWith(link.link) })}
                onClick={(event) => {
                    event.preventDefault();
                    window.location.assign(link.link);
                }}
              >
                  {link.label}
              </a>

              <Divider
                my="xl"
                size={7}
                className={getCurrentPath().startsWith(link.link) ? classes.linkLine : classes.linkLineHidden}
              />
          </span>
        );
    });
    const handleLogout = (event) => {
        event.preventDefault();
        submitLogout();
    };

    return (
      <>
          <Header height={HEADER_HEIGHT} mb={120} className={classes.mantineContainerRoot}>
              <Container
                className={classes.inner}
                size="xl"
                >
                  <Group spacing={5}>
                      <Burger
                        opened={drawerOpened}
                        onClick={toggleDrawer}
                        className={classes.burger}
                        size="sm" />
                      <UnstyledButton onClick={ () => window.location.assign('/') }>
                          <Image src={logo} alt="PRBOX logo" />
                      </UnstyledButton>
                      <Space w="xl" />
                  </Group>
                  <Group className={classes.linksMenu}>
                      {items}
                  </Group>
                  <Group className={classes.links}>
                      {/*{ currentUser.user_type === 'standard' &&*/}
                      {/*  <>*/}
                      {/*      <CreateButton />*/}
                      {/*      <Autocomplete*/}
                      {/*        icon={<IconSearch />}*/}
                      {/*        placeholder="Search"*/}
                      {/*        onChange={setSearchTerm}*/}
                      {/*        data={searchData}*/}
                      {/*        value={searchTerm}*/}
                      {/*        onItemSubmit={submit}*/}
                      {/*      />*/}
                      {/*  </>}*/}
                      <Notifications />
                      <Menu
                        position="bottom-end"
                        transitionProps={{ transition: 'pop-top-right' }}
                        onClose={() => setUserMenuOpened(false)}
                        onOpen={() => setUserMenuOpened(true)}
                        withinPortal
                      >
                          <Menu.Target>
                              <UnstyledButton
                                // className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                                className={classes.user}
                              >
                                  <Group spacing={7}>
                                      <Text weight={500} size="sm" sx={{ lineHeight: 1 }} >
                                          {user.name}
                                      </Text>
                                      <IconChevronDown size={rem(12)} stroke={1.5} />
                                      <Avatar src={userImage} alt={user.name} radius="xl" size={30} />
                                  </Group>
                              </UnstyledButton>
                          </Menu.Target>
                          <Menu.Dropdown>
                              <Menu.Item
                                onClick={() => navigate(`/account`)}
                                icon={<IconSettings size="0.9rem" stroke={1.5} />}
                              >
                                  {t("layout.account_details_label")}
                              </Menu.Item>
                              <Menu.Item icon={<IconLogout size="0.9rem" stroke={1.5} />} onClick={handleLogout}>Logout</Menu.Item>
                          </Menu.Dropdown>
                      </Menu>
                  </Group>
              </Container>
          </Header>

          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            size="100%"
            padding="md"
            title="Navigation"
            zIndex={1000000}
          >
              <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
                  {accountItems()}
                  {companyItems()}
                  <Divider my="sm" />
                  <Text p="sm" weight="700">Menu</Text>
                  {items}
                  <Divider my="sm" />

                  <Anchor
                    className={classes.link}
                    // className={cx(classes.link, { [classes.linkActive]: getCurrentPath().startsWith(link.link) })}
                    onClick={() => navigate(`/account`)}
                    underline={false}
                  >
                      {t("layout.account_details_label")}
                  </Anchor>
                  <Anchor
                    className={classes.link}
                    // className={cx(classes.link, { [classes.linkActive]: getCurrentPath().startsWith(link.link) })}
                    onClick={handleLogout}
                    underline={false}


                  >
                      {t("logout_label")}
                  </Anchor>

              </ScrollArea>
          </Drawer>
      </>
    );
}

