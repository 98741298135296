import { gql } from "@apollo/client";

export const GET_TEAM_MEMBERS = gql`
query teamMembers($companyId: ID!) {
  teamMembers(companyId: $companyId) {
    __typename
    id
    companyId
    name
    role
    description
    avatarUrl
    linkedin
    x
    website
  }
}
`;

export const GET_TEAM_MEMBERS_BY_COMPANY_UUID = gql`
query teamMembersByCompanyUuid($companyUuid: String!) {
  teamMembersByCompanyUuid(companyUuid: $companyUuid) {
    __typename
    id
    companyId
    name
    role
    description
    avatarUrl
    linkedin
    x
    website
  }
}
`;

export const GET_TEAM_MEMBER = gql`
query teamMember($id: ID!) {
  teamMember(id: $id) {
    __typename
    id
    companyId
    name
    role
    description
    avatarUrl
    linkedin
    x
    website
  }
}
`;
