import React, { useState } from "react";
import { Button, Card, Group, Tooltip} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import useStyles from "../../AboutCompany.styles";
import SubmitButtons from "@/components/Companies/AboutCompany/InputCards/shared/SubmitButtons";
import {useTranslation} from "react-i18next";

export default function CompanyFormationDate({ date, updateCompany }) {
  const { t } = useTranslation(['translation']);
  const { classes, cx } = useStyles();
  const [companyFormation, setCompanyFormation] = useState(
    date ? new Date(date) : new Date('January 01, 2000')
  );
  const [editMode, setEditMode] = useState(false);
  const saveCompanyChanges = () => {
    updateCompany({ formationDate: companyFormation });
    setEditMode(false);
  };
  const cancelChanges = () => {
    setCompanyFormation(new Date(date) || null);
    setEditMode(false);
  };

  return (
    <>
      <Card radius={8} my="md" className={classes.inputCard}>
        <Group position="apart" align="flex-start" spacing={0}>
          <DateInput
            style={{ width: "90%" }}
            value={companyFormation}
            onChange={setCompanyFormation}
            label={t('company.formation_date_label')}
            placeholder={t('company.formation_date_placeholder')}
            maxDate={new Date()}
            disabled={!editMode}
            classNames={{
              label: classes.label,
              input: cx(classes.input, classes.calendarInput),
            }}
          />
          {!editMode &&
            <Button
              variant="subtle"
              radius="xl"
              size="xs"
              compact
              onClick={() => setEditMode(true)}
            >
              {t('edit_button')}
            </Button>}
        </Group>

        { editMode && <SubmitButtons
          cancelChanges={cancelChanges}
          saveCompanyChanges={saveCompanyChanges}
        /> }
      </Card>
    </>
  );
}
