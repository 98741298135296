import React from 'react';
import { createStyles, Container, Text, Button, Group, rem } from '@mantine/core';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : 'none',
  },

  inner: {
    position: 'relative',
    paddingTop: rem(50),
    paddingBottom: rem(120),

    [theme.fn.smallerThan('sm')]: {
      paddingBottom: rem(80),
      paddingTop: rem(80),
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(62),
    fontWeight: 900,
    lineHeight: 1.1,
    margin: 0,
    padding: 0,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(42),
      lineHeight: 1.2,
    },
  },

  description: {
    marginTop: theme.spacing.xl,
    fontSize: rem(24),

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(18),
    },
  },

  controls: {
    marginTop: `calc(${theme.spacing.xl} * 2)`,

    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xl,
    },
  },

  control: {
    height: rem(54),
    paddingLeft: rem(38),
    paddingRight: rem(38),

    [theme.fn.smallerThan('sm')]: {
      height: rem(54),
      paddingLeft: rem(18),
      paddingRight: rem(18),
      flex: 1,
    },
  },
}));

export default function Dashboard() {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <Container size={700} className={classes.inner}>
        <h1 className={classes.title}>
          PRBOX{' '}
          <Text component="span" variant="gradient" gradient={{ from: 'blue', to: 'cyan' }} inherit>
            ALPHA
          </Text>{' '}
        </h1>

        <Text className={classes.description} color="dimmed">
          {t('dashboard.version_info')}

          {t('dashboard.features')}
          <ul>
            <li>{t('dashboard.all_press_kits')}</li>
            <li>{t('dashboard.upload')}</li>
            <li>{t('dashboard.releases')}</li>
            <li>{t('dashboard.products_and_services')}</li>
            <li>{t('dashboard.team')}</li>
            <li>{t('dashboard.contact')}</li>
            <li>{t('dashboard.social')}</li>
            <li>{t('dashboard.tags')}</li>
            <li>{t('dashboard.tags_for')}</li>
            <li>{t('dashboard.media')}</li>
          </ul>

          {t('dashboard.contact_message')}<a href="mailto:support@ig.software"> support@ig.software</a>
        </Text>

        <Group className={classes.controls}>
          <Button
            size="xl"
            className={classes.control}
            variant="gradient"
            gradient={{ from: 'blue', to: 'cyan' }}
            onClick={() => navigate('/companies')}
          >
            {t('get_started_button')}
          </Button>

        </Group>
      </Container>
    </div>
  );
}