import React, { useState } from "react";
import {Button, Card, Group, Textarea, Tooltip} from "@mantine/core";
import useStyles from "../../AboutCompany.styles";
import SubmitButtons from "@/components/Companies/AboutCompany/InputCards/shared/SubmitButtons";
import {useTranslation} from "react-i18next";

export default function CompanyTagline({ tagline, updateCompany }) {
  const { t } = useTranslation(['translation']);
  const { classes } = useStyles();
  const [companyTagline, setCompanyTagline] = useState(tagline || "");
  const [editMode, setEditMode] = useState(false);
  const saveCompanyChanges = () => {
    updateCompany({ tagline: companyTagline });
    setEditMode(false);
  };
  const cancelChanges = () => {
    setCompanyTagline(tagline || "");
    setEditMode(false);
  };
  return (
    <>
      <Card radius={8} my="md" className={classes.inputCard}>
        <Group position="apart" align="flex-start" spacing={0}>
          <Textarea
            label={t('company.tagline_label')}
            placeholder={t('company.tagline_placeholder')}
            value={companyTagline}
            onChange={(event) => setCompanyTagline(event.currentTarget.value)}
            disabled={!editMode}
            w={"90%"}
            classNames={{
              label: classes.label,
              input: classes.input,
            }}
            minRows={1}
            autosize
          />
          {!editMode &&
            <Button
              variant="subtle"
              radius="xl"
              size="xs"
              compact
              onClick={() => setEditMode(true)}
            >
              {t('edit_button')}
            </Button>}
        </Group>

        { editMode && <SubmitButtons
          cancelChanges={cancelChanges}
          saveCompanyChanges={saveCompanyChanges}
        /> }
      </Card>
    </>
  );
}
