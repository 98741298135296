import React from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_JOURNALIST } from '~/graphql/mutations/JournalistMutations'
import { Button } from '@mantine/core'
import {useTranslation} from "react-i18next";

export default function UpdateJournalist({ submit, id, name, email, description, tagIds, mediaId, setJournalists }) {
  const { t } = useTranslation(['translation']);
  const [updateJournalist] = useMutation(UPDATE_JOURNALIST)

  function handleUpdateJournalist() {
    if (name.length > 0 && email.length > 0 && (/^\S+@\S+$/.test(email))) {
      updateJournalist({
        variables: {
          journalist: {
            id: id,
            name: name,
            email: email,
            description: description,
            tagIds: tagIds,
            mediaId: mediaId,
          },
        },
        update(cache, { data: { updateJournalist } }) {
          setJournalists((journalists) => {
            const index = journalists.findIndex((journalist) => journalist.id === updateJournalist.id)
            journalists[index] = updateJournalist
            return [...journalists]
          })
        }
      })
      submit()
    } else {
      submit()
    }

  }

  return <Button onClick={handleUpdateJournalist}>{t('ok_button')}</Button>
}
