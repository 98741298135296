import React from "react";
import { Modal, Space, Group, Button, Anchor, Text } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { DELETE_ACCOUNT_INVITATION } from "~/graphql/mutations/AccountMutations";
import {useTranslation} from "react-i18next";
import {GET_CURRENT_ACCOUNT} from "@/graphql/queries/AccountQueries";

export default function DeleteTeammateModal({ opened, close, email }) {
  const { t } = useTranslation(['translation']);

  const [_deleteTeammate] = useMutation(DELETE_ACCOUNT_INVITATION);
  const deleteTeammate = () => {
    _deleteTeammate({
      variables: {
        email: email,
      },
      refetchQueries: [
        { query: GET_CURRENT_ACCOUNT },
      ],
    });
    close();
  };
  return (
    <>
       <Modal opened={opened} onClose={close} centered>
        <Text align="center">
          {t('accounts.teammates.delete_message')}
        </Text>
        <Space h="xl" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button onClick={deleteTeammate} color="red">
            {t('ok_button')}
          </Button>
        </Group>
      </Modal>
    </>
  );
}
