import React, {useState} from 'react';
import {Modal, Button, Group, Space, Anchor, Input, Textarea, LoadingOverlay} from '@mantine/core';
import { useMutation } from '@apollo/client';
import { CREATE_TAG } from '~/graphql/mutations/TagMutations';
import { GET_TAGS } from '~/graphql/queries/TagQueries';
import {useTranslation} from "react-i18next";

export default function NewTagModal({ opened, close }) {
  const { t } = useTranslation(['translation']);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [createTag, { loading, data }] = useMutation(CREATE_TAG);
  const newTagParams = { tag: { name: name, description: description }}

  const createNewTag = () => {
    createTag(
      {
        variables: newTagParams,
        refetchQueries: [
          { query: GET_TAGS }
        ],
      })
    close()
    setName("")
    setDescription("")
  }

  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        <Input.Wrapper label={t('tags.name_label')} required >
          <Input
            placeholder={t('tags.name_placeholder')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Input.Wrapper>
        <Space h="lg" />
        <Textarea
          placeholder={t('tags.description_placeholder')}
          label={t('tags.description_label')}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Space h="lg" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>{t('cancel_button')}</Anchor>
          <Button onClick={createNewTag}>{t('ok_button')}</Button>
        </Group>
        <LoadingOverlay visible={loading} overlayBlur={2} />
      </Modal>
    </>
  );
}