import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
    deleteButton: {
        color: 'red'
    },
    actionsContainer: {
        paddingTop: theme.spacing.md,
        display: 'flex',
        justifyContent: 'space-between'
    },
    rightContainer: {
        display: "flex",
        gap: theme.spacing.sm
    }
}));

export default useStyles;
