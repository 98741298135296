import React from 'react';
import {SimpleGrid, Container, Input, Space, Button, Grid, LoadingOverlay} from '@mantine/core';
import ProductCard from './ProductCard';
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS } from '~/graphql/queries/ProductQueries';
import {useParams} from "react-router-dom";
import CreateProduct  from "@/components/Companies/Products/CreateProduct";
import {useTranslation} from "react-i18next";

export default function Products() {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_PRODUCTS, { variables: { companyId: id } });

  if (loading) return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (error) return console.log(id, error);

  return (
    <>
      <Grid justify="space-between" align="center">
        <Grid.Col span="content"><h2>{t('company.product.header')}</h2></Grid.Col>
        <Grid.Col span="content">
          <CreateProduct />
        </Grid.Col>
      </Grid>
      <Space h="md" />
      <SimpleGrid cols={4}>
        {data.products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </SimpleGrid>
    </>
  );
}