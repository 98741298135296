import { gql } from "@apollo/client";

export const GET_TAGS = gql`
  query tags {
    tags {
      id
      name
      description
    }
  }
`;

export const GET_TAG = gql`
  query tag($id: ID!) {
    tag(id: $id) {
      id
      name
      description
    }
  }
`;
