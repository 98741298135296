import React from 'react';
import { Button, Group } from '@mantine/core';
import { GithubIcon, DiscordIcon, TwitterIcon } from '@mantine/ds';
import { GoogleIcon } from './GoogleIcon';
import { FacebookIcon } from './FacebookIcon';
import {useTranslation} from "react-i18next";

export function GoogleButton(props) {
  return <Button w="100%" leftIcon={<GoogleIcon />} variant="default" color="gray" {...props} />;
}

export function FacebookButton(props) {
  return (
    <Button
      leftIcon={<FacebookIcon />}
      sx={(theme) => ({
        backgroundColor: '#4267B2',
        color: '#fff',
        '&:not([data-disabled]):hover': {
          backgroundColor: theme.fn.darken('#4267B2', 0.1),
        },
      })}
      {...props}
    />
  );
}

export function DiscordButton(props) {
  return (
    <Button
      leftIcon={<DiscordIcon size="1rem" />}
      sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? '#5865F2' : '#5865F2',
        '&:not([data-disabled]):hover': {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.fn.lighten('#5865F2', 0.05)
              : theme.fn.darken('#5865F2', 0.05),
        },
      })}
      {...props}
    />
  );
}

// Twitter button as anchor
export function TwitterButton(props) {
  return (
    <Button
      component="a"
      leftIcon={<TwitterIcon size="1rem" color="#00ACEE" />}
      variant="default"
      {...props}
    />
  );
}

export function GithubButton(props) {
  return (
    <Button
      {...props}
      leftIcon={<GithubIcon size="1rem" />}
      sx={(theme) => ({
        backgroundColor: theme.colors.dark[theme.colorScheme === 'dark' ? 9 : 6],
        color: '#fff',
        '&:hover': {
          backgroundColor: theme.colors.dark[theme.colorScheme === 'dark' ? 9 : 6],
        },
      })}
    />
  );
}

export function GoogleOmniauthForm({text}) {
  return (
    <form data-turbo="false" action="/users/auth/google_oauth2" acceptCharset="UTF-8" method="post">
      <input
        type="hidden"
        name="authenticity_token"
        value={document.getElementsByTagName("meta")["csrf-token"].content}
        autoComplete="off"
      />
      <GoogleButton type="submit">{text}</GoogleButton>
    </form>
  )
}

export function SocialButtons() {
  const { t } = useTranslation(['translation']);

  return (
    <Group position="center" sx={{ padding: 15 }}>
      <GoogleButton>{t("auth.social_buttons.google")}</GoogleButton>
      <TwitterButton href="https://twitter.com/mantinedev" target="_blank">
        {t("auth.social_buttons.twitter")}
      </TwitterButton>
      <FacebookButton>{t("auth.social_buttons.facebook")}</FacebookButton>
      <GithubButton>{t("auth.social_buttons.github")}</GithubButton>
      <DiscordButton>{t("auth.social_buttons.discord")}</DiscordButton>
    </Group>
  );
}