import React, {useEffect, useState} from 'react';
import {Modal, Group, Space, Anchor, Input, LoadingOverlay, MultiSelect, Text, Textarea, Tabs, Flex, rem, Button} from '@mantine/core';
import {useQuery, useMutation} from "@apollo/client";
import useStyles from "../Companies/AboutCompany/ImageUploader/ImageUploader.styles";
import {GET_TAGS} from "@/graphql/queries/TagQueries";
import CreateJournalist from './CreateJournalist';
import MediaFieldAutocomplete from "./MediaFieldAutocomplete";
import {useTranslation} from "react-i18next";
import classes from "./Journalists.module.scss";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { IconUpload, IconX, IconFileUpload } from "@tabler/icons-react";
import {BULK_CREATE_JOURNALISTS} from "@/graphql/mutations/JournalistMutations";
import { GET_JOURNALISTS } from '~/graphql/queries/JournalistQueries';

export default function NewJournalistModal({ opened, close, loadDefault }) {
  const { t } = useTranslation(['translation']);
  const { theme } = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [tagIds, setTagIds] = useState([]);
  const [mediaId, setMediaId] = useState(null);
  const { loading, error, data } = useQuery(GET_TAGS);
  const [tab, setTab] = useState("new")
  const [bulkCreateJournalists, bulkCreateResult] = useMutation(BULK_CREATE_JOURNALISTS);
  const [files, setFiles] = useState([])
  const [modalState, setModalState] = useState('ready')
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)

  if (loading) return <LoadingOverlay visible={true} overlayBlur={2} />
  const availableTags = data.tags.map((tag) => {
    return { label: tag.name, value: tag.id };
  });

  function submit() {
    if(name.length === 0) {
      setNameError(true)
    } else if (email.length === 0 || !(/^\S+@\S+$/.test(email))) {
      setEmailError(true)
    } else {
      close()
      setName("")
      setEmail("")
      setDescription("")
      setTagIds([])
      setMediaId(null)
    }
  }

  const saveCompanyChanges = () => {
    bulkCreateJournalists({
      variables: {
        file: files[0]
      },
      refetchQueries: [
        { query: GET_JOURNALISTS }
      ],
      onError: (error) => {
        console.log(error)
        setModalState('error')
      }
    });
  }

  useEffect(() => {
    if (bulkCreateResult.data?.bulkCreateJournalists?.success) {
      setFiles([])
      setModalState('success')
    }
    if (bulkCreateResult.data?.bulkCreateJournalists?.error) {
      setModalState('error')
    }
  }, [bulkCreateResult.data])

  const previews = files.map((file, index) => {
    return <Text key={index} size="sm" color="dimmed" mb="md">{file.name}</Text>
  });

  const renderUploadText = () => {
    if (modalState === 'error') {
      return (
        <Flex direction="column" align="center">
          <Text size="md">{t('journalists.uploader.error')}</Text>
          <Text size="md" td="underline" weight={700}>{t('journalists.uploader.try_again')}</Text>
        </Flex>
      )
    } else if (modalState === 'success') {
      return (
        <Flex direction="column" align="center">
          <Text size="md" align="center">{t('journalists.uploader.success')}</Text>
        </Flex>
      )
    } else {
      return(
        <Flex direction="column" align="center">
          <Text size="md">{t('journalists.uploader.instructions')}</Text>
          <Text size="md" td="underline" weight={700}>{t('journalists.uploader.choose_file')}</Text>
        </Flex> 
      )
    }
  }
  const bgColor = () => {
    if (modalState === 'error') {
      return "red.1"
    } else if (modalState === 'success') {
      return "green.1"
    } else {
      return "gray.1"
    }
  }
  const borderColor = () => {
    if (modalState === 'error') {
      return "red"
    } else if (modalState === 'success') {
      return "green"
    } else {
      return "gray"
    }
  }

  if (bulkCreateResult.loading) return <LoadingOverlay visible={true} overlayBlur={2} />

  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        <Tabs
          defaultValue={tab || "new"}
          onTabChange={(value) => setTab(value)}
        >
          <Flex justify="center">
            <Tabs.List className={classes.tabsList} bg="gray.2" radius="xl">
              <Tabs.Tab className={classes.tab} value="new">
                <Text color={tab === 'new' ? 'white' : 'gray.6'}>{t("journalists.tabs.new")}</Text>
              </Tabs.Tab>
              <Tabs.Tab className={classes.tab} value="mass">
                <Text color={tab === 'mass' ? 'white' : 'gray.6'}>{t("journalists.tabs.mass")}</Text>
              </Tabs.Tab>
            </Tabs.List>
          </Flex>
          <Tabs.Panel value="new">
            <Input.Wrapper pt="md" label={t('journalists.name_label')} required >
              <Input
                placeholder={t('journalists.name_placeholder')}
                value={name}
                error={nameError}
                onChange={(e) => {
                  setName(e.target.value)
                  if (name.length > 0) { setNameError(false) }
                }}
              />
            </Input.Wrapper>
            <Space h="lg" />
            <Input.Wrapper label={t('journalists.email_label')} required >
              <Input
                placeholder={t('journalists.email_placeholder')}
                value={email}
                error={emailError}
                onChange={(e) => {
                  setEmail(e.target.value)
                  if (email.length > 0) { setEmailError(false) }
                }}
              />
            </Input.Wrapper>
            <Space h="lg" />
            <Input.Wrapper label={t('journalists.description_label')} >
              <Textarea
                placeholder={t('journalists.description_placeholder')}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Input.Wrapper>
            <Space h="lg" />
            <Input.Wrapper label={t('journalists.tags_label')}>
              <MultiSelect
                onChange={(value) => setTagIds(value)}
                data={availableTags}
                placeholder={t('journalists.tags_placeholder')}
                searchable
              />
            </Input.Wrapper>
            <Space h="lg" />
            <MediaFieldAutocomplete setMediaId={setMediaId} />
            <Space h="lg" />
            <Group position="right">
              <Anchor onClick={close} underline={false}>Cancel</Anchor>
              <CreateJournalist
                submit={submit}
                name={name}
                email={email}
                description={description}
                tagIds={tagIds}
                mediaId={mediaId}
                loadDefault={loadDefault}
              />
            </Group>
            <LoadingOverlay visible={loading} overlayBlur={2} />
          </Tabs.Panel>
          <Tabs.Panel value="mass" pt="lg">
            <Dropzone
              maxFiles={1}
              onDrop={(files) => {
                setFiles(files)
                setModalState('ready')
              }}
              onReject={(files) => setFiles([])}
              maxSize={3 * 1024 ** 2}
              accept={[MIME_TYPES.csv]}
              bg={bgColor()}
              style={{ borderColor: borderColor() }}
            >
              <Group
                position="center"
                spacing="xl"
                style={{ minHeight: rem(120), pointerEvents: "none" }}
              >
                <Dropzone.Accept>
                  <IconUpload
                    size="3.2rem"
                    stroke={1.5}
                    color={theme.colors.red[4]}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX
                    size="3.2rem"
                    stroke={1.5}
                    color={theme.colors.red[4]}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconFileUpload size="3.2rem" stroke={1.5} color={borderColor()} />
                </Dropzone.Idle>
              </Group>
              <Group position="center">{previews}</Group>
              {renderUploadText()}
            </Dropzone>
            <Flex justify="space-between" w="100%">
              <Text size="sm" color="dimmed" mt={7}>{t('journalists.uploader.format')}</Text>
              <Text size="sm" color="dimmed" mt={7}>{t('journalists.uploader.size')}</Text>
            </Flex>
            <Group position="right" mt="xl">
              <Anchor onClick={close} underline={false}>{t('close_button')}</Anchor>
              <Button radius="xl" disabled={modalState !== "ready"} onClick={() => saveCompanyChanges()}>{t('send_button')}</Button>
            </Group>
          </Tabs.Panel>
        </Tabs>
      </Modal>
    </>
  );
}
