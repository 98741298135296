import { gql } from "@apollo/client";

export const UPDATE_ATTACHMENT = gql`
mutation UpdateAttachment($attachment: AttachmentInput!) {
  updateAttachment(input: {attachment: $attachment}) {
    id
    name
  }
}
`;

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($id: ID!) {
    deleteAttachment(input: {id: $id}) {
      success
    }
  }
`;

export const BULK_CREATE_ATTACHMENTS = gql`
  mutation BulkCreateAttachment($files: [Upload!], $companyId: ID, $folderId: ID, $attachmentType: String) {
    bulkCreateAttachments(input: {files: $files, companyId: $companyId, folderId: $folderId, attachmentType: $attachmentType }) {
      errors
    }
  }
`;