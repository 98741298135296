import React from "react";
import Dashboard from "~/components/Dashboard/Dashboard";
import Companies from "~/components/Companies/JournalistView/Companies";
import PressReleases from "~/components/PressReleases/JournalistView/PressReleases"
import Account from "~/components/Account/Account";
import {AppShell, useMantineTheme, Container} from "@mantine/core";
import NavbarCompanyVew from "~/components/Layout/NavbarCompanyVew";
import HeaderAction from "~/components/Layout/HeaderAction";
import PressKit from "@/components/PressKits/PressKit";
import PressReleasePage from "@/components/PressKits/PressReleases/PressReleasePage/PressReleasePage";

export default function AgencyView({ view, sidebar }) {
  const theme = useMantineTheme();

  const renderPage = () => {
    switch(view) {
      case "Dashboard":
        return <Companies />;
      case "Account":
        return <Account />;
      case "PressKits":
        return <Companies />;
      case "PressReleases":
        return <PressReleases />;
      case "PressKit":
        return <PressKit />;
      case "PressReleasePage":
        return <PressReleasePage />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={sidebar === "companyView" ? <NavbarCompanyVew /> : null}
      header={<HeaderAction />}
    >
      <Container size="xl">
        {renderPage()}
      </Container>
    </AppShell>
  );
}