import React from 'react';
import {useParams} from "react-router-dom";
import {Avatar, Text, Paper, Space, Menu, ActionIcon, rem, Grid, Group} from '@mantine/core';
import {IconDotsVertical, IconEye, IconTrash, IconBrandTwitter, IconBrandLinkedin, IconAt, IconPlanet} from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import DeleteMemberModal from "./DeleteMemberModal";
import {useTranslation} from "react-i18next";

export default function TeamCard({ member }) {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const [isDeleteModalOpened, deleteModalHandlers] = useDisclosure(false);

  const handleNavigate = () => window.location.assign(`/companies/${id}/member/${member.id}/team`)

  return (
    <Paper
      radius="md"
      p="lg"
      sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
      })}
    >
      <Grid justify="flex-end">
        <Menu withinPortal position="bottom-end" shadow="sm">
          <Menu.Target>
            <ActionIcon>
              <IconDotsVertical size="1rem" />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              icon={<IconEye size={rem(14)} />}
              onClick={handleNavigate}
            >
              {t('edit_button')}
            </Menu.Item>
            <Menu.Item
              icon={<IconTrash size={rem(14)} />}
              color="red"
              onClick={deleteModalHandlers.open}
            >
              {t('delete_button')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Grid>

      <div style={{ cursor: "pointer" }} onClick={handleNavigate}>
        <Avatar src={member.avatarUrl} size={120} radius={120} mx="auto" />
        <Text ta="center" fz="lg" weight={500} mt="md">
          {member.name}
        </Text>
        <Text ta="center" c="dimmed" weight={500} fz="sm">
          {member.role}
        </Text>
        <Space h="md" />
        <Text ta="center" c="dimmed" fz="sm" lineClamp={5}>
          {member.description}
        </Text>
        <Group position="center" mt="md">
          <ActionIcon component="a" href={member.linkedin} disabled={!member.linkedin}>
            <IconBrandLinkedin stroke={1.5} size="1rem" />
          </ActionIcon>
          <ActionIcon component="a" href={member.x} disabled={!member.x}>
            <IconBrandTwitter stroke={1.5} size="1rem" />
          </ActionIcon>
          <ActionIcon component="a" href={member.website} disabled={!member.website}>
            <IconPlanet stroke={1.5} size="1rem" />
          </ActionIcon>
        </Group>
      </div>
      <DeleteMemberModal
        opened={isDeleteModalOpened}
        close={deleteModalHandlers.close}
        memberId={member.id}
      />
    </Paper>
  );
}
