import React, {useState, useEffect} from 'react';
import { SimpleGrid, Space, Grid, Group, Loader, Divider } from '@mantine/core';
import CompanyCard from './CompanyCard';
import CreateCompanyButton from './CreateCompanyButton'
import { useLazyQuery } from '@apollo/client';
import { GET_COMPANIES } from '~/graphql/queries/CompanyQueries';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from "@/components/Shared/LoadingOverlay";
import Search from "@/components/Companies/Search/Search";
import {useTranslation} from "react-i18next";
import classes from "./Companies.module.scss";

export default function Companies() {
  const { t } = useTranslation(['translation']);
  const [search, setSearch] = useState("")
  // const [filter, setFilter] = useState('all')
  const [companies, setCompanies] = useState([])
  const [selectedTagIds, setSelectedTagIds] = useState([])
  const [getCompanies, { loading, data, error }] = useLazyQuery(GET_COMPANIES, { fetchPolicy: 'no-cache'});

  useEffect(() => {
    loadMore()
  }, [])

  useEffect(() => {
    if (data?.companies?.nodes) {
      setCompanies((current) => [...current, ...data.companies.nodes])
    }
  }, [data])

  const searchWithoutTags = search.replace(/#(\w+)?/g, '');

  function loadMore() {
    getCompanies({ variables: {after: data?.companies?.pageInfo?.endCursor, search: searchWithoutTags, selectedTagIds: selectedTagIds} })
  }

  function startSearch({tagIds, value}) {
    setCompanies([])
    getCompanies({ variables: {after: "", search: value, selectedTagIds: tagIds} })
  }

  if (loading && !companies.length) return <Loading />;
  if (error) return <p>Error :</p>;

  return (
    <>
      <Grid align="center">
        <Grid.Col lg={7} md={7} sm={12}><h2>{t('company.header')}</h2></Grid.Col>
        <Grid.Col lg={5} md={5} sm={12}>
          <Search
            startSearch={startSearch}
            search={search}
            setSearch={setSearch}
            setSelectedTagIds={setSelectedTagIds}
            selectedTagIds={selectedTagIds}
          />
        </Grid.Col>
      </Grid>

      <Divider my="sm" className={classes.divider} />

      <Group position="right">
        <CreateCompanyButton />
      </Group>
        <InfiniteScroll
          dataLength={companies.length}
          next={loadMore}
          hasMore={ data?.companies?.pageInfo?.hasNextPage }
          loader={ <Group justify="center" grow><Loader /></Group>}
          endMessage={<p>{t('no_more_data_text')}</p> }
        >
          <Grid align="center">
            {companies.map((company) => (
              <Grid.Col lg={3} md={4} sm={6} xs={12} key={company.id}>
                <CompanyCard company={company} setCompanies={setCompanies} />
              </Grid.Col>
            ))}
          </Grid>
        </InfiniteScroll>
    </>
  );
}