import React, { useState, useRef, useEffect} from "react";
import { Group, ThemeIcon, Space, Anchor } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { List } from '@mantine/core';
import { IconTrash, IconCircleDashed, IconFile } from '@tabler/icons-react';
import { DELETE_BLOB_ENTITY} from "@/graphql/mutations/GeneralMutations";
import { GET_PRESS_RELEASE } from "@/graphql/queries/PressReleaseQueries";
import { useMutation } from "@apollo/client";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function FilesInput({pressRelease, debouncedOnChange, setTyping, typing, isPublished}) {
  const { t } = useTranslation(['translation']);
  const { press_release_id } = useParams();
  const [files, setFiles] = useState([]);
  const openRef = useRef(null);
  const [deleteBlobEntity] = useMutation(DELETE_BLOB_ENTITY, {
    refetchQueries: [
      {
        query: GET_PRESS_RELEASE,
        variables: { id: press_release_id }
      }
    ]
  });

  useEffect(() => {
    setFiles(pressRelease.pressReleaseFiles);
  } , [pressRelease]);

  const deleteFile = (file) => {
    setFiles((currentFiles) => currentFiles.filter((f) => f !== file));
    deleteBlobEntity({ variables: { id: file.id } });
  }

  const fileIcon = (file) => {
    if (isPublished) return (
      <ThemeIcon color="green" size={24} radius="xl">
        <IconFile size="1rem" />
      </ThemeIcon>
      )

    if (file.id) {
      return (
        <Anchor onClick={() => deleteFile(file)}>
          <ThemeIcon
            color="red"
            size={24}
            radius="xl"
          >
            <IconTrash size="1rem" />
          </ThemeIcon>
        </Anchor>
      )
    } else {
      return (
        <ThemeIcon color="blue" size={24} radius="xl">
          <IconCircleDashed size="1rem" />
        </ThemeIcon>
      )
    }
  }


  return (
    <>
    { !isPublished && <Dropzone
        openRef={openRef}
        onDrop={(value) => {
          setTyping(true);
          setFiles((currentFiles) => [...currentFiles, ...value]);
          debouncedOnChange({ pressReleaseFiles: value })
        }}
      >
        <Group justify="center">
          {t('press_releases.select_files')}
        </Group>
      </Dropzone> }


      <Space h="xl" />

      <List
        spacing="xs"
        size="sm"
        center
      >
        {(files).map((file, index) => (
          <List.Item
            key={index}
            icon={ fileIcon(file) }
          >
            <Anchor href={file.url} target="_blank" rel="noopener noreferrer">
              {file.filename || file.name }
            </Anchor>
          </List.Item>
        ))}
      </List>
    </>
  );
}