import React, { useState } from "react";
import {Group, Text, rem, Image, Flex, Button, Anchor} from "@mantine/core";
import useStyles from "./ImageUploader.styles";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons-react";
import {useMutation} from "@apollo/client";
import {UPDATE_COMPANY} from "@/graphql/mutations/CompanyMutations";
import {useTranslation} from "react-i18next";

export default function ImageUploader(props) {
  const { t } = useTranslation(['translation']);
  const { classes, theme } = useStyles();
  const [files, setFiles] = useState([]);
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const saveCompanyChanges = () => {
    updateCompany({
      variables: {
        company: {
          id: props.id,
          logo: files[0],
        }
      },
    });
    reset()
  }

  const reset = () => {
    setFiles([])
    props.close()
  }

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        src={imageUrl}
        fit="contain"
        height="40vh"
        imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
      />
    );
  });
  return (
    <>
      <Dropzone
        onDrop={(files) => { setFiles(files); }}
        onReject={(files) => console.log("rejected files", files)}
        maxSize={3 * 1024 ** 2}
        accept={IMAGE_MIME_TYPE}
      >
        {files.length > 0 ? (
          <Group position="center">{previews}</Group>
        ) : (
          <>
            <Group
              position="center"
              spacing="xl"
              style={{ minHeight: rem(120), pointerEvents: "none" }}
            >
              <Dropzone.Accept>
                <IconUpload
                  size="3.2rem"
                  stroke={1.5}
                  color={
                    theme.colors[theme.primaryColor][
                      theme.colorScheme === "dark" ? 4 : 6
                    ]
                  }
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  size="3.2rem"
                  stroke={1.5}
                  color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconPhoto size="3.2rem" stroke={1.5} />
              </Dropzone.Idle>
            </Group>
            <Flex direction="column" align="center">
              <Text size="xl">{t('company.image_uploader.instructions')}</Text>
              <Text size="sm" color="dimmed" mt={7}>
                {t('company.image_uploader.limit')}
              </Text>
            </Flex>
          </>
        )}
      </Dropzone>
      <div className={classes.actionsContainer}>
        <div>
          {files.length > 0 && (
            <Anchor className={classes.deleteButton} radius="xl" size="xs" compact="true" onClick={() => setFiles([])}>
              {t('delete_button')}
            </Anchor>
          )}
        </div>
        <div className={classes.rightContainer}>
          <Anchor radius="xl" size="xs" compact="true" onClick={reset}>
            {t('cancel_button')}
          </Anchor>
          <Button radius="xl" size="xs" compact="true" onClick={() => saveCompanyChanges()}>
            {t('save_button')}
          </Button>
        </div>
      </div>
    </>
  );
}
