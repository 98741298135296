import React, { useState, useEffect } from 'react';
import {
  Grid,
  Group,
  Loader,
} from '@mantine/core';
import {GET_ALL_PUBLIC_PRESS_RELEASES} from "@/graphql/queries/PressReleaseQueries";
import {useLazyQuery, useQuery} from "@apollo/client";
import InfiniteScroll from 'react-infinite-scroll-component';
import {GET_TAGS} from "@/graphql/queries/TagQueries";
import Loading from "@/components/Shared/LoadingOverlay";
import PressReleaseCard from "@/components/PressKits/PressReleases/PressReleaseCard";
import classes from "./PressReleases.module.scss";
import Search from "@/components/Companies/Search/Search";
import {searchWithoutTags} from "@/utils/search";
import {useTranslation} from "react-i18next";

export default function PressReleases() {
  const { t } = useTranslation(['translation']);
  const [search, setSearch] = useState("")
  const [sort, setSort] = useState('desc')
  const [sortBy, setSortBy] = useState('id')
  const [selectedTagIds, setSelectedTagIds] = useState([])

  const tagsQuery = useQuery(GET_TAGS);
  const [pressReleases, setPressReleases] = useState([])
  const [getPressReleases, { loading, data, error }] = useLazyQuery(GET_ALL_PUBLIC_PRESS_RELEASES, { fetchPolicy: 'no-cache'});

  useEffect(() => {
    loadMore()
  }, [])

  useEffect(() => {
    if (data?.allPublicPressReleases?.nodes) {
      setPressReleases((current) => [...current, ...data.allPublicPressReleases.nodes])
    }
  }, [data])

  function loadMore() {
    getPressReleases({ variables: {sortBy: sortBy, sort: sort, after: data?.allPublicPressReleases?.pageInfo?.endCursor, search: searchWithoutTags(search), filter: "published", selectedTagIds: selectedTagIds} })
  }

  function startSearch({tagIds, value}) {
    setPressReleases([])
    getPressReleases({ variables: {sortBy: sortBy, sort: sort, after: "", search: value, selectedTagIds: tagIds }})
  }

  if ((loading && !pressReleases.length) || tagsQuery.loading) return <Loading />;
  if (error || tagsQuery.error) return <p>Error :</p>;

  return (
    <>
      <Grid justify="space-between" align="center">
        <Grid.Col span="content"><h2>Press releases</h2></Grid.Col>
        <Grid.Col span="content">
        </Grid.Col>
      </Grid>

      <Search
        startSearch={startSearch}
        search={search}
        setSearch={setSearch}
        setSelectedTagIds={setSelectedTagIds}
        selectedTagIds={selectedTagIds}
        label={t("search_by_pr_name_or_tag")}
      />

      <InfiniteScroll
        dataLength={pressReleases.length}
        next={loadMore}
        hasMore={ data?.allPublicPressReleases?.pageInfo?.hasNextPage }
        loader={ <Group justify="center" grow><Loader /></Group>}
        endMessage={<p>No more data to load.</p> }
        className={classes.infiniteScroll}
      >

        <Grid mt={12}>
          {pressReleases.map(pressRelease => (
            <Grid.Col key={pressRelease.id} lg={3} md={4} sm={6} xs={12} span={3}>
              <PressReleaseCard
                pressRelease={pressRelease}
                backUrl="/press_releases"
              />
            </Grid.Col>
          ))}
        </Grid>
      </InfiniteScroll>
    </>
  );
}