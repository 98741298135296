import React from "react";
import {
  createStyles,
  Card,
  Text,
  rem,
  Image, LoadingOverlay,
} from "@mantine/core";
import { useQuery } from "@apollo/client";
import { GET_COMPANY } from "@/graphql/queries/CompanyQueries";
import { useParams } from "react-router-dom";
import noImage from "@/components/mocks/no-image.png";
import {useTranslation} from "react-i18next";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  avatar: {
    border: `${rem(2)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
    }`,
  },
}));

export default function CompanyCardWithImage({ image, name, job }) {
  const { t } = useTranslation(['translation']);
  const { classes, theme } = useStyles();
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_COMPANY, {
    variables: { id: id },
  });

  if (loading) return <LoadingOverlay visible={loading} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;

  return (
    <Card padding="xl" radius="lg" className={classes.card}>
      <Card.Section mt="sm">
        <Image src={data.company.logoUrl || noImage } height={70} fit="contain" mt={50} />
      </Card.Section>
      <Text ta="center" fz="lg" fw={500} mt="sm">
        {data.company.name}
      </Text>
    </Card>
  );
}
