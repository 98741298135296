import React, {useState, useEffect} from 'react';
import {Table} from '@mantine/core';
import {useParams} from "react-router-dom";
import { useQuery } from '@apollo/client';
import { GET_FUNDINGS_BY_COMPANY_UUID } from '~/graphql/queries/FundingQueries';
import {useTranslation} from "react-i18next";
import Funding from './Funding';

export default function Fundings({fundings}) {
  const { t } = useTranslation(['translation']);

  return (
    <Table>
      <thead>
        <tr>
          <th>{t('company.fundings.name_placeholder')}</th>
          <th>{t('company.fundings.date_placeholder')}</th>
          <th>{t('company.fundings.number_of_investors_label')}</th>
          <th>{t('company.fundings.money_raised_label')}</th>
          <th>{t('company.fundings.lead_investors_label')}</th>
        </tr>
      </thead>
      <tbody>{fundings.map((funding) => (
        <Funding key={funding.id} funding={funding} />
      ))}</tbody>
    </Table>
  );
}