import React, {useContext} from "react";
import PressKit from "~/components/PressKits/PressKit";
import PressReleasePage from "~/components/PressKits/PressReleases/PressReleasePage/PressReleasePage";
import JournalistView from "./JournalistView";
import {Container, AppShell, useMantineTheme} from "@mantine/core";
import {CurrentUser} from "@/contexts/CurrentUserContext";

export default function ExternalPageContainer({ view }) {
  const theme = useMantineTheme();
  const currentUser = useContext(CurrentUser);

  if (currentUser?.user_type === "journalist") {
    return (<JournalistView view={view} />);
  }

  const renderPage = () => {
    switch(view) {
      case "PressKit":
        return <PressKit />;
      case "PressReleasePage":
        return <PressReleasePage />;
      default:
        return "";
    }
  };

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      }}
    >
      <Container size="md">
        {renderPage()}
      </Container>
    </AppShell>
  );
}