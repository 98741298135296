import React from "react";
import { Modal, Space, Group, Button, Anchor, Text } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { DELETE_COMPANY } from "~/graphql/mutations/CompanyMutations";
import { useTranslation } from "react-i18next";

export default function DeleteCompanyModal({ opened, close, companyId, setCompanies }) {
  const [_deleteCompany] = useMutation(DELETE_COMPANY);
  const { t } = useTranslation(['translation']);
  const deleteCompany = () => {
    _deleteCompany({
      variables: {
        id: companyId,
      },
      update(_cache) {
        setCompanies((companies) => {
          const index = companies.findIndex((company) => company.id === companyId);
          companies.splice(index, 1);
          return [...companies];
        })
      }
    });
    close();
  };
  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        <Text align="center">
          Are you sure you want to delete this company?
        </Text>
        <Space h="xl" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('companies.cancel_button')}
          </Anchor>
          <Button onClick={deleteCompany} color="red">
            OK
          </Button>
        </Group>
      </Modal>
    </>
  );
}
