import React, { useState } from 'react';
import {
  Avatar,
  Group,
  Text,
  ActionIcon,
  Menu,
  Badge,
  MultiSelect,
  rem,
  Anchor
} from '@mantine/core';
import {
  IconPencil,
  IconTrash,
  IconDotsVertical,
} from '@tabler/icons-react';
import DeleteJournalistModal from './DeleteJournalistModal';
import { useDisclosure } from "@mantine/hooks";
import EditJournalistModal from './EditJournalistModal';
import {useMutation} from "@apollo/client";
import {UPDATE_JOURNALIST} from "@/graphql/mutations/JournalistMutations";
import {useTranslation} from "react-i18next";

export default function Journalist({ journalist, classes, tags, setJournalists }) {
  const { t } = useTranslation(['translation']);
  const [isDeleteModalOpened, deleteModalHandlers] = useDisclosure(false);
  const [isEditModalOpened, editModalHandlers] = useDisclosure(false);
  const [_updateJournalist] = useMutation(UPDATE_JOURNALIST)
  const [tagEditable, setTagEditable] = useState(false);

  function statusColor(journalist) {
    if (journalist.user) {
      return "green";
    }
    return "gray";
  }

  function handleUpdateJournalistTags(tagIds) {
    _updateJournalist({
      variables: {
        journalist: {
          id: journalist.id,
          tagIds: tagIds,
        },
      }
    })
  }

  return (
   <tr className={classes.tr}>
      <td>
        <Anchor underline={false} href={`/journalists_show/${journalist.id}`}>
          <Group spacing="sm">
            <Avatar size={40} src={journalist?.avatar} radius={40} />
            <div>
              <Text fz="sm" fw={500}>
                {journalist.name}
              </Text>
              <Text c="dimmed" fz="xs">
                {journalist.email}
              </Text>
            </div>
          </Group>
        </Anchor>
      </td>
     <td>
       <Text fz="sm" fw={500}>
         {journalist.media?.name}
       </Text>
     </td>
      <td>
        <Badge color={ statusColor(journalist) }>{journalist.user ? "subscribed" : "created"}</Badge>
      </td>
      <td className={classes.tagTd} onClick={() => setTagEditable(true)}>
        {tagEditable ?
          <MultiSelect
            data={tags.map((tag) => ({ value: tag.id, label: tag.name }))}
            placeholder={t('journalists.tags_placeholder')}
            defaultValue={journalist.tags.map((tag) => tag.id)}
            variant="unstyled"
            searchable
            readOnly={journalist.user}
            rightSection={null}
            onChange={(tagIds) => handleUpdateJournalistTags(tagIds)}
          />
          :
          <MultiSelect
            data={tags.map((tag) => ({ value: tag.id, label: tag.name }))}
            placeholder={t('journalists.tags_placeholder')}
            defaultValue={journalist.tags.map((tag) => tag.id)}
            value={journalist.tags.map((tag) => tag.id)}
            variant="unstyled"
            readOnly={true}
            rightSection={null}
          />
        }
      </td>
      <td>
        {
          !journalist.user &&
          <Group spacing={0} position="right">
            <Menu withinPortal position="bottom-end" shadow="sm">
              <Menu.Target>
                <ActionIcon radius="xl">
                  <IconDotsVertical size="1rem"/>
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconPencil size={rem(14)} />}
                  onClick={ () => {
                    editModalHandlers.open()
                    setTagEditable(false)
                  }}
                >
                  {t('edit_button')}
                </Menu.Item>
                <Menu.Item
                  icon={<IconTrash size={rem(14)} />}
                  onClick={deleteModalHandlers.open}
                  color="red"
                >
                  {t('delete_button')}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <DeleteJournalistModal 
              opened={isDeleteModalOpened}
              close={deleteModalHandlers.close}
              setJournalists={setJournalists}
              journalistId={journalist.id}/>
            <EditJournalistModal
              setJournalists={setJournalists}
              opened={isEditModalOpened}
              close={editModalHandlers.close}
              journalist={journalist}/>
          </Group>
        }
      </td>
    </tr>
  );
}
