import React, { useContext } from "react";
import { CurrentUser } from "~/contexts/CurrentUserContext";
import AgencyView from "./AgencyView";
import JournalistView from "./JournalistView";
import NotFoundTitle from "@/components/Shared/NotFoundTitle";

export default function PageContainer({ view, sidebar }) {
    const currentUser = useContext(CurrentUser);

    if (!currentUser.id) {
        return document.location.assign("/sign_in");
    }

    switch (currentUser.user_type) {
        case "standard":
            return <AgencyView view={view} sidebar={sidebar} />;
        case "journalist":
            return <JournalistView view={view} sidebar={sidebar} />;
        default:
            return <NotFoundTitle />;
    }
}