import React from "react";
import {ActionIcon, Card, Group, Text, Tooltip} from "@mantine/core";
import AttachmentPreview from "~/components/Companies/Attachments/AttachmentPreview";
import {IconDownload} from "@tabler/icons-react";

export default function ImageCard({ attachment }) {
  function slicedName() {
    if (attachment.name.length > 14) {
      return attachment.name.slice(0, 14) + "…";
    } else {
      return attachment.name;
    }
  }
  const downloadUrl = `/download/attachments/${attachment.id}`;

  return (
    <Tooltip label={attachment.name} openDelay={500}>
      <Card shadow="sm" radius="md" padding={10}>
        <Card.Section inheritPadding py="xs">
          <Group position="apart" noWrap={true}>
            <Text weight={500} size={14}>
              {slicedName()}
            </Text>
            <ActionIcon
              component="a"
              href={downloadUrl}
              size={22}
            >
              <IconDownload />
            </ActionIcon>
          </Group>
        </Card.Section>
        <Card.Section
          component={"a"}
          href={attachment.url}
          target="_blank"
        >
          <AttachmentPreview attachment={attachment} />
        </Card.Section>
      </Card>
    </Tooltip>
  );
}