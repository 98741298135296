import { gql } from "@apollo/client";

export const GET_SERVICES = gql`
query services($companyId: ID!) {
  services(companyId: $companyId) {
    __typename
    id
    companyId
    name
    description
    serviceImageUrl
  }
}
`;

export const GET_SERVICES_BY_COMPANY_UUID = gql`
query servicesByCompanyUuid($companyUuid: String!) {
  servicesByCompanyUuid(companyUuid: $companyUuid) {
    __typename
    id
    companyId
    name
    description
    serviceImageUrl
  }
}
`;

export const GET_SERVICE = gql`
query service($id: ID!) {
  service(id: $id) {
    __typename
    id
    companyId
    name
    description
    serviceImageUrl
  }
}
`;
