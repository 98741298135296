import React, { useState } from "react";
import { Modal, Space, Group, Button, Anchor, TextInput } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { UPDATE_FOLDER } from "~/graphql/mutations/FolderMutations";
import { GET_FOLDERS } from "@/graphql/queries/FolderQueries";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function RenameFolderModal({
  opened,
  close,
  attachmentType,
  folderId,
  folderName,
}) {
  const { t } = useTranslation(['translation']);
  const [newFolderName, setNewFolderName] = useState(folderName || "");
  const [error, setError] = useState(null);
  const [updateFolder] = useMutation(UPDATE_FOLDER);
  const { id } = useParams();

  function onChange(event) {
    setNewFolderName(event.currentTarget.value);
    setError(null);
  }

  const renameFolder = () => {
    if (newFolderName === "") {
      setError(t('company.attachments.folder_name_error'));
      return;
    }

    if (newFolderName.length > 50) {
      setError(t('company.attachments.folder_length_error'));
      return;
    }

    updateFolder({
      variables: {
        folder: {
          id: folderId,
          name: newFolderName,
        },
      },
      refetchQueries: [
        {
          query: GET_FOLDERS,
          variables: { companyId: id, folderType: attachmentType },
        },
      ],
    });
    close();
  };
  return (
    <>
      <Modal opened={opened} onClose={close} title={t('company.attachments.rename_header')} centered>
        <TextInput
          placeholder="Folder name"
          value={newFolderName}
          onChange={onChange}
          error={error}
        />
        <Space h="xs" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button onClick={renameFolder}>{t('ok_button')}</Button>
        </Group>
      </Modal>
    </>
  );
}
