import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import Locize from 'i18next-locize-backend'; // load from service
import HttpApi from 'i18next-http-backend'; // have a own http fallback

function initI18n(language) {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      debug: true,
      lng: language,
      fallbackLng: 'en',
      backend: {
        backends: [
          // Locize,
          HttpApi
        ],
        backendOptions: [
          // {
          //   projectId: '953122ca-8e88-426b-8a44-716f70764688',
          //   apiKey: 'dfa60d0e-1178-48c2-b801-9df0a34430af', // YOU should not expose your apps API key to production!!!
          // },
        ],
      },
    });
}

export default initI18n;