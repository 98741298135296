import React, {useEffect, useState} from 'react';
import { createStyles, Container, Group, Anchor, rem, Button } from '@mantine/core';
import {useNavigate, useParams} from "react-router-dom";
import {
  GET_ALL_PRESS_RELEASES,
  GET_COMPANY_PRESS_RELEASES,
  GET_PRESS_RELEASE
} from "@/graphql/queries/PressReleaseQueries";
import { PUBLISH_PRESS_RELEASE } from "@/graphql/mutations/PressReleaseMutations";
import { useQuery, useMutation } from "@apollo/client";
import { useDisclosure } from '@mantine/hooks';
import PublishConfirmationModal from './PublishConfirmationModal'
import {useTranslation} from "react-i18next";

const useStyles = createStyles((theme) => ({
  footer: {
    position: "fixed",
    zIndex: 100,
    bottom: 0,
    width: "100%",
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    left: 0,
    borderTop: `2px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
    }`,
    marginTop: rem(120),
  },
  footerWithSidebar: {
    position: "fixed",
    bottom: 0,
    width: "80%",
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    right: 0,
    borderTop: `2px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
    }`,
    marginTop: rem(120),
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

}));

export function FooterBar({companyId, backUrl, setIsCompleted, isCompleted, params}) {
  const { t } = useTranslation(['translation']);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { press_release_id } = useParams();
  const [pressRelease, setPressRelease] = useState({ name: ''})
  const pressReleaseQuery = useQuery(GET_PRESS_RELEASE, {
    variables: { id: press_release_id }
  });
  const [publishPressRelease, { loading }] = useMutation(PUBLISH_PRESS_RELEASE);
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    setPressRelease(pressReleaseQuery?.data?.pressRelease)
  }, [pressReleaseQuery?.data])

  function publishPressReleaseHandler() {
    close()
    publishPressRelease({
      variables: { id: press_release_id },
      refetchQueries: [
        { query: GET_ALL_PRESS_RELEASES },
        { query: GET_COMPANY_PRESS_RELEASES, variables: { id: companyId } },
      ]
    })
    setIsCompleted(true)
  }

  function isPrValid() {

    if (params.emailNotification) {
      return params.subject
        && params.message
        && params.companyId
        && params.tagIds.length
        && params.pressReleaseFiles.length
    }

    if (params.isPublic) {
      return params.name
        && params.companyId
        && params.tagIds.length
    }

    return false
  }

  if ((pressReleaseQuery?.data?.pressRelease?.status === "published" || loading || pressReleaseQuery.loading) && !isCompleted) {
    return (
      <div className={ companyId ? classes.footerWithSidebar : classes.footer}>
        <Container className={classes.inner}>
          <div></div>
        </Container>
      </div>
    )
  }

  return (
    <div className={ companyId ? classes.footerWithSidebar : classes.footer}>
      <Container className={classes.inner}>
        <div></div>
        <Group className={classes.links} spacing={"40px"}>
          { !isCompleted && <Anchor
            underline={false}
            size="sm"
            radius="xl"
            onClick={() => navigate(backUrl)}
          >
            {t('cancel_button')}
          </Anchor> }
          { !isCompleted && <Button
            size="sm"
            onClick={open}
            disabled={!isPrValid()}
          >{t('publish_button')}</Button> }
          { isCompleted && <Button
            size="sm"
            onClick={() => navigate(backUrl)}
          >
            {t('done_button')}
          </Button> }
        </Group>
      </Container>
      <PublishConfirmationModal
        close={close}
        opened={opened}
        pressRelease={pressRelease}
        publishPressReleaseHandler={publishPressReleaseHandler}
      />
    </div>
  );
}