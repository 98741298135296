import React from 'react';
import PressReleaseList from "@/components/PressReleases/PressReleaseList";
import {useNavigate, useParams} from "react-router-dom";
import {Grid, LoadingOverlay} from "@mantine/core";
import {GET_COMPANY_PRESS_RELEASES} from "@/graphql/queries/PressReleaseQueries";
import {useQuery} from "@apollo/client";
import NewPressReleaseButton from "@/components/Companies/PressReleases/NewPressReleaseButton";
import {useTranslation} from "react-i18next";

export default function PressReleases() {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_COMPANY_PRESS_RELEASES, { variables: { companyId: id } });

  if (loading) return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;

  return (
    <>
      <Grid justify="space-between" align="center">
        <Grid.Col span="content">
          <h2>{t('company.press_releases_header')}</h2>
        </Grid.Col>
        <Grid.Col span="content">
          <NewPressReleaseButton />
        </Grid.Col>
      </Grid>
      <PressReleaseList
        companyId={id}
        pressReleasesData={data.pressReleases}
      />
    </>
  );
}