import React from "react";
import { Paper, Grid } from "@mantine/core";
import MemberCard from "@/components/Companies/AboutCompany/InputCards/CompanyPeople/MemberCard";
import classes from "./AboutCompany.module.scss";
import Fundings from "../Fundings/Fundings"

import { useTranslation } from "react-i18next";

export default function AboutCompany({ company }) {
  const { t } = useTranslation(["translation"]);

  return (
    <div className={classes.aboutCompanyWrapper}>
      <Grid mt={12}>
        <Grid.Col>
          <Paper shadow="xs" radius="md" p="xl" mt={12}>
            <h2>{t("press_kits.about")}</h2>
            <div dangerouslySetInnerHTML={{ __html: company.description }} />
          </Paper>
        </Grid.Col>
        <Grid.Col span={12}>
          <Paper shadow="xs" radius="md" p="xl" mt={12}>
            <h2>{t("press_kits.more_about")}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: company.descriptionMore }}
            />
          </Paper>
        </Grid.Col>
        <Grid.Col span={12}>
          <Paper shadow="xs" radius="md" p="xl" mt={12}>
            <h2>{t("press_kits.key_people")}</h2>
            <Grid>
              {company.keyPeople.map((member) => (
                <Grid.Col xs={12} sm={6} md={4} lg={4} key={member.id}>
                  <MemberCard
                    key={member.id}
                    avatar={member.avatarUrl}
                    name={member.name}
                    title={member.role}
                  />
                </Grid.Col>
              ))}
            </Grid>
          </Paper>
        </Grid.Col>
      </Grid>
      <Grid mt={12}>
        <Grid.Col xs={12} sm={6} md={6} lg={6}>
          <Paper
            shadow="xs"
            radius="md"
            p="xl"
            mt={12}
            className={classes.miniCard}
          >
            <h2>{t("press_kits.formation_date")}</h2>
            <p>{company.formationDate}</p>
          </Paper>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} md={6} lg={6}>
          <Paper
            shadow="xs"
            radius="md"
            p="xl"
            mt={12}
            className={classes.miniCard}
          >
            <h2>{t("press_kits.turnover")}</h2>
            <p>{company.turnover}</p>
          </Paper>
        </Grid.Col>
      </Grid>
      {company.fundings.length > 0  &&
        <Grid mt={12}>
          <Grid.Col>
            <Paper shadow="xs" radius="md" p="xl" mt={12}>
              <h2>{t("press_kits.fundings")}</h2>
              <Fundings fundings={company.fundings} />
            </Paper>
          </Grid.Col>
        </Grid>
      }
    </div>
  );
}
