import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
  Paper,
  Container,
  Anchor,
  Input,
  Textarea,
  Space,
  Text,
  Image,
  SimpleGrid, Grid, LoadingOverlay
} from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { GET_PRODUCT } from "@/graphql/queries/ProductQueries";
import { UPDATE_PRODUCT } from "@/graphql/mutations/ProductMutations";
import { useQuery, useMutation } from '@apollo/client';
import { debounce } from "lodash";
import {useTranslation} from "react-i18next";

export default function ProductPage() {
  const { t } = useTranslation(['translation']);
  const { id, product_id } = useParams();
  const navigate = useNavigate();
  const productQuery = useQuery(GET_PRODUCT, { variables: { id: product_id } });
  const [updateProduct, {loading}] = useMutation(UPDATE_PRODUCT);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [typing, setTyping] = useState(false);

  const onChange = (name, description) => {
    updateProductHandler(name, description);
  }
  const debouncedOnChange = debounce(onChange, 1000);

  if (productQuery.loading) return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (productQuery.error) return <p>Error :(</p>;

  function updateProductHandler(product) {
    updateProduct({
      variables: {
        product: {
          id: product_id,
          name: product.name,
          description: product.description
        }
      }
    })
    setName(product.name);
    setDescription(product.description);
    setTyping(false);
  }

  function uploadImage(files) {
    updateProduct({
      variables: {
        product: {
          id: product_id,
          productImage: files[0]
        }
      }
    });
  }

  function savingState() {
    if (loading || typing) {
      return t('saving_text');
    } else {
      return t('saved_text');
    }
  }

  return (
    <>

      <Grid justify="space-between" align="center">
        <Grid.Col span="content">
          <Anchor
            underline={false}
            onClick={() => navigate(`/companies/${id}/products`) }
          >
            {`${t('company.product.back_to_all')}`}
          </Anchor>
        </Grid.Col>
        <Grid.Col span="content">
          <Text c="dimmed">{savingState()}</Text>
        </Grid.Col>
      </Grid>

        <Paper shadow="xs" p={30} radius="md" mt="xl">
          <Input
            variant="filled"
            placeholder={t('company.product.name_label')}
            size="xl"
            onKeyDown={() => setTyping(true)}
            defaultValue={name || productQuery.data.product.name}
            onChange={(e) => {
              debouncedOnChange({
                name: e.target.value,
                description: description || productQuery.data.product.description || ""})
            }}
          />

          <Space h="xl" />
          <Textarea
            placeholder={t('company.product.name_label')}
            variant="filled"
            size="xl"
            withAsterisk
            autosize
            minRows={4}
            defaultValue={description || productQuery.data.product.description}
            onKeyDown={() => setTyping(true)}
            onChange={(e) => {
              debouncedOnChange({
                  name: name || productQuery.data.product.name || "",
                  description: e.target.value
              })
            }}
          />
          <Space h="xl" />
          <Dropzone
            accept={IMAGE_MIME_TYPE}
            onDrop={(files) => { uploadImage(files); }}
            multiple={false}
          >
            { !productQuery.data.product.productImageUrl && <Text align="center" >{t('company.product.drop_images_label')}</Text> }
            <SimpleGrid
              cols={4}
              breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
            >
              { productQuery.data.product.productImageUrl &&  <Image
                src={productQuery.data.product.productImageUrl}
              />}
            </SimpleGrid>
          </Dropzone>
        </Paper>
    </>
  );
}
