import React from 'react';
import {Button, LoadingOverlay} from '@mantine/core';
import { useMutation } from '@apollo/client';
import { CREATE_SERVICE } from '~/graphql/mutations/ServiceMutations';
import {useNavigate, useParams} from "react-router-dom";
import { GET_SERVICES } from '~/graphql/queries/ServiceQueries';
import {useTranslation} from "react-i18next";
import {IconPlus} from "@tabler/icons-react";

export default function CreateService(companyId) {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const [createService, { loading, data }] = useMutation(CREATE_SERVICE);
  const newServiceParams = { service: { name: "Untitled service", companyId: id }}
  const navigate = useNavigate();

  const handleCreateService = () => {
    createService(
      {
        variables: newServiceParams,
        refetchQueries: [
          {
            query: GET_SERVICES,
            variables: { companyId: id },
          },
        ],
      })
  }

  if (data?.createService?.id) navigate(`/companies/${id}/service/${data.createService.id}/services`)

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Button
        variant="light"
        size="sm"
        radius="xl"
        leftIcon={<IconPlus />}
        onClick={handleCreateService}>
        {t('company.services.create_button')}
      </Button>
    </>
  );
}
