import React, { useState } from "react";
import { Modal, Space, Group, Button, Anchor, TextInput } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { UPDATE_ATTACHMENT } from "~/graphql/mutations/AttachmentMutations";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function RenameAttachmentModal({
  opened,
  close,
  attachmentId,
  attachmentQuery,
  attachmentName,
}) {
  const { t } = useTranslation(['translation']);
  const [newAttachmentName, setNewAttachmentName] = useState(
    attachmentName || ""
  );
  const [error, setError] = useState(null);
  const [updateAttachment] = useMutation(UPDATE_ATTACHMENT);
  const { id, folder_id } = useParams();

  function onChange(event) {
    setNewAttachmentName(event.currentTarget.value);
    setError(null);
  }
  const renameAttachment = () => {
    if (newAttachmentName === "") {
      setError(t('company.attachments.attachment_name_error'));
      return;
    }

    if (newAttachmentName.length > 200) {
      setError(t('company.attachments.attachment_length_error'));
      return;
    }

    updateAttachment({
      variables: {
        attachment: {
          id: attachmentId,
          name: newAttachmentName,
        },
      },
      refetchQueries: [
        {
          query: attachmentQuery,
          variables: { companyId: id, folderId: folder_id }
        },
      ],
    });
    close();
  };
  return (
    <>
      <Modal opened={opened} onClose={close} title={t('company.attachments.rename_header')} centered>
        <TextInput
          placeholder="Attachment name"
          value={newAttachmentName}
          onChange={onChange}
          error={error}
        />
        <Space h="xs" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button onClick={renameAttachment}>{t('ok_button')}</Button>
        </Group>
      </Modal>
    </>
  );
}
