import React from 'react';

 const submitLogin = (data, setError) => {
    fetch("/users/sign_in", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((data) => {
            if (data["result"] === "success") {
                window.location.assign("/");
            } else {
                setError(data["errors"]); //"Record not found. Please contact support@prbox.cz to reset the password"
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
};

const submitLogout = () => {
    fetch("/users/sign_out", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        },
    })
        .then((response) => response.json())
        .then((data) => {
            if (data["result"] === "success") {
                window.location.assign("/sign_in");
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
};

const registerAccount = (data, setError) => {
  fetch("/users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
    body: JSON.stringify({ user: data }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data["result"] === "success") {
        window.location.assign("/");
      } else {
        setError(data["errors"]);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const sendResetPassword = (data, setError) => {
  fetch("/users/password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
    body: JSON.stringify({ user: data }),
  }).then((response) => response.json())
    .then((data) => {
      if (data["result"] === "success") {
        window.location.assign("/sign_in");
      } else {
        setError(data["errors"]);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

const changePassword = (data, setError) => {
  fetch("/users/password", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
    body: JSON.stringify({ user: data }),
  }).then((response) => response.json())
    .then((data) => {
      if (data["result"] === "success") {
        window.location.assign("/sign_in");
      } else {
        setError(data["errors"]);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

const verifyJournalistEmail = (data, setError, setStatus, setJournalistId) => {
  fetch("/journalists/verify_email", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
    body: JSON.stringify({ user: data }),
  }).then((response) => response.json())
    .then((data) => {
      if (data["result"] === "success") {
        setStatus(data["status"])
        setJournalistId(data["journalist_id"])
      } else {
        setError(data["errors"]);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export { submitLogin, submitLogout, registerAccount, sendResetPassword, changePassword, verifyJournalistEmail };
