import React from "react";
import {rem, Image} from "@mantine/core";
import {IconFile, IconVideo} from "@tabler/icons-react";
import classes from './AttachmentPreview.module.scss';

export default function AttachmentPreview({ attachment }) {
  switch (attachment.type) {
    case "IMAGE":
      return <Image src={attachment.url} height={200} fit="cover"/>;
    case "DOCUMENT":
      return (
        <div
          className={classes.attachmentIconWrapper}
        >
          <IconFile className={classes.attachmentIcon} size={rem(72)} />
        </div>
      );
    case "VIDEO":
      return (
        <div
          className={classes.attachmentIconWrapper}
        >
          <IconVideo className={classes.attachmentIcon} size={rem(72)} />
        </div>
      );
    default:
      return null;
  }
}