import React from "react";
import {Grid, LoadingOverlay} from "@mantine/core";
import {useQuery} from "@apollo/client";
import {GET_TEAM_MEMBERS_BY_COMPANY_UUID} from "@/graphql/queries/TeamMemberQueries";
import MemberCard from './MemberCard'
import {useTranslation} from "react-i18next";
import { Alert } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

export default function Team({ companyUuid }) {
  const { t } = useTranslation(['translation']);
  const { loading, error, data } = useQuery(GET_TEAM_MEMBERS_BY_COMPANY_UUID, { variables: { companyUuid: companyUuid } });
  if (loading) return <LoadingOverlay visible={loading} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;

  const members = data.teamMembersByCompanyUuid

  if (members.length === 0) {
    return (
      <Alert mt={10} icon={<IconAlertCircle size="1rem"/>} title={t('press_kits.nothing_added_yet')} color="indigo">
      </Alert>
    );
  }


  return (
    <div>
      <Grid mt={24}>
        {members.map(member => (
          <Grid.Col key={member.id} xs={12} sm={6} md={4} lg={4}>
            <MemberCard member={member}/>
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
}