import { gql } from "@apollo/client";

export const GET_JOURNALIST = gql`
  query Journalist($id: ID!) {
    journalist(id: $id) {
      id
      name
      email
      status
      description
      user {
        avatarUrl
      }
      tags {
        id
        name
      }
    }
  }
`;

export const GET_JOURNALISTS = gql`
  query journalists($sortBy: String, $sort: String, $after: String, $search: String, $selectedTagIds: [ID!]) {
    journalists(sortBy: $sortBy, sort: $sort, after: $after, search: $search, selectedTagIds: $selectedTagIds) {
      totalCount
      nodes {
        id
        name
        email
        description
        status
        tags {
          id
          name
        }
        media {
          id
          name
        }
        user {
          id
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
    }
  }
`;
