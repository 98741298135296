import React from "react";
import {
  Card,
  Text,
  Image,
  Space,
  Tooltip,
  Badge,
  Grid,
} from "@mantine/core";
import noImage from "../../mocks/no-image.png";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import DeleteCompanyModal from "../DeleteCompanyModal";
import classes from "../CompanyCard.module.scss";
import ShortInformation from "@/components/Companies/SordInformation/ShortInformation";
import Subscribe from "../../PressKits/Subscribe/Subscribe";

export default function CompanyCard({ company, setCompanies }) {
  const navigate = useNavigate();
  const [isDeleteModalOpened, deleteModalHandlers] = useDisclosure(false);

  const handlePreview = () => {
    navigate(`/presskits/${company.externalUuid}`);
  };

  function getBadge() {
    if (company?.status === "draft") {
      return <Badge color="gray" size="xs">Draft</Badge>;
    } else {
      return <Badge color="green" size="xs">Published</Badge>;
    }
  }

  return (
    <>
      <Tooltip label={company.name} openDelay={500}>
        <Card shadow="sm" radius="md">

          <div style={{ cursor: "pointer" }} onClick={handlePreview}>
            <Grid>
              <Grid.Col span={10}>
                <Text weight={500} truncate={true} >{company.name}</Text>
              </Grid.Col>
              <Grid.Col span={2}>
              </Grid.Col>
            </Grid>
            <Card.Section inheritPadding>
              <Image
                className={classes.image}
                src={company.logoUrl || noImage}
                height={60}
                fit="contain"
              />
            </Card.Section>

            <div className={classes.shortInformationContainer}>
              <ShortInformation tags={company.tags} textSize={"xs"} />
            </div>
            <div style={{ minHeight: 130 }} >
              <Text mt="xs" size="xs" lineClamp={7}>
                {company.tagline || "No tagline"}
              </Text>
            </div>
            <Space h="xs" />
          </div>
          <Subscribe companyId={company.id} />
          <Space h="xs" />
        </Card>
      </Tooltip>
      <DeleteCompanyModal
        opened={isDeleteModalOpened}
        close={deleteModalHandlers.close}
        companyId={company.id}
        setCompanies={setCompanies}
      />
    </>
  );
}
