import React from "react";
import {LoadingOverlay, Paper, ScrollArea} from "@mantine/core";
import {useQuery} from "@apollo/client";
import { GET_COMPANY_BY_EXTERNAL_UUID } from "@/graphql/queries/CompanyQueries";
import { useTranslation } from "react-i18next";

export default function Contacts({ companyUuid }) {
  const { t } = useTranslation(['translation']);
  const { loading, error, data } = useQuery(GET_COMPANY_BY_EXTERNAL_UUID, {
    variables: { externalUuid: companyUuid },
  });
  if (loading) return <LoadingOverlay visible={loading} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;

  const contactsText = data.companyByExternalUuid.contactsText;

  return (
    <Paper shadow="xs" radius="md" p="xl" mt={12}>
      <ScrollArea>
        <div dangerouslySetInnerHTML={{ __html: contactsText }} />
      </ScrollArea>
    </Paper>
  );
}