import {gql} from "@apollo/client";

export const UPDATE_USER = gql`
mutation UpdateUser($user: UserInput!) {
  updateUser(input: {user: $user}) {
    __typename
    id
    name
    language
    phone
    avatarUrl
  }
}
`;

export const DELETE_TEAMMATE = gql`
mutation DeleteTeammate($userId: ID!) {
  deleteTeammate(input: {userId: $userId}) {
    success
  }
}
`;
