import React from "react";
import {
  Card,
  Group,
  Text,
  Menu,
  ActionIcon,
  rem,
  Tooltip,
  Flex,
} from "@mantine/core";
import {
  IconDotsVertical,
  IconEye,
  IconFileZip,
  IconFolderFilled,
  IconPencil,
  IconTrash,
} from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import RenameFolderModal from "./RenameFolderModal";
import DeleteFolderModal from "./DeleteFolderModal";
import {useTranslation} from "react-i18next";

export default function FolderCard({ name, folderId, attachmentType }) {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isRenameFolderOpened, renameFolderHandlers] = useDisclosure(false);
  const [isDeleteFolderOpened, deleteFolderHandlers] = useDisclosure(false);

  const downloadUrl = `/download/folders/${folderId}`;

  function slicedName() {
    if (name.length > 11) {
      return name.slice(0, 11) + "…";
    } else {
      return name;
    }
  }

  const handlePreview = () => {
    navigate(`/companies/${id}/folders/${folderId}/${attachmentType}`);
  };

  return (
    <>
      <Tooltip label={name} openDelay={500}>
        <Card shadow="sm" radius="md" padding={10}>
          <Card.Section inheritPadding py="xs">
            <Group position="apart" noWrap={true} spacing={"1"}>
              <Flex
                style={{ cursor: "pointer" }}
                onClick={handlePreview}
                justify="start"
                w="90%"
              >
                <IconFolderFilled size={rem(24)} />
                <Text weight={500} size={14} pl={4} lineClamp={1}>
                  {slicedName()}
                </Text>
              </Flex>
              <Menu withinPortal shadow="sm">
                <Menu.Target>
                  <ActionIcon size={22}>
                    <IconDotsVertical size="1rem" />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    icon={<IconEye size={rem(14)} />}
                    onClick={handlePreview}
                  >
                    {t('company.attachments.preview_button')}
                  </Menu.Item>
                  <Menu.Item
                    component="a"
                    href={downloadUrl}
                    download="attachments.zip"
                    icon={<IconFileZip size={rem(14)} />}
                  >
                    {t('company.attachments.download_zip')}
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconPencil size={rem(14)} />}
                    onClick={renameFolderHandlers.open}
                  >
                    {t('company.attachments.rename_button')}
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconTrash size={rem(14)} />}
                    color="red"
                    onClick={deleteFolderHandlers.open}
                  >
                    {t('company.attachments.delete_button')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Card.Section>
        </Card>
      </Tooltip>
      <RenameFolderModal
        opened={isRenameFolderOpened}
        close={renameFolderHandlers.close}
        attachmentType={attachmentType}
        folderId={folderId}
        folderName={name}
      />
      <DeleteFolderModal
        opened={isDeleteFolderOpened}
        close={deleteFolderHandlers.close}
        attachmentType={attachmentType}
        folderId={folderId}
      />
    </>
  );
}
