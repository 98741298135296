import React, {useState} from 'react';
import {NumberInput, Modal, Group, Space, Anchor, Input} from '@mantine/core';
import { DateInput } from "@mantine/dates";
import UpdateFunding from './UpdateFunding'
import {useTranslation} from "react-i18next";

export default function EditFundingModal({ opened, close, funding, setFundings }) {
  const { t } = useTranslation(['translation']);
  const [date, setDate] = useState(new Date(funding.date));
  const [name, setName] = useState(funding.name || '');
  const [numberOfInvestors, setNumberOfInvestors] = useState(parseInt(funding.numberOfInvestors));
  const [moneyRaised, setMoneyRaised] = useState(parseInt(funding.moneyRaised));
  const [leadInvestors, setLeadInvestors] = useState(funding.leadInvestors || '');

  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        <Input.Wrapper label={t('company.fundings.name_label')} required >
          <Input
            placeholder={t('company.fundings.name_placeholder')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Input.Wrapper>

        <Input.Wrapper label={t('company.fundings.date_label')} required >
          <DateInput
            placeholder={t('company.fundings.date_placeholder')}
            value={date}
            onChange={setDate}
          />
        </Input.Wrapper>
        <Space h="lg" />

        <Input.Wrapper label={t('company.fundings.number_of_investors_label')} required >
          <NumberInput
            placeholder={t('company.fundings.number_of_investors_placeholder')}
            value={numberOfInvestors}
            onChange={setNumberOfInvestors}
          />
        </Input.Wrapper>
        <Space h="lg" />

        <Input.Wrapper label={t('company.fundings.money_raised_label')} required >
          <NumberInput
            placeholder={t('company.fundings.money_raised_placeholder')}
            value={moneyRaised}
            onChange={setMoneyRaised}
          />
        </Input.Wrapper>
        <Space h="lg" />

        <Input.Wrapper label={t('company.fundings.lead_investors_label')} required >
          <Input
            placeholder={t('company.fundings.lead_investors_placeholder')}
            value={leadInvestors}
            onChange={(e) => setLeadInvestors(e.target.value)}
          />
        </Input.Wrapper>
        <Space h="lg" />

        <Space h="lg" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>Cancel</Anchor>
          <UpdateFunding
            submit={close}
            id={funding.id}
            date={date}
            name={name}
            numberOfInvestors={numberOfInvestors}
            moneyRaised={moneyRaised}
            leadInvestors={leadInvestors}
            setFundings={setFundings}
          />
        </Group>
      </Modal>
    </>
  );
}
