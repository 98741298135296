import React from "react";
import {
  CompanyLogo,
  CompanyName,
  CompanyFormationDate,
  CompanyTagline,
  CompanyOffices,
  CompanyPeople,
  BusinessType,
  CompanyTurnover,
  CompanyFunding,
  CompanyEmployees,
  CompanyWebsite,
  CompanySocials,
  CompanyDescription,
  CompanyDescriptionMore,
  CompanyTopics,
} from "./InputCards";
import { useMutation, useQuery } from "@apollo/client";
import { GET_COMPANY } from "@/graphql/queries/CompanyQueries";
import {useNavigate, useParams} from "react-router-dom";
import { UPDATE_COMPANY } from "@/graphql/mutations/CompanyMutations";
import {Anchor, Grid, ActionIcon, Group, Tooltip, CopyButton, Popover, LoadingOverlay} from "@mantine/core";
import useStyles from "./AboutCompany.styles";
import {IconLink, IconEye, IconCheck} from "@tabler/icons-react";
import PublishButton from "./PublishButton";
import {useTranslation} from "react-i18next";
import { useMediaQuery } from '@mantine/hooks';

export default function AboutCompany() {
  const { t } = useTranslation(['translation']);
  const { classes } = useStyles();
  const { id } = useParams();
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_COMPANY, {
    variables: { id },
  });
  const isMobile = useMediaQuery(`(max-width: 720px)`);
  if (loading) return <LoadingOverlay visible={loading} overlayBlur={2} />;
  if (error) return <p>Error :(</p>;
  const company = data.company;

  const updateCompanyAttributes = (attributes) => {
    updateCompany({
      variables: {
        company: {
          id: id,
          ...attributes,
        }
      },
    });
  }

  return (
    <div className={classes.mainContainer}>
      <Grid justify="space-between" align="center">
        {!isMobile && <Grid.Col span={12}>
          <Anchor
            underline={false}
            onClick={() => navigate(`/companies`) }
          >
            {t('company.back_to_all')}
          </Anchor>
        </Grid.Col> }
        <Grid.Col span="content"><h2>{t('company.about')}</h2></Grid.Col>
        <Grid.Col span="content" >
          <Group>
            <Tooltip label={t("company.as_visitor_label")} position="left" withArrow transition="pop" openDelay={500}>
              <Anchor
                underline={false}
                target={"_blank"}
                href={`/presskits/${company.externalUuid}`}
              >
                <ActionIcon
                  color="primary"
                  size="lg"
                  radius="xl"
                >
                  <IconEye />
                </ActionIcon>
              </Anchor>
            </Tooltip>

            <CopyButton value={`https://app.prbox.cz/presskits/${company.externalUuid}`}>
              {({ copied, copy }) => (
                <Tooltip label={t("company.copy_link_label")} position="left" withArrow transition="pop" openDelay={500}>
                  <ActionIcon
                    color={copied ? 'teal' : 'primary'}
                    size="lg"
                    radius="xl"
                    onClick={copy}
                  >
                    {copied ? <IconCheck /> : <IconLink />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>

            <PublishButton
              companyId={company.id}
              status={company.status}
            />
          </Group>
        </Grid.Col>
      </Grid>
      <CompanyLogo logoUrl={company.logoUrl} id={id}/>
      <CompanyName name={company.name} updateCompany={updateCompanyAttributes}/>
      <CompanyFormationDate date={company.formationDate} updateCompany={updateCompanyAttributes} />
      <CompanyTagline tagline={company.tagline} updateCompany={updateCompanyAttributes}/>
      <CompanyOffices officeAddress={company.officeAddress} updateCompany={updateCompanyAttributes}/>
      <CompanyPeople updateCompany={updateCompanyAttributes} keyPeople={company.keyPeople} />
      <BusinessType companyBusinessType={company.businessType} updateCompany={updateCompanyAttributes}/>
      <CompanyTurnover turnover={company.turnover} updateCompany={updateCompanyAttributes}/>
      <CompanyFunding funding={company.funding} updateCompany={updateCompanyAttributes}/>
      <CompanyEmployees employeesCount={company.employeesCount} updateCompany={updateCompanyAttributes}/>
      <CompanyWebsite webUrl={company.webUrl} updateCompany={updateCompanyAttributes}/>
      <CompanySocials
        facebookUrl={data.company.facebookUrl}
        linkedinUrl={data.company.linkedinUrl}
        twitterUrl={data.company.twitterUrl}
        instagramUrl={data.company.instagramUrl}
        updateCompany={updateCompanyAttributes}
      />
      <CompanyDescription description={data.company.description} updateCompany={updateCompanyAttributes}/>
      <CompanyDescriptionMore descriptionMore={data.company.descriptionMore} updateCompany={updateCompanyAttributes}/>
      <CompanyTopics tags={data.company.tags} updateCompany={updateCompanyAttributes}/>
    </div>
  );
}
