import React from "react";
import {
  Group,
  Text,
  useMantineTheme,
  rem,
  LoadingOverlay,
} from "@mantine/core";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons-react";
import { Dropzone } from "@mantine/dropzone";
import { useMutation } from "@apollo/client";
import { BULK_CREATE_ATTACHMENTS } from "@/graphql/mutations/AttachmentMutations";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function AttachmentsDropZone({
  fileAllowedFormats,
  attachmentType,
  attachmentQuery,
  folderId,
}) {
  const { t } = useTranslation(['translation']);
  const theme = useMantineTheme();
  const { id } = useParams();
  const [_bulkCreateAttachments, { loading }] = useMutation(
    BULK_CREATE_ATTACHMENTS
  );

  function handleFileChange(files) {
    _bulkCreateAttachments({
      variables: {
        files: files,
        folderId: folderId || null,
        companyId: id,
        attachmentType: attachmentType,
      },
      refetchQueries: [
        {
          query: attachmentQuery,
          variables: {
            companyId: id,
            ...(folderId && {
              folderId: folderId,
            }),
          },
        },
      ],
    });
  }

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Dropzone.FullScreen
        onDrop={handleFileChange}
        onReject={(files) => console.log("rejected files", files)}
        maxSize={3 * 1024 ** 2}
        accept={fileAllowedFormats}
      >
        <Group
          position="center"
          spacing="xl"
          style={{ minHeight: rem(220), pointerEvents: "none" }}
        >
          <Dropzone.Accept>
            <IconUpload
              size="3.2rem"
              stroke={1.5}
              color={
                theme.colors[theme.primaryColor][
                  theme.colorScheme === "dark" ? 4 : 6
                ]
              }
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size="3.2rem"
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto size="3.2rem" stroke={1.5} />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              {t('company.attachments.dorp_zone_text')}
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              {t('company.attachments.drop_zone_many')}
            </Text>
          </div>
        </Group>
      </Dropzone.FullScreen>
    </>
  );
}
