import {gql} from "@apollo/client";

export const CREATE_SERVICE = gql`
mutation CreateService($service: ServiceInput!) {
  createService(input: {service: $service}) {
    id
    name
    description
  }
}
`;

export const UPDATE_SERVICE = gql`
mutation UpdateService($service: ServiceInput!) {
  updateService(input: {service: $service}) {
    __typename
    id
    name
    description
    serviceImageUrl
  }
}
`;

export const DELETE_SERVICE = gql`
mutation DeleteService($id: ID!) {
  deleteService(input: {id: $id}) {
    success
  }
}
`;