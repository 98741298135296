import React, { useState } from "react";
import {
  Card,
  Group,
  TextInput,
  Stack,
  Text,
  Anchor, Button
} from "@mantine/core";
import useStyles from "../../AboutCompany.styles";
import SubmitButtons from "@/components/Companies/AboutCompany/InputCards/shared/SubmitButtons";
import {useTranslation} from "react-i18next";

export default function CompanyWebsite({ webUrl, updateCompany }) {
  const { t } = useTranslation(['translation']);
  const { classes } = useStyles();
  const [companyWebsite, setCompanyWebsite] = useState(webUrl || "");
  const [editMode, setEditMode] = useState(false);
  const label = t('company.website_label');
  const placeholder = t('company.website_placeholder');
  const saveCompanyChanges = () => {
    updateCompany({ webUrl: companyWebsite });
    setEditMode(false);
  };
  const cancelChanges = () => {
    setCompanyWebsite(webUrl || "");
    setEditMode(false);
  };
  return (
    <>
      <Card radius={8} my="md" className={classes.inputCard}>
        <Group position="apart" align="flex-start" spacing={0}>
          {editMode ? (
            <TextInput
              label={label}
              placeholder={placeholder}
              value={companyWebsite}
              onChange={(event) => setCompanyWebsite(event.currentTarget.value)}
              disabled={!editMode}
              w={"95%"}
              classNames={{
                label: classes.label,
                input: classes.input,
              }}
            />
          ) : (
            <Stack>
              <Text size={"sm"} weight={500}>
                {label}
              </Text>
              {companyWebsite ? (
                <Anchor href={companyWebsite} target="_blank">
                  {companyWebsite}
                </Anchor>
              ) : (
                <Text className={classes.fakePlaceholder}>{placeholder}</Text>
              )}
            </Stack>
          )}
          {!editMode &&
            <Button
              variant="subtle"
              radius="xl"
              size="xs"
              compact
              onClick={() => setEditMode(true)}
            >
              {t('edit_button')}
            </Button>}
        </Group>
        { editMode && <SubmitButtons
          cancelChanges={cancelChanges}
          saveCompanyChanges={saveCompanyChanges}
        /> }
      </Card>
    </>
  );
}
