import { gql } from "@apollo/client";

export const NOTIFICATIONS_QUERY = gql`
  query notifications($after: String, $userId: ID!) {
    notifications(after: $after, userId: $userId) {
      totalCount
      nodes {
        id
        content
    	  readAt
        createdAt
        companyId
        logoUrl
        link
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
    }
  }
`;
