import React from "react";
import { Modal, Space, Group, Button, Anchor, Text } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { DELETE_PRESS_RELEASE } from "~/graphql/mutations/PressReleaseMutations";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function DeletePressReleaseModal({ opened, close, pressReleaseId, setPressReleases }) {
  const { t } = useTranslation(['translation']);
  const [_deletePressRelease] = useMutation(DELETE_PRESS_RELEASE);
  const { id } = useParams();
  const deletePressRelease = () => {
    _deletePressRelease({
      variables: {
        id: pressReleaseId,
      },
      update(_cache) {
        setPressReleases((pressReleases) => {
          const index = pressReleases.findIndex((pressRelease) => pressRelease.id === pressReleaseId);
          pressReleases.splice(index, 1);
          return [...pressReleases];
        })
      }
    });
    close();
  };
  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        <Text align="center">
          {t('press_releases.delete_confirm')}
        </Text>
        <Space h="xl" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button onClick={deletePressRelease} color="red">
            {t('ok_button')}
          </Button>
        </Group>
      </Modal>
    </>
  );
}
