import React, {useEffect} from 'react';
import { forwardRef } from 'react';
import {Group, Avatar, Text, Select, Image} from '@mantine/core';
import {useLazyQuery} from "@apollo/client";
import {GET_MEDIAS} from "@/graphql/queries/MediaQueries";
import noImage from "@/components/mocks/no-image.png";
import {useTranslation} from "react-i18next";

const AutoCompleteItem = forwardRef(
  ({ id, description, value, label, image, ...others }, ref) => (
    <div ref={ref} {...others} key={id}>
      <Group noWrap>
        { image ?
        <Avatar src={image} />
        : <Image src={ noImage} height={35} width={35} fit="contain" /> }

        <div>
          <Text>{label}</Text>
          <Text size="xs">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

export default function MediaFieldAutocomplete({ setMediaId, mediaId }) {
  const { t } = useTranslation(['translation']);
  const [getMedias, { loading, data, error }] = useLazyQuery(GET_MEDIAS, { fetchPolicy: 'no-cache'});

  useEffect(() => {
    getMedias()
  }, [])

  const medias = (data?.medias?.nodes || []).map((media) => {
    return { value: media.id,  label: media.name, image: media.logoUrl, description: media.description, id: media.id };
  });

  return (
    <Select
      label={t('journalists.media_label')}
      placeholder={t('journalists.media_placeholder')}
      defaultValue={mediaId}
      itemComponent={AutoCompleteItem}
      data={medias || []}
      onSearchChange={(value) => getMedias({ variables: {search: value} })}
      onChange={(value) => setMediaId(value)}
      searchable
    />
  );
}