import React from 'react';
import {Button, LoadingOverlay} from '@mantine/core';
import { useMutation } from '@apollo/client';
import { CREATE_PRODUCT } from '~/graphql/mutations/ProductMutations';
import {useNavigate, useParams} from "react-router-dom";
import { GET_PRODUCTS } from '~/graphql/queries/ProductQueries';
import {useTranslation} from "react-i18next";
import {IconPlus} from "@tabler/icons-react";

export default function CreateProduct(companyId) {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const [createProduct, { loading, data }] = useMutation(CREATE_PRODUCT);
  const newProductParams = { product: { name: "Untitled product", companyId: parseInt(id) }}
  const navigate = useNavigate();

  const handleCreateProduct = () => {
    createProduct(
      {
        variables: newProductParams,
        refetchQueries: [
          {
            query: GET_PRODUCTS,
            variables: { companyId: id },
          },
        ],
      })
  }

  if (data?.createProduct?.id) navigate(`/companies/${id}/product/${data.createProduct.id}/products`)

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Button
        variant="light"
        size="sm"
        radius="xl"
        leftIcon={<IconPlus />}
        onClick={handleCreateProduct}>
        {t('company.product.create_button')}
      </Button>
    </>
  );
}
