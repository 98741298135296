import React, { useState } from "react";
import {
  Card,
  Group,
  ScrollArea,
  Text,
  CopyButton,
  Tooltip,
  Space,
  Button,
} from "@mantine/core";
import useStyles from "../../AboutCompany.styles";
import RichTextEditor from "../../RichTextEditor/TextEditor";
import { getTextFromHTML } from "../../../../../utils/getTextFromHTML";
import SubmitButtons from "@/components/Companies/AboutCompany/InputCards/shared/SubmitButtons";
import {useTranslation} from "react-i18next";

export default function CompanyDescriptionMore({
  descriptionMore,
  updateCompany,
}) {
  const { t } = useTranslation(['translation']);
  const { classes } = useStyles();
  const [companyInfoLong, setCompanyInfoLong] = useState(descriptionMore || "");
  const [editMode, setEditMode] = useState(false);
  const label = t('company.description_label_more');
  const placeholder = t('company.description_placeholder_more');
  const saveCompanyChanges = () => {
    updateCompany({ descriptionMore: companyInfoLong });
    setEditMode(false);
  };
  const cancelChanges = () => {
    setCompanyInfoLong(descriptionMore || "");
    setEditMode(false);
  };

  return (
    <>
      <Card radius={8} my="md" className={classes.inputCard}>
        <Group position="apart" align="flex-start">
          <Text size={"sm"} weight={500}>
            {label}
          </Text>
          <Group>

              <CopyButton value={getTextFromHTML(descriptionMore)}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? t('company.copied_message') : t('company.copy_button')}
                    withArrow
                    position="right"
                    openDelay={300}
                  >
                    {copied ? (
                      <Text color="green" size={"xs"} weight={700}>
                        {t('company.copied_message')}
                      </Text>

                    ) : (
                      <Button
                        variant="subtle"
                        radius="xl"
                        size="xs"
                        compact
                        onClick={copy}
                      >
                        {t('company.copy_button')}
                      </Button>
                    )}
                  </Tooltip>
                )}
              </CopyButton>

            {!editMode &&
              <Button
                variant="subtle"
                radius="xl"
                size="xs"
                compact
                onClick={() => setEditMode(true)}
              >
                {t('edit_button')}
              </Button>}
          </Group>
        </Group>
        <Space h="md" />
        {!editMode && companyInfoLong ? (
          <ScrollArea>
            <div dangerouslySetInnerHTML={{ __html: companyInfoLong }} />
          </ScrollArea>
        ) : !editMode && !companyInfoLong ? (
          <Text className={classes.fakePlaceholder}>{placeholder}</Text>
        ) : (
          <div style={{ width: "95%" }}>
            <RichTextEditor
              content={companyInfoLong}
              onChange={(content) => setCompanyInfoLong(content)}
            />
          </div>
        )}
        { editMode && <SubmitButtons
          cancelChanges={cancelChanges}
          saveCompanyChanges={saveCompanyChanges}
        /> }
      </Card>
    </>
  );
}
