import { gql } from "@apollo/client";

export const GET_MEDIAS = gql`
query Medias($sortBy: String, $sort: String, $after: String, $search: String) {
  medias(sortBy: $sortBy, sort: $sort, after: $after, search: $search) {
    nodes {
      id
      name
      logoUrl
      description
    }
    pageInfo {
      hasNextPage
      startCursor
      endCursor
      hasPreviousPage
    }
  }
}
`;
