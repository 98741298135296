import React from "react";
import { Modal, Space, Group, Button, Anchor, Text } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { DELETE_ATTACHMENT } from "@/graphql/mutations/AttachmentMutations";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function DeleteAttachmentModal({
  opened,
  close,
  attachmentId,
  attachmentQuery,
}) {
  const { t } = useTranslation(['translation']);
  const [_deleteAttachment] = useMutation(DELETE_ATTACHMENT);
  const { id, folder_id } = useParams();

  const deleteAttachment = () => {
    _deleteAttachment({
      variables: {
        id: attachmentId,
      },
      refetchQueries: [
        {
          query: attachmentQuery,
          variables: { companyId: id, folderId: folder_id },
        },
      ],
    });
    close();
  };
  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        <Text align="center">
          {t('company.attachments.delete_confirm')}
        </Text>
        <Space h="xl" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>
            {t('cancel_button')}
          </Anchor>
          <Button onClick={deleteAttachment} color="red">
            {t('ok_button')}
          </Button>
        </Group>
      </Modal>
    </>
  );
}
