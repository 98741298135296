import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  label: {
    width: "100%",
    marginBottom: theme.spacing.xs,
  },
  input: {
    "&:disabled": {
      paddingLeft: 0,
      backgroundColor: "white",
      border: "none",
      color: "#000",
      fontSize: "1rem",
      opacity: "unset",
    },
  },
  logo: {
    padding: theme.spacing.xs,
    maxWidth: "200px",
  },
  inputCard: {
    overflow: "unset",
  },
  multiSelecInput: {
    paddingInline: 0,
    ":has(input:disabled)": {
      backgroundColor: "white !important",
      border: "none",
    },
  },
  calendarInput: {
    minWidth: "250px",
  },
  fakePlaceholder: {
    color: "#909296",
    opacity: 0.6,
    cursor: "not-allowed",
    pointerEvents: "none",
    fontSize: theme.fontSizes.sm,
  },
  mainContainer: {
    marginBottom: "50px",
    // marginTop: "20px",
  },
  onlyChildText: {
    display: 'none',
    color: "#909296",
    opacity: 0.6,
    cursor: "not-allowed",
    pointerEvents: "none",
    fontSize: theme.fontSizes.sm,
    '&:only-child': {
      display: 'block'
    }
  }
}));

export default useStyles;
