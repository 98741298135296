// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')
// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from '../components/App';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const currentUser = container.dataset.user ? JSON.parse(container.dataset.user) : null;
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

const links = [
  new createUploadLink({
    uri: '/graphql',
    headers: {
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
  }),
];

const cache = new InMemoryCache({
  typePolicies: {
    Journalist: {
      fields: {
        tags: {
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
          },
        },
      },
    },
  },
})

const client = new ApolloClient({
  link: ApolloLink.from(links),
  cache: cache,
});

root.render(
  <ApolloProvider client={client}>
    <App currentUser={currentUser}/>
  </ApolloProvider>
);