import React, { useState } from "react";
import Preview from "../Preview/Preview";
import {Card, Group, Select} from "@mantine/core";
import SubmitButtons from "@/components/Account/Inputs/SubmitButtons/SubmitButtons";
import classes from "../inputs.module.scss";
import { useTranslation } from "react-i18next";

export default function Language({ language, updateUser }) {
  const [userLanguage, setUserLanguage] = useState(language);
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation(['translation']);

  const saveChanges = () => {
    updateUser({ language: userLanguage });
    setEditMode(false);
  };

  const cancelChanges = () => {
    setUserLanguage(language || "");
    setEditMode(false);
  };

  return (
    <Card radius={8} my="md" className={classes.accountCard}>
      <Group position="apart" align="flex-start" spacing={0}>
        { !editMode && <Preview
          label={t('accounts.language_label')}
          value={t(`accounts.language_${language}`)}
          setEditMode={setEditMode}
          editButton={!editMode}
        /> }
      </Group>

      { editMode &&
        <Select
          label={t('accounts.language_label')}
          placeholder={t('accounts.pick_one_placeholder')}
          defaultValue={language}
          onChange={(value) => setUserLanguage(value)}
          data={[
            { value: 'en', label: t('accounts.language_en') },
            { value: 'cs', label: t('accounts.language_cs') },
          ]}
        /> }

      { editMode && <SubmitButtons
        cancelChanges={cancelChanges}
        saveChanges={saveChanges}
      /> }
    </Card>
  );
}