import React from 'react';
import {useParams} from "react-router-dom";
import PressReleaseForm from "@/components/PressReleases/PressReleaseForm";

export default function CompanyPressReleasesForm() {
  const { id } = useParams();

  return (
    <>
      <PressReleaseForm companyId={id} backUrl={`/companies/${id}/press_releases`}/>
    </>
  );
}