import { gql } from "@apollo/client";

export const GET_USER = gql`
query GetUser($id: ID!) {
  user(id: $id) {
    id
    name
    email
    userType
    status
    language
    phone
    avatarUrl
  }
}
`;

export const GET_CONTACTS_BY_COMPANY_UUID = gql`
query contactsByCompanyUuid($companyUuid: String!) {
  contactsByCompanyUuid(companyUuid: $companyUuid) {
    id
    name
    email
    phone
    avatarUrl
  }
}
`;
