import React, {useContext} from 'react';
import Name from './Inputs/Name/Name';
import Email from './Inputs/Email/Email';
import Language from './Inputs/Language/Language';
import Phone from './Inputs/Phone/Phone';
import Picture from './Inputs/Picture/Picture';
import {Container, LoadingOverlay} from "@mantine/core";
import { CurrentUser } from "~/contexts/CurrentUserContext";
import {useMutation, useQuery} from "@apollo/client";
import {GET_USER} from "@/graphql/queries/UserQueries";
import {UPDATE_USER} from "@/graphql/mutations/UserMutations";
import {useTranslation} from "react-i18next";

export default function Personal() {
  const { t } = useTranslation(['translation']);
  const {id, setCurrentUser} = useContext(CurrentUser);
  const { loading, error, data } = useQuery(GET_USER, { variables: { id: id } });
  let user = data?.user;
  const [updateUser] = useMutation(UPDATE_USER);

  const handleUpdateUser = (attributes) => {
    updateUser({
      variables: {
        user: {
          id: id,
          ...attributes,
        }
      },
    });
    setCurrentUser((currentUser) => ({
      ...currentUser,
      ...attributes
    }));
  }

  if (loading || !id) return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;

  return (
    <Container>
      <h1>{t("accounts.personal_details")}</h1>

      <Name
        name={user.name}
        updateUser={handleUpdateUser}
      />
      <Email email={user.email}/>
      <Language
        language={user.language}
        updateUser={handleUpdateUser}
      />
      <Picture
        picture={user.avatarUrl}
        updateUser={handleUpdateUser}
      />
      <Phone
        phone={user.phone}
        updateUser={handleUpdateUser}
      />
    </Container>
  );
}