import React, {} from 'react';

import { useParams } from "react-router-dom";
import {
  Paper,
  Avatar,
  Flex,
  Stack,
  Text,
  Anchor,
  Group
} from '@mantine/core';
import {useQuery} from '@apollo/client';
import { GET_JOURNALIST } from '~/graphql/queries/JournalistQueries';
import Loading from "@/components/Shared/LoadingOverlay";
import {useTranslation} from "react-i18next";
import classes from './Journalists.module.scss';

export default function JournalistShow() {
  const { id } = useParams();
  const { t } = useTranslation(['translation']);
  const { loading, data, error } = useQuery(GET_JOURNALIST, { variables: { id: id }, fetchPolicy: 'no-cache'});

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;

  return (
    <Paper p={30} radius="md" mt="xl" className={classes.header}>
      <Flex gap="md">
        <Group>
          <Avatar className={classes.avatar} src={data.journalist.user?.avatarUrl} radius={150} />
        </Group>
        <Flex direction="column" justify="center" gap="sm">
          <Flex gap="md" align="center">
            <Avatar className={classes.mobileAvatar} src={data.journalist.user?.avatarUrl} radius={150} />
            <Text size="xl" weight={800}>{data.journalist.name}</Text>
          </Flex>
          <Flex direction="column">
            <Anchor href={`mailto:${data.journalist.email}`}>{data.journalist.email}</Anchor>
            <Text>
              {data.journalist.description}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Paper>
  )
}
