import React, { useState } from "react";
import { IconEdit, IconCheck, IconX } from "@tabler/icons-react";
import {ActionIcon, Button, Card, Group, TextInput, Tooltip} from "@mantine/core";
import useStyles from "../../AboutCompany.styles";
import SubmitButtons from "@/components/Companies/AboutCompany/InputCards/shared/SubmitButtons";
import {useTranslation} from "react-i18next";

export default function CompanyOffices({ officeAddress, updateCompany }) {
  const { t } = useTranslation(['translation']);
  const { classes } = useStyles();
  const [companyOffices, setCompanyOffices] = useState(officeAddress || "");
  const [editMode, setEditMode] = useState(false);
  const saveCompanyChanges = () => {
    updateCompany({ officeAddress: companyOffices });
    setEditMode(false);
  };
  const cancelChanges = () => {
    setCompanyOffices(officeAddress || "");
    setEditMode(false);
  };
  return (
    <>
      <Card radius={8} my="md" className={classes.inputCard}>
        <Group position="apart" align="flex-start" spacing={0}>
          <TextInput
            label={t('company.offices_label')}
            placeholder={t('company.offices_placeholder')}
            value={companyOffices}
            onChange={(event) => setCompanyOffices(event.currentTarget.value)}
            disabled={!editMode}
            w={"90%"}
            classNames={{
              label: classes.label,
              input: classes.input,
            }}
          />
          {!editMode &&
            <Button
              variant="subtle"
              radius="xl"
              size="xs"
              compact
              onClick={() => setEditMode(true)}
            >
              {t('edit_button')}
            </Button>}
        </Group>

        { editMode && <SubmitButtons
          cancelChanges={cancelChanges}
          saveCompanyChanges={saveCompanyChanges}
        /> }
      </Card>
    </>
  );
}
