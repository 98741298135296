import React, { useContext } from "react";
import classes from "./Notifications.module.scss";
import { Avatar, Flex, Menu, Text, Indicator } from '@mantine/core';
import {useMutation} from "@apollo/client";
import { MARK_NOTIFICATION_AS_READ } from "~/graphql/mutations/NotificationMutations";

export default function Notification({ notification }) {
  const [_markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ);
  const markNotificationAsRead = () => {
    _markNotificationAsRead({
      variables: { id: notification.id }
    });
  };

  function timeSince() {
    const date = new Date(notification.createdAt);
    const now = new Date();

    const seconds = Math.floor((now - date) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval > 0) {
      return interval + "y";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 0) {
      return interval + "mo";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 0) {
      return interval + "d";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 0) {
      return interval + "h";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 0) {
      return interval + "min";
    }
    return Math.floor(seconds) + "s";
  }

  const followLink = () => {
    markNotificationAsRead()
    window.location.assign(notification.link)
  }

  return(
    <Menu.Item radius="0" rightSection={timeSince()} onClick={followLink} bg={notification.readAt ? '' : 'gray.1'}>
      <Flex gap="md" align="center" mr="md">
        <Indicator disabled={notification.readAt} position="top-start">
          <Avatar fit="contain" radius="xl" src={notification.logoUrl}/>
        </Indicator>
        <Text>{notification.content}</Text>
      </Flex>
    </Menu.Item>
  )
}
