import { gql } from "@apollo/client";

export const CREATE_FOLDER = gql`
mutation CreateFolder($folder: FolderInput!) {
  createFolder(input: {folder: $folder}) {
    id
    name
  }
}
`;

export const UPDATE_FOLDER = gql`
mutation UpdateFolder($folder: FolderInput!) {
  updateFolder(input: {folder: $folder}) {
    id
    name
  }
}
`;

export const DELETE_FOLDER = gql`
  mutation DeleteFolder($id: ID!) {
    deleteFolder(input: {id: $id}) {
      success
    }
  }
`;
