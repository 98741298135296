import React from 'react';
import {SimpleGrid, Space, Grid, LoadingOverlay} from '@mantine/core';
import ServiceCard from './ServiceCard';
import { useQuery } from '@apollo/client';
import { GET_SERVICES } from '~/graphql/queries/ServiceQueries';
import {useParams} from "react-router-dom";
import CreateService  from "@/components/Companies/Services/CreateService";
import {useTranslation} from "react-i18next";

export default function Services() {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_SERVICES, { variables: { companyId: id } });

  if (loading) return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <Grid justify="space-between" align="center">
        <Grid.Col span="content"><h2>{t('company.services.header')}</h2></Grid.Col>
        <Grid.Col span="content">
          <CreateService />
        </Grid.Col>
      </Grid>
      <Space h="md" />
      <SimpleGrid cols={4}>
        {data.services.map((service) => (
          <ServiceCard key={service.id} service={service} />
        ))}
      </SimpleGrid>
    </>
  );
}