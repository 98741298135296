import React from "react";
import {
  Card,
  Text,
  Menu,
  ActionIcon,
  Image,
  rem,
  Space,
  Tooltip,
  Badge,
  Grid,
  Divider,
} from "@mantine/core";
import { IconDotsVertical, IconEye, IconTrash } from "@tabler/icons-react";
import noImage from "../mocks/no-image.png";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import DeleteCompanyModal from "./DeleteCompanyModal";
import classes from "./CompanyCard.module.scss";
import ShortInformation from "@/components/Companies/SordInformation/ShortInformation";
import {useTranslation} from "react-i18next";

export default function CompanyCard({ company, setCompanies }) {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const [isDeleteModalOpened, deleteModalHandlers] = useDisclosure(false);

  const handlePreview = () => {
    navigate(`/companies/${company.id}/about`);
  };

  function getBadge() {
    if (company?.status === "draft") {
      return <Badge color="gray" size="xs">{t('badges.draft')}</Badge>;
    } else {
      return <Badge color="green" size="xs">{t('badges.published')}</Badge>;
    }
  }

  return (
    <>
      <Tooltip label={company.name} openDelay={500}>
        <Card radius="md" withBorder>

          <Grid>
            <Grid.Col span={10}>
              {getBadge()}
            </Grid.Col>
            <Grid.Col span={2}>
              <Menu withinPortal position="bottom-end" shadow="sm">
                <Menu.Target>
                  <ActionIcon>
                    <IconDotsVertical size="1rem" />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    icon={<IconEye size={rem(14)} />}
                    onClick={handlePreview}
                  >
                    {t('company.product.preview_button')}
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconTrash size={rem(14)} />}
                    color="red"
                    onClick={deleteModalHandlers.open}
                  >
                    {t('delete_button')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Grid.Col>
          </Grid>
          <div style={{ cursor: "pointer" }} onClick={handlePreview}>
            <Card.Section inheritPadding>
              <Image
                className={classes.image}
                src={company.logoUrl || noImage}
                height={60}
                fit="contain"
              />
            </Card.Section>
            <Divider my="sm" className={classes.divider}/>
            <Card.Section inheritPadding>
              <h3>
                <Text truncate={true} >{company.name}</Text>
              </h3>
            </Card.Section>

            <div className={classes.shortInformationContainer}>
              <ShortInformation tags={company.tags} textSize={"xs"} />
            </div>
            <div style={{ minHeight: 130 }} >
              <Text mt="xs" size="xs" lineClamp={7}>
                {company.tagline || t('company.no_tagline_text')}
              </Text>
            </div>
            <Space h="xs" />
          </div>
        </Card>
      </Tooltip>
      <DeleteCompanyModal
        opened={isDeleteModalOpened}
        close={deleteModalHandlers.close}
        companyId={company.id}
        setCompanies={setCompanies}
      />
    </>
  );
}
