import React, { useState } from "react";
import { Card, Group, TextInput} from "@mantine/core";
import Preview from "@/components/Account/Inputs/Preview/Preview";
import SubmitButtons from "../SubmitButtons/SubmitButtons";
import classes from "../inputs.module.scss";
import {useTranslation} from "react-i18next";
export default function Phone({ phone, updateUser }) {
  const { t } = useTranslation(['translation']);
  const [userPhone, setUserPhone] = useState(phone || "");
  const [editMode, setEditMode] = useState(false);
  const saveChanges = () => {
    updateUser({ phone: userPhone });
    setEditMode(false);
  };
  const cancelChanges = () => {
    setUserPhone(phone || "");
    setEditMode(false);
  };
  return (
    <>
      <Card radius={8} my="md">
        <Group position="apart" align="flex-start" spacing={0}>
          { !editMode && <Preview
            label={t('accounts.phone_label')}
            value={phone}
            setEditMode={setEditMode}
            editButton={!editMode}
          /> }
        </Group>

        { editMode &&
          <TextInput
            label={t('accounts.phone_label')}
            placeholder={t('accounts.phone_placeholder')}
            value={userPhone}
            onChange={(event) => setUserPhone(event.currentTarget.value)}
            disabled={!editMode}
            className={ classes.inputLabel}
          /> }

        { editMode && <SubmitButtons
          cancelChanges={cancelChanges}
          saveChanges={saveChanges}
        /> }
      </Card>
    </>
  );
}