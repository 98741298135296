import React from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_MEDIA } from '~/graphql/mutations/MediasMutations'
import { Button } from '@mantine/core'
import {useTranslation} from "react-i18next";

export default function UpdateMedia({ submit, id, name, logo, description, setMedias }) {
  const [updateMedia] = useMutation(UPDATE_MEDIA)
  const { t } = useTranslation(['translation']);

  function handleUpdateMedia() {
    updateMedia({
      variables: {
        media: {
          id: id,
          name: name,
          logo: logo,
          description: description,
        },
      },
      update(cache, { data: { updateMedia } }) {
        setMedias((medias) => {
          const index = medias.findIndex((media) => media.id === updateMedia.id)
          medias[index] = updateMedia
          return [...medias]
        })
      }
    })
    submit()
  }

  return <Button onClick={handleUpdateMedia}>{t('ok_button')}</Button>
}
