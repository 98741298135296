import React, {useState} from 'react';
import {Modal, Group, Space, Anchor, Input, LoadingOverlay, MultiSelect, Textarea} from '@mantine/core';
import {useQuery} from "@apollo/client";
import {GET_TAGS} from "@/graphql/queries/TagQueries";
import UpdateJournalist from './UpdateJournalist'
import MediaFieldAutocomplete from "@/components/Journalists/MediaFieldAutocomplete";
import {useTranslation} from "react-i18next";

export default function EditJournalistModal({ opened, close, journalist, setJournalists }) {
  const { t } = useTranslation(['translation']);
  const [name, setName] = useState(journalist.name || "");
  const [email, setEmail] = useState(journalist.email || "");
  const [description, setDescription] = useState(journalist.description || "");
  const [mediaId, setMediaId] = useState(journalist.media?.id || "");
  const [tagIds, setTagIds] = useState(journalist.tags.map((tag) => tag.id.toString()) || []);
  const { loading, error, data } = useQuery(GET_TAGS);
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)

  if (loading) return <LoadingOverlay visible={true} overlayBlur={2} />
  const availableTags = data.tags.map((tag) => {
    return { label: tag.name, value: tag.id };
  });

  const submit = () => {
    if(name.length === 0) {
      setNameError(true)
    } else if (email.length === 0 || !(/^\S+@\S+$/.test(email))) {
      setEmailError(true)
    } else {
      close()
    }
  }

  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        <Input.Wrapper label={t('journalists.name_label')} required >
          <Input
            placeholder={t('journalists.name_placeholder')}
            value={name}
            error={nameError}
            onChange={(e) => {
              setName(e.target.value)
              if (name.length > 0) { setNameError(false) }
            }}
          />
        </Input.Wrapper>
        <Space h="lg" />
        <Input.Wrapper label={t('journalists.email_label')} required >
          <Input
            placeholder={t('journalists.email_placeholder')}
            value={email}
            error={emailError}
            onChange={(e) => {
              setEmail(e.target.value)
              if (email.length > 0) { setEmailError(false) }
            }}
          />
        </Input.Wrapper>
        <Space h="lg" />
        <Input.Wrapper label={t('journalists.description_label')} >
          <Textarea
            placeholder={t('journalists.description_placeholder')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Input.Wrapper>
        <Space h="lg" />
        <Input.Wrapper label={t('journalists.tags_label')}>
          <MultiSelect
            onChange={(value) => setTagIds(value)}
            value={tagIds}
            data={availableTags}
            placeholder={t('journalists.tags_placeholder')}
          />
        </Input.Wrapper>
        <Space h="lg" />
        <MediaFieldAutocomplete mediaId={mediaId} setMediaId={setMediaId} />
        <Space h="lg" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>{t('cancel_button')}</Anchor>
          <UpdateJournalist
            submit={submit}
            id={journalist.id}
            name={name}
            email={email}
            description={description}
            tagIds={tagIds}
            mediaId={mediaId}
            setJournalists={setJournalists}
          />
        </Group>
        <LoadingOverlay visible={loading} overlayBlur={2} />
      </Modal>
    </>
  );
}
