import React from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_FUNDING } from '~/graphql/mutations/FundingMutations'
import { Button } from '@mantine/core'
import {useTranslation} from "react-i18next";

export default function CreateFunding({ submit, name, date, numberOfInvestors, moneyRaised, leadInvestors, companyId, loadDefault, setFundings }) {
  const { t } = useTranslation(['translation']);
  const [_createFunding] = useMutation(CREATE_FUNDING)

  function handleCreateFunding() {
    _createFunding({
      variables: {
        funding: {
          companyId: companyId,
          name: name,
          date: date,
          numberOfInvestors: numberOfInvestors,
          moneyRaised: moneyRaised,
          leadInvestors: leadInvestors,
        },
      },
      update(_observableQuery, { data: { createFunding } }) {
        loadDefault()
        setFundings((fundings) => {
          return [...fundings, createFunding];
        })
      },
    })
    submit()
  }

  return <Button onClick={handleCreateFunding}>{t('ok_button')}</Button>
}
