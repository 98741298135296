import React, {useEffect} from 'react';
import { Switch } from '@mantine/core';
import {useTranslation} from "react-i18next";

export default function PublicSwitch({onChange, value, readOnly, setIsPublic}) {
  const { t } = useTranslation(['translation']);
  useEffect(() => {
    setIsPublic(value);
  },[])

  function handleChange(e) {
    setIsPublic(e.target.checked);
    onChange(e.target.checked);
  }

  return (
    <Switch
      disabled={readOnly}
      label={t("make_press_release_public_label")}
      size="md"
      defaultChecked={value}
      onChange={handleChange}
    />
  );
}