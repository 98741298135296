import React from 'react';
import {Button} from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import SelectKeyPeopleModal from "./SelectKeyPeopleModal";
import {useTranslation} from "react-i18next";

export default function SelectKeyPeopleButton({keyPeople, updateCompany, setDefaultKeyPeople}) {
  const { t } = useTranslation(['translation']);
  const [opened, {open, close}] = useDisclosure(false);
  return (
    <>
      <SelectKeyPeopleModal opened={opened} close={close} keyPeople={keyPeople} updateCompany={updateCompany} setDefaultKeyPeople={setDefaultKeyPeople}/>
      <Button
        variant="subtle"
        radius="xl"
        size="xs"
        compact
        onClick={open}
      >
        {t('edit_button')}
      </Button>
    </>
  );
}