import { gql } from "@apollo/client";

export const GET_DOCUMENTS = gql`
  query Documents($companyId: ID!, $folderId: ID) {
    documents(companyId: $companyId, folderId: $folderId) {
      __typename
      id
      name
      type
      url
      folder {
        name
      }
    }
  }
`;

export const GET_IMAGES = gql`
  query Images($companyId: ID!, $folderId: ID) {
    images(companyId: $companyId, folderId: $folderId) {
      __typename
      id
      name
      type
      url
      folder {
        name
      }
    }
  }
`;

export const GET_VIDEOS = gql`
  query Videos($companyId: ID!, $folderId: ID) {
    videos(companyId: $companyId, folderId: $folderId) {
      __typename
      id
      name
      type
      url
      folder {
        name
      }
    }
  }
`;

export const GET_ATTACHMENTS = gql`
  query GetAttachments($companyId: ID!, $folderId: ID) {
  getAttachments(companyId: $companyId, folderId: $folderId) {
    ... on Document {
      __typename
      id
      name
      type
      url
      folder {
        name
      }
    }
    ... on Image {
      __typename
      id
      name
      type
      url
      folder {
        name
      }
    }
    ... on Video {
      __typename
      id
      name
      type
      url
      folder {
        name
      }
    }
  }
}
`;

export const GET_IMAGES_BY_COMPANY_UUID = gql`
  query ImagesByCompanyUuid($companyUuid: String!) {
    imagesByCompanyUuid(companyUuid: $companyUuid) {
      __typename
      id
      name
      type
      url
    }
  }
`;

export const GET_VIDEOS_BY_COMPANY_UUID = gql`
  query VideosByCompanyUuid($companyUuid: String!) {
    videosByCompanyUuid(companyUuid: $companyUuid) {
      __typename
      id
      name
      type
      url
    }
  }
`;

export const GET_DOCUMENTS_BY_COMPANY_UUID = gql`
  query DocumentsByCompanyUuid($companyUuid: String!) {
    documentsByCompanyUuid(companyUuid: $companyUuid) {
      __typename
      id
      name
      type
      url
    }
  }
`;