import React, { useState, useEffect } from 'react';
import Form from "@/components/PressReleases/Form";
import {FooterBar} from "@/components/PressReleases/FooterBar";
import {Container, Grid, Anchor, Text} from '@mantine/core';
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {UPDATE_PRESS_RELEASE} from "@/graphql/mutations/PressReleaseMutations";
import {GET_PRESS_RELEASE} from "@/graphql/queries/PressReleaseQueries";
import {debounce} from "lodash";
import {useTranslation} from "react-i18next";

export default function NewPressReleaseForm({companyId, backUrl}) {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const [isCompleted, setIsCompleted] = useState(false);
  const [updatePressRelease, { loading }] = useMutation(UPDATE_PRESS_RELEASE);
  const [typing, setTyping] = useState(false);
  const { press_release_id } = useParams();
  const [params, setParams] = useState({});
  const onChange = (attrs) => {
    updatePressReleaseHandler(attrs);
  }
  const pressReleaseQuery = useQuery(GET_PRESS_RELEASE, {
    variables: { id: press_release_id }
  });
  const debouncedOnChange = debounce(onChange, 1000);

  function isPublished() {
    return pressReleaseQuery?.data?.pressRelease?.status === "published";
  }

  useEffect(() => {
    if (pressReleaseQuery?.data?.pressRelease) {
      setParams({
          emailNotification: pressReleaseQuery.data.pressRelease.emailNotification,
          isPublic: pressReleaseQuery.data.pressRelease.isPublic,
          name: pressReleaseQuery.data.pressRelease.name,
          tagIds: pressReleaseQuery.data.pressRelease.tags.map((tag) => tag.id),
          companyId: pressReleaseQuery.data.pressRelease.companyId,
          message: pressReleaseQuery.data.pressRelease.message,
          subject: pressReleaseQuery.data.pressRelease.subject,
          pressReleaseFiles: pressReleaseQuery.data.pressRelease.pressReleaseFiles,
          picture: pressReleaseQuery.data.pressRelease.pictureFile,
        }
      );
    }
  }, [pressReleaseQuery])

  function updatePressReleaseHandler(pr) {
    setParams((params) => ({ ...params, ...pr }));

    updateWithRefetch(pr)
    // if (pr.pressReleaseFiles || pr.pictureFile) {
    //   updateWithRefetch(pr)
    // } else {
    //   updatePressRelease({
    //     variables: {
    //       pressRelease: {
    //         id: press_release_id,
    //         ...pr
    //       }
    //     }
    //   })
    // }
    setTyping(false);
  }

  function updateWithRefetch(pr) {
    updatePressRelease({
      variables: {
        pressRelease: {
          id: press_release_id,
          ...pr
        }
      },
      refetchQueries: [
        {
          query: GET_PRESS_RELEASE,
          variables: { id: press_release_id }
        }
      ]
    })
  }

  function savingState() {
    if (pressReleaseQuery.loading ) {
      return "";
    }
    if (isCompleted || isPublished()) {
      return t('press_releases.published_message');
    }
    if (loading || typing) {
      return t('saving_text');
    } else {
      return t('saved_text');
    }
  }

  return (
    <>
      <Container my={30}>
        <Grid justify="space-between" align="center">
          <Grid.Col span="content">
            <Anchor
              underline={false}
              onClick={() => navigate(companyId ? `/companies/${companyId}/press_releases` : `/press_releases`) }
            >
              {`${t('press_releases.back_to_all')}`}
            </Anchor>
          </Grid.Col>
          <Grid.Col span="content">
            <Text c="dimmed">{savingState()}</Text>
          </Grid.Col>
        </Grid>
        <Form
          isCompleted={isCompleted}
          debouncedOnChange={debouncedOnChange}
          setTyping={setTyping}
          typing={typing}
          isPublished={isPublished()}
          companyId={companyId}
        />

      </Container>
      <FooterBar
        params={params}
        backUrl={backUrl || `/press_releases`}
        setIsCompleted={setIsCompleted}
        isCompleted={isCompleted}
        isPublished={isPublished()}
        companyId={companyId}
      />
    </>
  );
}