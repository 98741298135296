import React, { useState } from "react";
import {
  Card,
  Group, Space,
  Text,
} from "@mantine/core";
import useStyles from "../../AboutCompany.styles";
import SelectKeyPeopleButton from "./SelectKeyPeopleButton";
import MemberCard from "./MemberCard";
import {useTranslation} from "react-i18next";

export default function CompanyPeople({keyPeople, updateCompany}) {
  const { t } = useTranslation(['translation']);
  const { classes } = useStyles();
  const label = t('company.key_people_label');
  const [defaultKeyPeople, setDefaultKeyPeople] = useState(keyPeople);

  return (
    <>
      <Card radius={8} my="md" className={classes.inputCard}>
        <Group position="apart" align="flex-start" spacing={0}>
          <Text size={"sm"} weight={500}>
            {label}
          </Text>
          <SelectKeyPeopleButton keyPeople={keyPeople} updateCompany={updateCompany} setDefaultKeyPeople={setDefaultKeyPeople}/>
        </Group>
        <Space h="md" />

        {
          defaultKeyPeople.length === 0 && <Text color="dimmed">{t("company.key_people_placeholder")}</Text>
        }

        <Group position="" align="flex-start" spacing={0}>
          { defaultKeyPeople.map((member) => (
            <MemberCard
              key={member.id}
              avatar={member.avatarUrl}
              name={member.name}
              title={member.role}
            />
          ))}
        </Group>
      </Card>
    </>
  );
}
