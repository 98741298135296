import React, { useState, useEffect } from 'react';
import {Container, LoadingOverlay, Table, Group} from "@mantine/core";
import {useQuery} from "@apollo/client";
import {GET_CURRENT_ACCOUNT} from "@/graphql/queries/AccountQueries";
import {useTranslation} from "react-i18next";
import Teammate from './Teammates/Teammate';
import NewTeammateButton from "@/components/Account/Teammates/NewTeammateButton";

export default function Account() {
  const { t } = useTranslation(['translation']);

  const { loading, error, data } = useQuery(GET_CURRENT_ACCOUNT, { variables: {} });

  if (loading) return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (error) return <p>{t('error')}</p>;

  // gets the unique account invitations
  const account_invitations = data.getCurrentAccount.accountInvitations
    .filter((v,i,a)=>a.findIndex(t=>(t.email === v.email))===i)

  return (
    <Container>
      <h1>{t("accounts.account_header")}</h1>


      <Group position="right">
        <NewTeammateButton />
      </Group>
      <Table>
        <thead>
        <tr>
          <th>{t('accounts.teammates.email')}</th>
          <th>{t('accounts.teammates.status')}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>{account_invitations.map((teammate) => (
          <Teammate key={teammate.id} teammate={teammate} />
        ))}</tbody>
      </Table>

    </Container>
  );
}

