import React, {useState, useEffect} from 'react';
import {
  Paper,
  Input,
  Select,
  Space,
  LoadingOverlay,
} from '@mantine/core';
import {useQuery} from "@apollo/client";
import {GET_ALL_COMPANIES} from "@/graphql/queries/CompanyQueries";
import CompletedStep from "@/components/PressReleases/CompletedStep";
import {GET_TAGS} from "@/graphql/queries/TagQueries";
import {GET_PRESS_RELEASE} from "@/graphql/queries/PressReleaseQueries";
import {useParams} from "react-router-dom";
import FilesInput from './inputs/FilesInput';
import PictureInput from './inputs/PictureInput';
import Tags from './inputs/Tags'
import PublicSwitch from './inputs/PublicSwitch'
import BodyEditor from "./inputs/BodyEditor";
import MessageEditor from "./inputs/MessageEditor";
import classes from './Form.module.scss';
import {useTranslation} from "react-i18next";
import SendNotificationSwitch from './inputs/SendNotificationSwitch';

export default function Form({ companyId, isCompleted, debouncedOnChange, setTyping, typing, isPublished }) {
  const { t } = useTranslation(['translation']);
  const companiesQuery = useQuery(GET_ALL_COMPANIES);
  const tagsQuery = useQuery(GET_TAGS);
  const { press_release_id } = useParams();
  const pressReleaseQuery = useQuery(GET_PRESS_RELEASE, {
    variables: { id: press_release_id }
  });
  const [tagIds, setTagIds] = useState(null)
  const [isPublic, setIsPublic] = useState(null)
  const [emailNotification, setEmailNotification] = useState(null)

  if (companiesQuery.loading || tagsQuery.loading || pressReleaseQuery.loading) return <LoadingOverlay visible={true} overlayBlur={2} />;
  if (companiesQuery.error || tagsQuery.error || pressReleaseQuery.error) return <p>{t('error')}</p>;
  const availableTags = tagsQuery.data.tags.map((tag) => {
    return { label: tag.name, value: tag.id };
  });
  const companyData = companiesQuery.data.allCompanies.map((company) => ({
    value: company.id,
    label: company.name,
  }));

  function companyChanged(companyId) {
    setTyping(true);
    const company = companiesQuery.data.allCompanies.find((company) => company.id === companyId);
    const tagIds = company.tags.map((tag) => tag.id);
    setTagIds(tagIds);
    debouncedOnChange({ companyId: parseInt(companyId), tagIds: tagIds })
  }

  if (isCompleted) {
    return (
      <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
        <CompletedStep />
      </Paper>
    )
  }

  return (
    <div className={classes.preReleaseFormBase}>
      <Paper  shadow="xs" p={30} radius="md" mt="xl">
        <Select
          placeholder={t('press_releases.company_placeholder')}
          variant={ "filled" }
          label={t('press_releases.company_label')}
          size="md"
          defaultValue={pressReleaseQuery.data.pressRelease.company?.id}
          searchable
          disabled={!!companyId && !isPublished }
          readOnly={isPublished}
          onChange={(value) => {
            companyChanged(value);
          }}
          data={companyData}
        />
      </Paper>
      <Paper  shadow="xs" p={30} radius="md" mt="xl">
        <Tags
          pressReleaseQuery={pressReleaseQuery}
          isPublished={isPublished}
          tagIds={tagIds}
          setTyping={setTyping}
          setTagIds={setTagIds}
          debouncedOnChange={debouncedOnChange}
          availableTags={availableTags}
          />
      </Paper>

      <Paper  shadow="xs" p={30} radius="md" mt="xl">
        <PublicSwitch
          value={pressReleaseQuery.data.pressRelease.isPublic}
          onChange={(checked) => {
            setTyping(true);
            debouncedOnChange({ isPublic: checked })
          }}
          setIsPublic={setIsPublic}
        />
        {isPublic &&
          <div>
            <Space h={30} />

            <Input.Wrapper
              label={t('press_releases.headline_label')}
              size="md"
            >
              <Input
                variant={ "filled" }
                size="md"
                placeholder={t('press_releases.headline_placeholder')}
                onKeyDown={() => setTyping(true)}
                defaultValue={pressReleaseQuery.data.pressRelease.name}
                onChange={(e) => {
                  setTyping(true);
                  debouncedOnChange({ name: e.target.value })
                }}
              />
            </Input.Wrapper>
            <Space h={30} />
            <BodyEditor
              content={pressReleaseQuery.data.pressRelease.bodyHtml}
              onChange={(value) => {
                setTyping(true);
                debouncedOnChange({ bodyHtml: value })
              }}
            />
              <Space h={30} />
              <PictureInput
                  pressRelease={ pressReleaseQuery.data.pressRelease }
                  debouncedOnChange={debouncedOnChange}
                  setTyping={setTyping}
                  isPublished={isPublished}
                  typing={typing}
              />
          </div>
        }
      </Paper>

      <Paper  shadow="xs" p={30} radius="md" mt="xl" mb={150}>
        <SendNotificationSwitch
          value={pressReleaseQuery.data.pressRelease.emailNotification}
          onChange={(checked) => {
            setTyping(true);
            debouncedOnChange({ emailNotification: checked })
          }}
          setEmailNotification={setEmailNotification}
        />
        { emailNotification &&
          <div>
            <Space h={30} />
            <Input.Wrapper
              label={t('press_releases.subject_label')}
              size="md"
            >
              <Input
                variant={ "filled" }
                readOnly={isPublished}
                size="md"
                placeholder={t('press_releases.subject_placeholder')}
                onKeyDown={() => setTyping(true)}
                defaultValue={pressReleaseQuery.data.pressRelease.subject}
                onChange={(e) => {
                  setTyping(true);
                  debouncedOnChange({ subject: e.target.value })
                }}
              />
            </Input.Wrapper>
            <Space h={30} />
            <MessageEditor
              emailNotification={emailNotification}
              message={pressReleaseQuery.data.pressRelease.message}
              isPublished={isPublished}
              onChange={(value) => {
                setTyping(true);
                debouncedOnChange({ message: value })
              }}
            />
            <Space h={30} />
            <FilesInput
              pressRelease={ pressReleaseQuery.data.pressRelease }
              debouncedOnChange={debouncedOnChange}
              setTyping={setTyping}
              isPublished={isPublished}
              typing={typing}
            />
          </div>
        }
      </Paper>
    </div>
  );
}
