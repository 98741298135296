import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import {
  PasswordInput,
  Text,
  Paper,
  Group,
  Button,
  Space,
  Anchor,
  Stack,
  Container,
  Alert,
} from '@mantine/core';
import { changePassword } from "@/utils/login";
import { IconAlertCircle } from '@tabler/icons-react';
import { useSearchParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function ChangePassword(props) {
  const { t } = useTranslation(['translation']);
  const form = useForm({
    initialValues: { 
        password: '',
        passwordConfirmation: ''
    },
    validate: {
      password: (val) => (val.length <= 6 ? t("auth.password_min_error") : null),
      passwordConfirmation: (val) => {
        const password = form.values.password
        return val !== password ? t("auth.password_match_error") : null
      }
    },
  });

  const [searchParams, setSearchParams] = useSearchParams()
  const [error, setError] = useState("");

  const handleSubmit = (values) => {
    changePassword({
      password: values.password,
      password_confirmation: values.passwordConfirmation,
      reset_password_token: searchParams.get("reset_password_token")
    }, setError);
  }

  return (
    <Container size="30rem" px={0} mt={"100px"}>
      <Paper radius="md" p="xl" withBorder  {...props}>
        <Text size="lg" weight={500}>
          {t("auth.change_password_header")}
        </Text>

        { error &&
          <Alert icon={<IconAlertCircle size="1rem" />} color="red">
            {error}
          </Alert>
        }

        <Space h="md" />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Stack>

            <PasswordInput
              label={t("auth.new_password_label")}
              placeholder={t("auth.password_placeholder")}
              mt="md"
              size="md"
              onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
              value={form.values.password}
              error={form.errors.password && t("auth.password_min_error")}
            />
            <PasswordInput
              label={t("auth.confirm_password_label")}
              placeholder={t("auth.confirm_password_placeholder")}
              mt="md"
              size="md"
              onChange={(event) => form.setFieldValue('passwordConfirmation', event.currentTarget.value)}
              value={form.values.passwordConfirmation}
              error={form.errors.passwordConfirmation && t("auth.password_match_error")}
            />

          </Stack>

          <Group position="apart" mt="xl">
            <Group>
              <Anchor
                component="button"
                type="button"
                color="dimmed"
                onClick={() => window.location.assign("/sign_in")}
                size="xs"
              >
                {t("auth.login_button")}
              </Anchor>
              <Anchor
                component="button"
                type="button"
                color="dimmed"
                onClick={() => window.location.assign("/sign_up")}
                size="xs"
              >
                {t("auth.register_button")}
              </Anchor>
            </Group>
            <Button type="submit" radius="xl">
              {t("auth.change_password_button")}
            </Button>
          </Group>
        </form>
      </Paper>
    </Container>
  );
}
