import React, {useState} from 'react';
import {Group, Text} from "@mantine/core";
import classes from "./ShortInformation.module.scss";
import {useTranslation} from "react-i18next";

export default function ShortInformation({employeesCount, tags, businessType, officeAddress, textSize}) {
  const { t } = useTranslation(['translation']);

  const [size,] = useState(textSize || "sm");
  function employeesNumber() {
    if (employeesCount < 10) {
      return `1-10 ${t('company.employees')}`;
    } else if (employeesCount < 50) {
      return `11-50 ${t('company.employees')}`;
    } else if (employeesCount < 100) {
      return `51-100 ${t('company.employees')}`;
    } else if (employeesCount < 200) {
      return `101-200 ${t('company.employees')}`;
    } else if (employeesCount < 500) {
      return `201-500 ${t('company.employees')}`;
    } else if (employeesCount < 1000) {
      return `501-1000 ${t('company.employees')}`;
    } else if (employeesCount < 2000) {
      return `1001-2000 ${t('company.employees')}`;
    } else if (employeesCount < 5000) {
      return `2001-5000 ${t('company.employees')}`;
    } else {
      return `5000+ ${t('company.employees')}`;
    }
  }

  return (
    <>
      <Group>
        { tags && <Text c="dimmed" size={size}>
          {tags.map((tag) => '#' + tag.name).join(", ")}
        </Text> }
        { businessType && <Text c="dimmed" className={classes.dotAfter} size={size}>
          {businessType}
        </Text> }
        { officeAddress && <Text c="dimmed" className={classes.dotAfter} size={size}>
          {officeAddress}
        </Text> }

        { employeesCount && <Text c="dimmed" className={classes.dotAfter} size={size}>
          {employeesNumber()}
        </Text> }
      </Group>
    </>
  );
}