import React from 'react';
import {
  UnstyledButton,
  Group,
  Avatar,
  Text,
  createStyles,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  user: {
    display: 'block',
    width: '100%',
    padding: theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    },
  },
}));
export default function MemberCard({ avatar, name, title }) {
  const { classes } = useStyles();
  return (
    <div>
      <UnstyledButton className={classes.user} >
        <Group>
          <Avatar src={avatar} radius="xl" />

          <div style={{ flex: 1 }}>
            <Text size="sm" weight={500}>
              {name}
            </Text>

            <Text color="dimmed" size="xs">
              {title}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    </div>
  );
}