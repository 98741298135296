import React from 'react';
import {Button, LoadingOverlay} from '@mantine/core';
import { useMutation } from '@apollo/client';
import { CREATE_COMPANY } from '~/graphql/queries/CompanyQueries';
import {useNavigate} from "react-router-dom";
import {IconPlus} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import classes from "./CreateCompanyButton.module.scss";

export default function CreateCompanyButton() {
  const [createCompany, { loading, data }] = useMutation(CREATE_COMPANY);
  const newCompanyParams = { company: { name: "Untitled company" }}
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);

  const handleCreateCompany = () => {
    createCompany({variables: newCompanyParams})
  }

  if (data) navigate(`/companies/${data.createCompany.id}/about`)

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Button
        className={classes.createCompanyButton}
        variant="light"
        size="sm"
        radius="xl"
        leftIcon={<IconPlus />}
        onClick={handleCreateCompany}>
        {t('companies.create_button')}
      </Button>
    </>
  );
}
