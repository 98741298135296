import React, {useState} from 'react';
import {Avatar, Modal, SimpleGrid, Group, Space, Anchor, Input, Textarea, Text} from '@mantine/core';
import UpdateMedia from './UpdateMedia'
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import {useTranslation} from "react-i18next";

export default function EditMediaModal({ opened, close, media, setMedias }) {
  const { t } = useTranslation(['translation']);
  const [name, setName] = useState(media.name || "");
  const [logo, setLogo] = useState(null);
  const [description, setDescription] = useState(media.description || "");

  function uploadImage(files) {
    setLogo(files[0])
  }

  const preview = () => {
    const imageUrl = logo ? URL.createObjectURL(logo) : media?.logoUrl
    return (
      <Avatar
        src={imageUrl}
        size={80}
        radius={80}
      />
    );
  }

  const submit = () => {
    close()
    setLogo(null)
  }

  return (
    <>
      <Modal opened={opened} onClose={close} centered>
        {(media?.logoUrl || logo) && <Group position="center">{preview()}</Group>}
        <Space h="lg" />
        <Dropzone
          accept={IMAGE_MIME_TYPE}
          onDrop={(files) => { uploadImage(files); }}
          multiple={false}
        >
          <Text align="center">{t('medias.drop_logo_text')}</Text>
          <SimpleGrid
            cols={4}
            breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
          >
          </SimpleGrid>
        </Dropzone>
        {logo && <Anchor radius="xl" size="xs" onClick={() => setLogo(null)}>
          {t('clear_button')}
        </Anchor>}
        <Space h="lg" />
        <Input.Wrapper label={t('medias.name_label')} required >
          <Input
            placeholder={t('medias.name_placeholder')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Input.Wrapper>
        <Space h="lg" />
        <Input.Wrapper label={t('medias.description_label')} required >
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Input.Wrapper>
        <Space h="lg" />
        <Group position="right">
          <Anchor onClick={close} underline={false}>{t('cancel_button')}</Anchor>
          <UpdateMedia
            submit={submit}
            id={media.id}
            name={name}
            logo={logo}
            description={description}
            setMedias={setMedias}
          />
        </Group>
      </Modal>
    </>
  );
}
