import React, {useState} from 'react';
import {
  MantineProvider,
} from '@mantine/core';
import PageRouting from "~/components/routing/PageRouting";
import { Suspense } from 'react';
import initI18n from './I18n';
import {CurrentUser} from "@/contexts/CurrentUserContext";
import "./App.scss";

export default function App({currentUser}) {
  const [user, setUser] = useState(currentUser);

  initI18n(user?.language || 'cs');

  return (
    <CurrentUser.Provider value={{...user, setCurrentUser: setUser}}>
      <Suspense fallback="Loading..." >
        <MantineProvider
          withGlobalStyles
          withCSSVariables
          withNormalizeCSS
          theme={{
            // primaryColor: "#6152cc",
            colors: {
              brand2: ['#eae8fa',
                '#ccc7f5',
                '#a39df0',
                '#8d88ee',
                '#7762ec',
                '#6357d8', //Button text color
                '#5649d6',
                '#493bd4',
                '#3c2dd2'],
            },
            primaryColor: 'brand2',
            globalStyles: (theme) => ({
              borderColor: theme.colors.gray[6],
            }),
          }}
        >
          <PageRouting />
        </MantineProvider>
      </Suspense>
    </CurrentUser.Provider>
  );
}