import React from 'react';
import {
  Group,
  ActionIcon,
  Menu,
  rem,
} from '@mantine/core';
import {
  IconTrash,
  IconDotsVertical,
} from '@tabler/icons-react';
import { useDisclosure } from "@mantine/hooks";
import {useTranslation} from "react-i18next";
import DeleteTeammateModal from './DeleteTeammateModal'

export default function Teammate({ teammate }) {
  const [isDeleteModalOpened, deleteModalHandlers] = useDisclosure(false);
  const { t } = useTranslation(['translation']);

  return (
    <tr key={teammate.id}>
      <td>{teammate.email}</td>
      <td>{teammate.status}</td>
      <td>
        <Group spacing={0} position="right">
          <Menu withinPortal position="bottom-end" shadow="sm">
            <Menu.Target>
              <ActionIcon radius="xl">
                <IconDotsVertical size="1rem"/>
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                icon={<IconTrash size={rem(14)} />}
                onClick={deleteModalHandlers.open}
                color="red"
              >
                {t('delete_button')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <DeleteTeammateModal 
            opened={isDeleteModalOpened}
            close={deleteModalHandlers.close}
            email={teammate.email}/>
        </Group>
      </td>
    </tr>
  );
}
