import React from 'react';
import {Button, LoadingOverlay} from '@mantine/core';
import {IconPlus} from "@tabler/icons-react";
import { useMutation } from '@apollo/client';
import { CREATE_PRESS_RELEASE } from '~/graphql/mutations/PressReleaseMutations';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function NewPressReleaseButton({}) {
  const { t } = useTranslation(['translation']);
  const [createPressRelease, { loading, data }] = useMutation(CREATE_PRESS_RELEASE);
  const navigate = useNavigate();

  if (loading) return <LoadingOverlay visible={loading} overlayBlur={2} />;
  if (data) navigate(`/press_releases/${data.createPressRelease.id}`)
  const handleCreatePressRelease = () => {
    createPressRelease({variables: { pressRelease: { subject: t('press_releases.notification_subject') } }})
  }
  return (
    <>
      <Button
        variant="light"
        size="sm"
        radius="xl"
        leftIcon={<IconPlus />}
        onClick={handleCreatePressRelease}
      >
        {t('press_releases.create_button')}
      </Button>
    </>
  );
}