import React from 'react';
import {Grid, Table} from '@mantine/core';
import NewTagButton from "@/components/Tags/NewTagButton";
import { GET_TAGS } from '~/graphql/queries/TagQueries';
import { useQuery } from '@apollo/client';
import TagRow from './TagRow';
import Loading from "@/components/Shared/LoadingOverlay";
import {useTranslation} from "react-i18next";

export default function Tags() {
  const { t } = useTranslation(['translation']);
  const { loading, error, data } = useQuery(GET_TAGS);

  if (loading) return <Loading />;
  if (error) return <p>{t('error')}</p>;

  return (
    <>
      <Grid justify="space-between" align="center">
        <Grid.Col span="content"><h2>{t('tags.header')}</h2></Grid.Col>
        <Grid.Col span="content">
          <NewTagButton />
        </Grid.Col>
      </Grid>

      <Table highlightOnHover>
        <thead>
        <tr>
          <th>{t('tags.name_label')}</th>
          <th>{t('tags.description_label')}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
          {data.tags.map((tag) => (
            <TagRow key={tag.id} id={tag.id} name={tag.name} description={tag.description} />
          ))}
        </tbody>
      </Table>
    </>
  );
}
